var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v("Staffs\n                         "),
              _vm.users
                ? _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("general.total_result_found", {
                          count: _vm.users.total
                        })
                      )
                    )
                  ])
                : _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c("div", { staticClass: "dropdown" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [
                    !_vm.showFilterPanel
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-sm pull-right m-r-10",
                            on: {
                              click: function($event) {
                                _vm.showFilterPanel = !_vm.showFilterPanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-filter" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-none d-sm-inline" }, [
                              _vm._v(_vm._s(_vm.trans("general.filter")))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [
                    _vm.users.total &&
                    !_vm.showCreatePanel &&
                    _vm.hasPermission("create-user")
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-sm pull-right m-r-10",
                            on: {
                              click: function($event) {
                                _vm.showCreatePanel = !_vm.showCreatePanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-none d-sm-inline" }, [
                              _vm._v("Create Staff")
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c("div", { staticClass: "card-body p-4" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("general.filter")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("user.name")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterUserForm.name,
                                    expression: "filterUserForm.name"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { name: "name" },
                                domProps: { value: _vm.filterUserForm.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filterUserForm,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("user.email")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterUserForm.email,
                                    expression: "filterUserForm.email"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { name: "email" },
                                domProps: { value: _vm.filterUserForm.email },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filterUserForm,
                                      "email",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("role.role")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterUserForm.role_id,
                                      expression: "filterUserForm.role_id"
                                    }
                                  ],
                                  staticClass: "custom-select col-12",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterUserForm,
                                        "role_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.select_one"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.roles, function(role) {
                                    return _c(
                                      "option",
                                      { domProps: { value: role.id } },
                                      [
                                        _vm._v(
                                          "\n                                             " +
                                            _vm._s(role.name) +
                                            "\n                                           "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("user.status")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterUserForm.status,
                                      expression: "filterUserForm.status"
                                    }
                                  ],
                                  staticClass: "custom-select col-12",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filterUserForm,
                                        "status",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.select_one"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "activated" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("user.status_activated")
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "pending_activation" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "user.status_pending_activation"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "pending_approval" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "user.status_pending_approval"
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "banned" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("user.status_banned"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "disapproved" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans("user.status_disapproved")
                                        )
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-12" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("date-range-picker", {
                                  attrs: {
                                    "start-date":
                                      _vm.filterUserForm.created_at_start_date,
                                    "end-date":
                                      _vm.filterUserForm.created_at_end_date,
                                    label: _vm.trans("user.created_at")
                                  },
                                  on: {
                                    "update:startDate": function($event) {
                                      return _vm.$set(
                                        _vm.filterUserForm,
                                        "created_at_start_date",
                                        $event
                                      )
                                    },
                                    "update:start-date": function($event) {
                                      return _vm.$set(
                                        _vm.filterUserForm,
                                        "created_at_start_date",
                                        $event
                                      )
                                    },
                                    "update:endDate": function($event) {
                                      return _vm.$set(
                                        _vm.filterUserForm,
                                        "created_at_end_date",
                                        $event
                                      )
                                    },
                                    "update:end-date": function($event) {
                                      return _vm.$set(
                                        _vm.filterUserForm,
                                        "created_at_end_date",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.showFilterPanel
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                on: {
                                  click: function($event) {
                                    _vm.showFilterPanel = !_vm.showFilterPanel
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                            )
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.hasPermission("create-user")
                ? _c("transition", { attrs: { name: "fade" } }, [
                    _vm.showCreatePanel
                      ? _c("div", { staticClass: "card border-bottom" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v("Add New Staff")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-content" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.submit.apply(null, arguments)
                                    },
                                    keydown: function($event) {
                                      return _vm.userForm.errors.clear(
                                        $event.target.name
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-6 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.first_name")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.userForm.first_name,
                                                  expression:
                                                    "userForm.first_name"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "first_name"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "first_name",
                                                placeholder: _vm.trans(
                                                  "user.first_name"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.first_name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "first_name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "first_name"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-6 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.last_name")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.last_name,
                                                  expression:
                                                    "userForm.last_name"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "last_name"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "last_name",
                                                placeholder: _vm.trans(
                                                  "user.last_name"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.last_name
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "last_name",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "last_name"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.username")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.username,
                                                  expression:
                                                    "userForm.username"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "username"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "username",
                                                placeholder: _vm.trans(
                                                  "user.username"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.username
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "username",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "username"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.email")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.email,
                                                  expression: "userForm.email"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "email"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "email",
                                                placeholder: _vm.trans(
                                                  "user.email"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.email
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "email",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "email"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.phone")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.phone,
                                                  expression: "userForm.phone"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "phone"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "phone",
                                                placeholder: _vm.trans(
                                                  "user.phone"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.phone
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "phone",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "phone"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.trans("role.role"))
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "role_id"
                                                )
                                              },
                                              attrs: {
                                                label: "name",
                                                "track-by": "id",
                                                name: "role_id",
                                                id: "role_id",
                                                options: _vm.roles,
                                                placeholder: _vm.trans(
                                                  "role.select_role"
                                                ),
                                                multiple: true,
                                                "close-on-select": false,
                                                "clear-on-select": false,
                                                "hide-selected": true,
                                                selected: _vm.selected_roles
                                              },
                                              on: {
                                                select: _vm.onRoleSelect,
                                                remove: _vm.onRoleRemove
                                              },
                                              model: {
                                                value: _vm.selected_roles,
                                                callback: function($$v) {
                                                  _vm.selected_roles = $$v
                                                },
                                                expression: "selected_roles"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "role_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.password")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.password,
                                                  expression:
                                                    "userForm.password"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "password"
                                                )
                                              },
                                              attrs: {
                                                type: "password",
                                                value: "",
                                                name: "password",
                                                placeholder: _vm.trans(
                                                  "user.password"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.password
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "password",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "password"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans(
                                                      "user.password_confirmation"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.userForm
                                                      .password_confirmation,
                                                  expression:
                                                    "userForm.password_confirmation"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "password_confirmation"
                                                )
                                              },
                                              attrs: {
                                                type: "password",
                                                value: "",
                                                name: "password_confirmation",
                                                placeholder: _vm.trans(
                                                  "user.password_confirmation"
                                                )
                                              },
                                              domProps: {
                                                value:
                                                  _vm.userForm
                                                    .password_confirmation
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "password_confirmation",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name":
                                                  "password_confirmation"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans(
                                                      "user.address_line_1"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.userForm.address_line_1,
                                                  expression:
                                                    "userForm.address_line_1"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "address_line_1"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "address_line_1",
                                                placeholder: _vm.trans(
                                                  "user.address_line_1"
                                                )
                                              },
                                              domProps: {
                                                value:
                                                  _vm.userForm.address_line_1
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "address_line_1",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "address_line_1"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans(
                                                      "user.address_line_2"
                                                    )
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.userForm.address_line_2,
                                                  expression:
                                                    "userForm.address_line_2"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "address_line_2"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "address_line_2",
                                                placeholder: _vm.trans(
                                                  "user.address_line_2"
                                                )
                                              },
                                              domProps: {
                                                value:
                                                  _vm.userForm.address_line_2
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "address_line_2",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "address_line_2"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.trans("user.city"))
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.city,
                                                  expression: "userForm.city"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "city"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "city",
                                                placeholder: _vm.trans(
                                                  "user.city"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.city
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "city",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "city"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.state")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.userForm.state,
                                                  expression: "userForm.state"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.userForm.errors.has(
                                                  "state"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                name: "state",
                                                placeholder: _vm.trans(
                                                  "user.state"
                                                )
                                              },
                                              domProps: {
                                                value: _vm.userForm.state
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "state",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "state"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.trans("user.country")
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.userForm.country_id,
                                                    expression:
                                                      "userForm.country_id"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  "is-invalid": _vm.userForm.errors.has(
                                                    "country_id"
                                                  )
                                                },
                                                attrs: { name: "country_id" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.userForm,
                                                      "country_id",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          "user.country"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(_vm.countries, function(
                                                  country
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      domProps: {
                                                        value: country.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(country.text)
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.userForm,
                                                "prop-name": "country_id"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-4 col-md-6 col-12 mb-1"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("switches", {
                                              staticClass: "m-l-20",
                                              attrs: {
                                                theme: "bootstrap",
                                                color: "success"
                                              },
                                              model: {
                                                value: _vm.userForm.send_email,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.userForm,
                                                    "send_email",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "userForm.send_email"
                                              }
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.trans(
                                                    "user.send_welcome_email"
                                                  )
                                                ) +
                                                "\n                                         "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-md-8 offset-md-4" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                                            attrs: { type: "submit" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.trans("general.add"))
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                _vm.showCreatePanel = !_vm.showCreatePanel
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("general.cancel")
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ])
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.hasPermission("list-user") && _vm.users.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            staticClass: "pull-right",
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterUserForm.sort_by,
                              order: _vm.filterUserForm.order
                            },
                            on: {
                              updateSortBy: function(value) {
                                _vm.filterUserForm.sort_by = value
                              },
                              updateOrder: function(value) {
                                _vm.filterUserForm.order = value
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body card-dashboard" },
                    [
                      _vm.hasPermission("list-user") && _vm.users.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c(
                              "table",
                              { staticClass: "table zero-configuration" },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("user.name")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("user.username")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("user.email")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("role.role")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Address")]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("user.status")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.trans("user.created_at"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "table-option" }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.action"))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.users.data, function(user) {
                                    return _c("tr", [
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            user.profile.first_name +
                                              " " +
                                              user.profile.last_name
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(user.username)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(user.email)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "ul",
                                          {
                                            staticStyle: {
                                              "list-style": "none",
                                              padding: "0"
                                            }
                                          },
                                          _vm._l(user.roles, function(role) {
                                            return _c("li", [
                                              _vm._v(_vm._s(role.name))
                                            ])
                                          }),
                                          0
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            user.profile.address_line_1
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        _vm._l(
                                          _vm.getUserStatus(user),
                                          function(status) {
                                            return _c(
                                              "span",
                                              {
                                                class: [
                                                  "badge",
                                                  "badge-" + status.color,
                                                  "m-r-5"
                                                ]
                                              },
                                              [_vm._v(_vm._s(status.label))]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("moment")(user.created_at)
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "user.view_user"
                                                      ),
                                                      expression:
                                                        "trans('user.view_user')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-info btn-sm",
                                                  attrs: {
                                                    to: "/user/" + user.id
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "feather icon-eye"
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.hasPermission("delete-user")
                                                ? _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "confirm",
                                                          rawName: "v-confirm",
                                                          value: {
                                                            ok: _vm.confirmDelete(
                                                              user
                                                            )
                                                          },
                                                          expression:
                                                            "{ok: confirmDelete(user)}"
                                                        },
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "user.delete_user"
                                                          ),
                                                          expression:
                                                            "trans('user.delete_user')"
                                                        }
                                                      ],
                                                      key: user.id,
                                                      staticClass:
                                                        "btn btn-danger btn-sm"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "feather icon-trash-2"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.users.total
                        ? _c(
                            "module-info",
                            {
                              attrs: {
                                module: "user",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "users"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "btn" }, slot: "btn" },
                                [
                                  _vm.hasPermission("create-user") &&
                                  !_vm.showCreatePanel
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-primary mr-1 mb-1 waves-effect waves-light",
                                          on: {
                                            click: function($event) {
                                              _vm.showCreatePanel = !_vm.showCreatePanel
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "feather icon-plus"
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans("general.add_new")
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterUserForm.page_length,
                          records: _vm.users
                        },
                        on: {
                          "update:pageLength": function($event) {
                            return _vm.$set(
                              _vm.filterUserForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function($event) {
                            return _vm.$set(
                              _vm.filterUserForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getUsers
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Staffs")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "feather icon-settings" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }