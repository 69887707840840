var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(_vm._s(_vm.trans("tcc.tcc")) + "\n                     ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                 "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 " +
                      _vm._s(_vm.trans("tcc.tcc")) +
                      "\n                             "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _vm.hasPermission("create-tcc")
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right ml-1",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/tcc/create")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "feather icon-plus" }),
                    _vm._v(" " + _vm._s(_vm.trans("tcc.create_tcc")))
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.showFilterPanel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right",
                    on: {
                      click: function($event) {
                        _vm.showFilterPanel = !_vm.showFilterPanel
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "feather icon-filter" }),
                    _vm._v(" " + _vm._s(_vm.trans("general.filter")))
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c(
                        "div",
                        { staticClass: "card-header" },
                        [
                          _c(
                            "h4",
                            { staticClass: "card-title float-left mb-0" },
                            [_vm._v(_vm._s(_vm.trans("general.filter")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "export-excel",
                            {
                              staticClass:
                                "btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right",
                              attrs: {
                                data: _vm.tccs.data,
                                fields: _vm.json_fields,
                                worksheet:
                                  "Sokoto State Inland Revenue Service",
                                name: "tcc.xls"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "feather icon-file-text"
                              }),
                              _vm._v(
                                " Export to Excel\n                              \n                             "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("general.keyword")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterTccForm.keyword,
                                      expression: "filterTccForm.keyword"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "keyword" },
                                  domProps: {
                                    value: _vm.filterTccForm.keyword
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.filterTccForm,
                                        "keyword",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("date-range-picker", {
                                    attrs: {
                                      "start-date":
                                        _vm.filterTccForm.start_date,
                                      "end-date": _vm.filterTccForm.end_date,
                                      label: _vm.trans("general.date_between")
                                    },
                                    on: {
                                      "update:startDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterTccForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:start-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterTccForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:endDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterTccForm,
                                          "end_date",
                                          $event
                                        )
                                      },
                                      "update:end-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterTccForm,
                                          "end_date",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                  on: {
                                    click: function($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.tccs.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterTccForm.sort_by,
                              order: _vm.filterTccForm.order
                            },
                            on: {
                              updateSortBy: function(value) {
                                _vm.filterTccForm.sort_by = value
                              },
                              updateOrder: function(value) {
                                _vm.filterTccForm.order = value
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm.tccs.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.serial_number"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("tcc.reference_no"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("tcc.date")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("tcc.tax_id")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("tcc.name")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("tcc.status")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm.trans("general.action")))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.tccs.data, function(tcc, index) {
                                  return _c("tr", [
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(index + 1)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(tcc.reference_no)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(tcc.date)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(tcc.user.tax_id)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(tcc.user.name)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.getStatus(tcc))
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "table-option" }, [
                                      _c("div", { staticClass: "btn-group" }, [
                                        _vm.hasPermission("approve-tcc")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: tcc.status
                                                      ? _vm.trans("tcc.reject")
                                                      : _vm.trans(
                                                          "tcc.approve"
                                                        ),
                                                    expression:
                                                      "tcc.status ? trans('tcc.reject') : trans('tcc.approve')"
                                                  }
                                                ],
                                                staticClass:
                                                  "btn btn-primary btn-sm",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.toggleStatus(tcc)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  class: [
                                                    "feather",
                                                    tcc.status
                                                      ? "icon-x"
                                                      : "icon-check"
                                                  ]
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        tcc.status == 1
                                          ? _c(
                                              "a",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.trans(
                                                      "tcc.print_tcc"
                                                    ),
                                                    expression:
                                                      "trans('tcc.print_tcc')"
                                                  }
                                                ],
                                                staticClass:
                                                  "btn btn-success btn-sm",
                                                attrs: {
                                                  href:
                                                    "/tcc/" +
                                                    tcc.reference_no +
                                                    "/print",
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "feather icon-printer"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermission("edit-tcc")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.trans(
                                                      "tcc.edit_tcc"
                                                    ),
                                                    expression:
                                                      "trans('tcc.edit_tcc')"
                                                  }
                                                ],
                                                staticClass:
                                                  "btn btn-info btn-sm",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editTcc(tcc)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "feather icon-edit"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermission("delete-tcc")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "confirm",
                                                    rawName: "v-confirm",
                                                    value: {
                                                      ok: _vm.confirmDelete(tcc)
                                                    },
                                                    expression:
                                                      "{ok: confirmDelete(tcc)}"
                                                  },
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.trans(
                                                      "tcc.delete_tcc"
                                                    ),
                                                    expression:
                                                      "trans('tcc.delete_tcc')"
                                                  }
                                                ],
                                                key: tcc.id,
                                                staticClass:
                                                  "btn btn-danger btn-sm"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "feather icon-trash-2"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.tccs.total
                        ? _c(
                            "module-info",
                            {
                              attrs: {
                                module: "tcc",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "check-circle"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "btn" }, slot: "btn" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-md",
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push("/tcc/create")
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "feather icon-plus"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.trans("general.add_new"))
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterTccForm.page_length,
                          records: _vm.tccs
                        },
                        on: {
                          "update:pageLength": function($event) {
                            return _vm.$set(
                              _vm.filterTccForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function($event) {
                            return _vm.$set(
                              _vm.filterTccForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getTccs
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }