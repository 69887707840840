var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("message.compose")) + "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.trans("message.compose")))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/home")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-home" }),
                _vm._v(" " + _vm._s(_vm.trans("general.home")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-area-wrapper" },
      [
        _c("message-sidebar", {
          attrs: { menu: "compose", statistics: _vm.statistics }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "app-content-overlay" }),
        _vm._v(" "),
        _c("div", { staticClass: "content-right" }, [
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "content-header row" }),
            _vm._v(" "),
            _c("div", { staticClass: "content-body" }, [
              _c("div", { staticClass: "app-content-overlay" }),
              _vm._v(" "),
              _c("div", { staticClass: "email-app-area" }, [
                _c("div", { staticClass: "email-app-list-wrapper" }, [
                  _c("div", { staticClass: "email-app-list" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "app-action" }, [
                      _c("div", { staticClass: "action-left" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "action-right" },
                        [_c("message-form")],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Message")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-fixed-search" }, [
      _c("div", { staticClass: "sidebar-toggle d-block d-lg-none" }, [
        _c("i", { staticClass: "feather icon-menu" })
      ]),
      _vm._v(" "),
      _c(
        "fieldset",
        { staticClass: "form-group position-relative has-icon-left m-0" },
        [
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              id: "email-search",
              placeholder: "Search email"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "form-control-position" }, [
            _c("i", { staticClass: "feather icon-search" })
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }