<template>
    <section class="row flexbox-container">
  <div class="col-xl-8 col-10 d-flex justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-6 d-lg-block d-none text-center align-self-center pl-0 pr-3 py-0">
                  <img src="images/pages/login.png" width="500px" alt="branding logo">
              </div>
              <div class="col-lg-6 col-12 p-0">
                  <div class="card rounded-0 mb-0 p-2">
                      <div class="card-header pt-50 pb-1">
                          <div class="card-title">
                              <h4 class="mb-0">{{trans('auth.register')}}</h4>
                          </div>
                      </div>
                      <p class="px-2">Fill the below form to create a new account.</p>
                      <div class="card-content">
                          <div class="card-body pt-0">
                            <form id="registerform" @submit.prevent="submit" @keydown="registerForm.errors.clear($event.target.name)">
                                <div class="form-group">
                                    <label class="form-check-label">
                                      <input class="form-check-input radio-inline" type="radio" name="type" id="type1" v-model="registerForm.type" value="individual" checked>
                                      Individual</label>
                                      <label class="form-check-label" style="margin-left: 20px">
                                      <input class="form-check-input radio-inline" type="radio" name="type" id="type2" v-model="registerForm.type" value="business">
                                      Business</label>
                                  </div>
                                  <div class="form-label-group" v-if="registerForm.type == 'individual'">
                                      
                                      <input type="text" name="first_name" autocomplete="first_name" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('first_name') }" :placeholder="trans('auth.first_name')" v-model="registerForm.first_name" autofocus="">
                                  
                                      <label for="first_name">{{ trans('auth.first_name') }}</label>
                                      <show-error :form-name="registerForm" prop-name="first_name"></show-error>
                                  </div>

                                  <div class="form-label-group" v-if="registerForm.type == 'individual'">
                                      <input type="text" name="last_name" autocomplete="last_name" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('last_name') }" :placeholder="trans('auth.last_name')" v-model="registerForm.last_name" autofocus="">
                                  
                                      <label for="last_name">{{ trans('auth.last_name') }}</label>
                                      <show-error :form-name="registerForm" prop-name="last_name"></show-error>
                                  </div>

                                  <div class="form-label-group" v-if="registerForm.type == 'business'">
                                      <input type="text" name="business_name" autocomplete="business_name" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('business_name') }" :placeholder="trans('auth.business_name')" v-model="registerForm.business_name" autofocus="">
                                  
                                      <label for="business_name">{{ trans('auth.business_name') }}</label>
                                      <show-error :form-name="registerForm" prop-name="business_name"></show-error>
                                  </div>

                                  <div class="form-label-group">
                                      <input type="text" name="username" autocomplete="username" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('username') }" :placeholder="trans('auth.username')" v-model="registerForm.username" autofocus="">
                                  
                                      <label for="username">{{ trans('auth.username') }}</label>
                                      <show-error :form-name="registerForm" prop-name="username"></show-error>
                                  </div>

                                  <div class="form-label-group">
                                      <input type="text" name="phone" autocomplete="phone" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('phone') }" :placeholder="trans('auth.phone')" v-model="registerForm.phone" autofocus="">
                                  
                                      <label for="phone">{{ trans('auth.phone') }}</label>
                                      <show-error :form-name="registerForm" prop-name="phone"></show-error>
                                  </div>

                                  <div class="form-label-group">
                                      <div v-if="getConfig('password_strength_meter')">
                                        <password v-model="registerForm.password" name="password" defaultClass="form-control" :placeholder="trans('auth.password')" :required="false"></password>
                                    </div>
                                    <div v-else>
                                        <input type="password" name="password" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('password') }" :placeholder="trans('auth.password')" v-model="registerForm.password">
                                    </div>
                                    <label for="password">{{ trans('auth.password') }}</label>
                                    <show-error :form-name="registerForm" prop-name="password"></show-error>
                                  </div>

                                  <div class="form-label-group">
                                      <input type="password" name="password_confirmation" class="form-control" :class="{ 'is-invalid': registerForm.errors.has('password_confirmation') }" :placeholder="trans('auth.confirm_password')" v-model="registerForm.password_confirmation">

                                      <label for="password-confirm">{{ trans('auth.confirm_password') }}</label>
                                        <show-error :form-name="registerForm" prop-name="password_confirmation"></show-error>  
                                  </div>
                                  <!-- <div class="form-group row" v-if="getConfig('terms_and_conditions')"> -->
                                    <div class="form-group row">
                                      <div class="col-12">
                                          <fieldset class="checkbox">
                                            <div class="vs-checkbox-con vs-checkbox-primary">
                                              <input id="checkbox-signup" type="checkbox" v-model="registerForm.tnc" name="tnc">
                                              <span class="vs-checkbox">
                                                <span class="vs-checkbox--check">
                                                  <i class="vs-icon feather icon-check"></i>
                                                </span>
                                              </span>
                                              <span class=""> 
                                                <a target="_blank" href="/terms-and-conditions">{{trans('auth.accept_tnc')}}</a>
                                              </span>
                                            </div>
                                          </fieldset>
                                          <show-error :form-name="registerForm" prop-name="tnc"></show-error>
                                      </div>
                                  </div>

                                  <div class="form-group g-recaptcha" v-if="getConfig('recaptcha') && getConfig('register_recaptcha')" :data-sitekey="getConfig('recaptcha_key')">
                                      
                                  </div>
                                  <router-link to="/login" class="btn btn-outline-primary float-left btn-inline mb-50">{{trans('auth.login')}}</router-link>
                                  <button-spinner
                                        :is-loading="registerForm.isLoading" 
                                        :disabled="registerForm.isLoading"
                                        
                                        class="btn btn-primary float-right btn-inline mb-50"
                                        style="height: 39px; color: #fff;"
                                        >
                                        {{trans('auth.register')}}
                                    </button-spinner>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    
</template>

<script>
    import guestFooter from '../../layouts/guest-footer.vue'
    import password from 'vue-password-strength-meter'

    export default {
        data() {
            return {
                registerForm: new Form ({
                    isLoading: false,
                    type: 'individual',
                    username: '',
                    phone: '',
                    password: '',
                    password_confirmation: '',
                    first_name: '',
                    last_name: '',
                    business_name: '',
                    tnc: false
                })
            }
        },
        components: {
            guestFooter,password
        },
        mounted(){
            if(!helper.featureAvailable('registration')){
                helper.featureNotAvailableMsg();
                return this.$router.push('/home');
            }

        },
        methods: {
            submit(){
                this.registerForm.isLoading = true;
                this.registerForm.post('/api/auth/register')
                    .then(response =>  {
                        this.registerForm.isLoading = false;
                        toastr.success(response.message);
                        this.$router.push('/login');
                    }).catch(error => {
                        this.registerForm.isLoading = false;
                        helper.showErrorMsg(error);
                    });
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
