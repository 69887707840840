<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('annual_return.annual_return')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/annual-return">
                                        {{ trans('annual_return.annual_return') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('annual_return.edit_annual_return') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/annual-return')"><i class="feather icon-list"></i> {{trans('annual_return.annual_return_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('annual_return.edit_annual_return')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <annual-return-form :id="id"></annual-return-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import annualReturnForm from './form';

    export default {
        components : { annualReturnForm },
        data() {
            return {
                id:this.$route.params.id
            }
        },
        mounted(){
            if(!helper.hasPermission('edit-annual-return')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('annual_return')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

        }
    }
</script>
