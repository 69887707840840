<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('receipt.bulk_upload')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/receipt">
                                        {{ trans('receipt.receipt') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('receipt.bulk_upload') }}
                                </li>


                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <a href="/files/receipts.xlsx" download class="btn btn-primary btn-sm waves-effect waves-float waves-light mr-1"><i class="fas fa-download"></i> {{ trans('general.download_sample') }}</a>
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/receipts')"><i class="feather icon-list"></i> {{trans('receipt.receipt_list')}}</button>
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('receipt.bulk_upload')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <form @submit.prevent="proceed" @keydown="receiptForm.errors.clear($event.target.name)">
                                    <div class="row">
                                        <div class="col-xl-6 col-md-6 col-12">
                                            <div class="form-group">
                                                <file-upload-input :button-text="trans('general.excel')" :token="receiptForm.upload_token" module="receipt" :clear-file="clear_message_attachment" :module-id="receiptForm.id"></file-upload-input>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8 offset-md-4">
                                            <button-spinner
                                                :is-loading="receiptForm.isLoading" 
                                                :disabled="receiptForm.isLoading"
                                                
                                                class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right"
                                                style="height: 39px; color: #fff;"
                                                >
                                                <span>{{trans('general.save')}}</span>
                                            </button-spinner>

        <router-link to="/receipts" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}</router-link>
        
                                    </div>
                                    </div>
                                </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import fileUploadInput from '../../components/file-upload-input'
    import uuid from 'uuid/v4'

    export default {
        components : { fileUploadInput },
        data() {
            return {
                receiptForm: new Form({
                    isLoading: false,
                    id: '',
                    upload_token: ''
                }),
                lgas: [],
                 clear_message_attachment: false,
            };
        },
        mounted(){
            if(!helper.hasPermission('bulk-upload-receipt')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('receipt')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }
            this.receiptForm.upload_token = uuid();
        },
         methods: {
            proceed(){
                this.storeUpload();
            },
            storeUpload(){
                this.receiptForm.isLoading = true;
                this.receiptForm.post('/api/receipt/bulk-upload')
                    .then(response => {
                        toastr.success(response.message);
                        this.receiptForm.isLoading = false;
                        this.clear_message_attachment = true;
                        this.$router.push('/receipt');
                    })
                    .catch(error => {
                        this.receiptForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
