<template>
    <div>
        <div class="content-header row">
        <div class="content-header-left col-md-10 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Payout</h2>
                    <div class="breadcrumb-wrapper col-12">
                        
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    Payout ({{ start_date }} ~ {{ end_date }})
                                </li>
                            </ol> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content-header-right text-md-right col-md-2 col-12 d-md-block d-none">
            <div class="form-group breadcrum-right d-flex">
                <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-filter"></i> Filter</button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" v-for="item in items" :key="item.id" v-on:click="handleSelectItem(item, 'filter')">
                            {{item.name}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
        <section id="dashboard-ecommerce">
            <div class="row">
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-info p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-info font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="info">
                              <h2 class="text-bold-700 mt-1">{{ total_withdrawable_comission | formatMoney }}</h2>
                              <p class="mb-0">Total Withdrawable Comission</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-printer text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/receipt" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ total_non_withdrawable_comission | formatMoney }}</h2>
                              <p class="mb-0">Total Non Withdrawable Comission (Receipt)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="success">
                              <h2 class="text-bold-700 mt-1">{{ total_amount_withdraw | formatMoney }}</h2>
                              <p class="mb-0">Total Amount Withdrawn</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-warning p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-warning font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/receipt" class="warning">
                              <h2 class="text-bold-700 mt-1">{{ total_balance | formatMoney }}</h2>
                              <p class="mb-0">Available Balance</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 offset-md-4">
                    <button type="button" @click="toggleCashOut" class="btn btn-primary float-right waves-effect mb-1">Cash Out</button>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Transaction History</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <div class="table-responsive" v-if="payouts.total">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Account No.</th>
                                    <th>Account Name</th>
                                    <th>Bank Name</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="payout in payouts.data">
                                    <td v-text="payout.amount"></td>
                                    <td v-text="payout.account_number"></td>
                                    <td v-text="payout.account_name"></td>
                                    <td v-text="payout.bank_name"></td>
                                    <td>{{payout.created_at | momentDateTime}}</td>
                                    <td v-html="getStatus(payout)"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <module-info v-if="!payouts.total" module="payout" title="module_info_title" description="module_info_description" icon="check-circle">
                        <div slot="btn">
                            <button class="btn btn-primary btn-md"><i class="feather icon-plus"></i> No records found</button>
                        </div>
                    </module-info>
                    <pagination-record :page-length.sync="filterPayoutForm.page_length" :records="payouts" @updateRecords="getPayouts"></pagination-record>
                        
                    </div>
                </div>
            </div>
           
        </section>
        
        <div class="modal fade" id="cashOut" tabindex="-1" role="dialog" aria-labelledby="cashOutDetail" aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <form @submit.prevent="cashOut" @keydown="payoutForm.errors.clear($event.target.name)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="cashOutDetail">Cash Out</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                                <div class="col-xl-12 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">Amount</label>
                                        <input class="form-control" :class="{ 'is-invalid': payoutForm.errors.has('amount') }" type="text" value="" v-model="payoutForm.amount" name="amount" placeholder="Amount">
                                        <show-error :form-name="payoutForm" prop-name="amount"></show-error>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">Bank</label>
                                        <v-select @input="updateBankName" :options="banks" :reduce="name => name.code + '-' + name.name" label="name" :class="{ 'form-control is-invalid': payoutForm.errors.has('bank') }" name="bank" v-model="payoutForm.bank"></v-select>
                                        <show-error :form-name="payoutForm" prop-name="bank_code"></show-error>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">Account Number</label>
                                        <input @input="onInput" v-on:keydown="setAccountNumber"  class="form-control" :class="{ 'is-invalid': payoutForm.errors.has('account_number') }" type="text" value="" :value="payoutForm.account_number" name="account_number" placeholder="Account Number">
                                        <show-error :form-name="payoutForm" prop-name="account_number"></show-error>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">Account Name</label>
                                        <input class="form-control" :class="{ 'is-invalid': payoutForm.errors.has('account_name') }" type="text" value="" v-model="payoutForm.account_name" name="account_name" placeholder="Account Name" disabled>
                                        <show-error :form-name="payoutForm" prop-name="account_name"></show-error>
                                    </div>
                                </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">Cacnel</button>
                        <button-spinner
                            :is-loading="payoutForm.isLoading" 
                            :disabled="payoutForm.isLoading"
                            
                            class="btn btn-primary waves-effect waves-light"
                            style="height: 39px; color: #fff;"
                            >
                            <span>Proceed</span>
                        </button-spinner>
                    </div>
                </form>
                </div>
            </div>
        </div>
   </div>
    </div>
</template>

<script>
import sortBy from '../../components/sort-by'
    export default {
        components : { sortBy },
        mounted(){
            if(!helper.hasPermission('access-comission')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(this.$route.query.reload)
                window.location = window.location.pathname;
            axios.get('/api/payout/dashboard?start_date=' + this.start_date + '&' + 'end_date=' + this.end_date)
                .then(response => response.data)
                .then(response => {
                    this.total_withdrawable_comission = response.total_withdrawable_comission;
                    this.total_non_withdrawable_comission = response.total_non_withdrawable_comission;
                    this.total_amount_withdraw = response.total_amount_withdraw;
                    this.total_balance = response.total_balance;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
            
            this.getPayouts();
            this.fetchPreRequisites();
            
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.banks = response.banks;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            cashOut(){
                this.payoutForm.isLoading = true;
                this.payoutForm.post('/api/payout')
                    .then(response => {
                        this.payoutForm.isLoading = false;
                        this.getPayouts();
                        toastr.success(response.message);
                        $('#cashOut').modal('hide');
                    })
                    .catch(error => {
                        this.payoutForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            handleSelectItem(item, type){
                this.selectedItem = item.name;
                var filter = item.id;
                
                switch (filter) {
                    case 1:
                        this.start_date = moment().format('YYYY-MM-DD');
                        this.end_date = moment().format('YYYY-MM-DD');
                        break;
                    case 2:
                        this.start_date = moment().subtract(1,'d').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'d').format('YYYY-MM-DD');
                    break;
                    case 3:
                        this.start_date = moment().subtract(7,'d').format('YYYY-MM-DD');
                        this.end_date = moment().format('YYYY-MM-DD');
                        break;
                    case 4:
                        this.start_date = moment().subtract(30,'d').format('YYYY-MM-DD');
                        this.end_date = moment().format('YYYY-MM-DD');
                    break;
                    case 5:
                        this.start_date = moment().startOf('month').format('YYYY-MM-DD');
                        this.end_date = moment().endOf('month').format('YYYY-MM-DD');
                    break;
                    case 6:
                        this.start_date = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
                    break;
                    case 7:
                        this.start_date = moment().subtract(1,'years').startOf('month').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'years').endOf('month').format('YYYY-MM-DD');
                    break;
                    case 8:
                        this.start_date = moment().startOf('year').format('YYYY-MM-DD');
                        this.end_date = moment().endOf('year').format('YYYY-MM-DD');
                    break;
                    case 9:
                        this.start_date = moment().subtract(1,'years').startOf('year').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'years').endOf('year').format('YYYY-MM-DD');
                    break;
                    default:
                        break;
                }
                
                axios.get('/api/payout/dashboard?start_date=' + this.start_date + '&end_date=' + this.end_date)
                .then(response => response.data)
                .then(response => {
                    this.total_withdrawable_comission = response.total_withdrawable_comission;
                    this.total_non_withdrawable_comission = response.total_non_withdrawable_comission;
                    this.total_amount_withdraw = response.total_amount_withdraw;
                    this.total_balance = response.total_balance;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
            },
            toggleCashOut(){
                $('#cashOut').modal('show');
            },
            updateBankName(event){
                var arr = event.split("-");
                if (String(arr[0]).length <= 2) {
                    this.payoutForm.bank_code = "0" + arr[0];
                } else {
                    this.payoutForm.bank_code = arr[0];
                }
                
                this.payoutForm.bank_name = arr[1];
                this.payoutForm.account_number = '';
                this.payoutForm.account_name = '';
            },
            setAccountNumber(v) {
                // allow all meta keys (including Backspace) to pass through
                if (v.metaKey || /(Backspace|Meta)/g.test(v.key)) {
                    return
                }

                // ignore non-numeric keys
                if (!/\d/.test(v.key)) {
                    v.preventDefault()
                    v.stopPropagation()
                    return false
                }

                const val = v.target.value.replace(/[^0-9]/g, "");
                this.payoutForm.account_number = val;
            },
            onInput(e) {
                if (e.inputType !== 'insertText' && /[^0-9]/g.test(e.target.value)) {
                    const val = e.target.value.replace(/[^0-9]/g, "");
                    this.payoutForm.account_number = val;
                    e.preventDefault()
                    e.stopPropagation()
                    return false
                }
                const value = e.target.value
                
                if (String(value).length <= 10) {
                    this.payoutForm.account_number = value
                    this.payoutForm.account_name = ''
                }
                if (String(value).length == 10) {
                    axios.get('/api/payout/validate/?accountNumber=' + value + '&bankCode=' + this.payoutForm.bank_code)
                    .then(response => response.data)
                    .then(response => {
                        this.payoutForm.account_name = response.accountName;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
                }
                this.$forceUpdate()
            },
            getPayouts(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterTodoForm);
                axios.get('/api/payout?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.payouts = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStatus(payout){
                return payout.status == 'SUCCESS' ? ('<span class="badge badge-success">'+payout.status+'</span>') : ('<span class="badge badge-danger">'+payout.status+'</span>') ;
            },
        },
        data() {
            return {
                payoutForm: new Form({
                    isLoading: false,
                    bank: '',
                    bank_code: '',
                    bank_name: '',
                    account_number: '',
                    account_name: '',
                    amount: '',
                    description: ''
                }),
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').format('YYYY-MM-DD'),
                selectedItem: null,
                items: [
                    {
                        id: 1,
                        name: "Today"
                    },
                    {
                        id: 2,
                        name: "Yesterday"
                    },
                    {
                        id: 3,
                        name: "Last 7 Days"
                    },
                    {
                        id: 4,
                        name: "Last 30 Days"
                    },
                    {
                        id: 5,
                        name: "This Month"
                    },
                    {
                        id: 6,
                        name: "Last Month"
                    },
                    {
                        id: 7,
                        name: "This Month Last Year"
                    },
                    {
                        id: 8,
                        name: "This Year"
                    },
                    {
                        id: 9,
                        name: "Last Year"
                    }
                ],
                banks: [],
                total_withdrawable_comission: 0,
                total_non_withdrawable_comission: 0,
                total_amount_withdraw: 0,
                total_balance: 0,
                payouts: {
                    total: 0,
                    data: []
                },
                filterPayoutForm: {
                    keyword: '',
                    status: false,
                    start_date: '',
                    end_date: '',
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: helper.getConfig('page_length')
                },
            }
        },
        computed: {
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
        watch: {
            filterPayoutForm: {
                handler(val){
                    setTimeout(() => {
                        this.getPayouts();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>