var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("locale.translation")) +
                  " (" +
                  _vm._s(_vm.locale.name) +
                  ")\n                     "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item" }, [
                  _c("a", { attrs: { href: "/" } }, [
                    _vm._v(_vm._s(_vm.trans("locale.translation")))
                  ])
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.locale.name))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "dropdown pull-right m-r-10" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-info btn-sm",
                staticStyle: { "margin-top": "-5px" },
                attrs: {
                  type: "button",
                  href: "#",
                  role: "button",
                  id: "moduleLink",
                  "data-toggle": "dropdown",
                  "aria-haspopup": "true",
                  "aria-expanded": "false"
                }
              },
              [
                _c("i", { staticClass: "fas fa-boxes" }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v(_vm._s(_vm.trans("locale.module")))
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "dropdown-menu",
                  _vm.getConfig("direction") == "ltr"
                    ? "dropdown-menu-right"
                    : ""
                ],
                attrs: { "aria-labelledby": "moduleLink" }
              },
              _vm._l(_vm.modules, function(mod) {
                return _c(
                  "button",
                  {
                    staticClass: "dropdown-item",
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push(
                          "/configuration/locale/" +
                            _vm.locale.locale +
                            "/" +
                            mod
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                         " +
                        _vm._s(_vm.toWord(mod)) +
                        " "
                    ),
                    mod == _vm.module
                      ? _c("span", { staticClass: "pull-right" }, [
                          _c("i", { staticClass: "fas fa-check" })
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right m-r-10",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/home")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "feather icon-home" }),
                    _vm._v(" " + _vm._s(_vm.trans("general.home")))
                  ]
                )
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right m-r-10",
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/configuration/locale")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "feather icon-globe" }),
                    _vm._v(" " + _vm._s(_vm.trans("locale.locale")))
                  ]
                )
              ])
            ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("show-tip", {
                      attrs: { module: "locale", tip: "tip_translation" }
                    }),
                    _vm._v(" "),
                    _c("form", [
                      _vm.getWordCount
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _vm._l(_vm.words, function(word, index) {
                                  return [
                                    typeof word === "object"
                                      ? _vm._l(word, function(wrd, i) {
                                          return _c(
                                            "div",
                                            { staticClass: "col-12 col-sm-4" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      },
                                                      attrs: { for: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.trans(
                                                            _vm.module +
                                                              "." +
                                                              index +
                                                              "." +
                                                              i
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.words[index][i],
                                                        expression:
                                                          "words[index][i]"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      value: "",
                                                      name: index + "_" + i
                                                    },
                                                    domProps: {
                                                      value: _vm.words[index][i]
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.words[index],
                                                          i,
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        })
                                      : [
                                          _c(
                                            "div",
                                            { staticClass: "col-12 col-sm-4" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "form-group" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticStyle: {
                                                        color: "red"
                                                      },
                                                      attrs: { for: "" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.trans(
                                                            _vm.module +
                                                              "." +
                                                              index
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.words[index],
                                                        expression:
                                                          "words[index]"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "text",
                                                      value: "",
                                                      name: index
                                                    },
                                                    domProps: {
                                                      value: _vm.words[index]
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.words,
                                                          index,
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                  ]
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-sm pull-right",
                                  on: { click: _vm.saveTranslation }
                                },
                                [_vm._v("Save")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.getWordCount
                        ? _c("div", [
                            _c("p", { staticClass: "alert alert-danger" }, [
                              _vm._v("No record found!")
                            ])
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dropdown" }, [
      _c(
        "button",
        {
          staticClass:
            "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
          attrs: {
            type: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [_c("i", { staticClass: "feather icon-settings" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }