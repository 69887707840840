var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("mail.mail_configuration")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("mail.mail_configuration")) +
                      "\n                            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/home")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-home" }),
                _vm._v(" " + _vm._s(_vm.trans("general.home")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("show-tip", {
                      attrs: { module: "mail", tip: "tip_mail_configuration" }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("mail.mail")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.configForm.errors.clear($event.target.name)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-sm-4" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("mail.driver")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.configForm.driver,
                                      expression: "configForm.driver"
                                    }
                                  ],
                                  staticClass: "custom-select col-12",
                                  class: {
                                    "is-invalid": _vm.configForm.errors.has(
                                      "driver"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.configForm,
                                        "driver",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.mail_drivers, function(option) {
                                  return _c(
                                    "option",
                                    { domProps: { value: option.value } },
                                    [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(option.text) +
                                          "\n                                          "
                                      )
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.configForm,
                                  "prop-name": "driver"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("mail.from_name")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.configForm.from_name,
                                    expression: "configForm.from_name"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.configForm.errors.has(
                                    "from_name"
                                  )
                                },
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "from_name",
                                  placeholder: _vm.trans("mail.from_name")
                                },
                                domProps: { value: _vm.configForm.from_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.configForm,
                                      "from_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.configForm,
                                  "prop-name": "from_name"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("mail.from_address")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.configForm.from_address,
                                    expression: "configForm.from_address"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.configForm.errors.has(
                                    "from_address"
                                  )
                                },
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "from_address",
                                  placeholder: _vm.trans("mail.from_address")
                                },
                                domProps: {
                                  value: _vm.configForm.from_address
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.configForm,
                                      "from_address",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.configForm,
                                  "prop-name": "from_address"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                              attrs: { type: "submit" }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.save")))]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-8" }, [
                          _vm.configForm.driver === "mailgun"
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.domain"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm.mailgun_domain,
                                                expression:
                                                  "configForm.mailgun_domain"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_domain"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_domain",
                                              placeholder: _vm.trans(
                                                "mail.domain"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.mailgun_domain
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_domain",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_domain"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.secret"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm.mailgun_secret,
                                                expression:
                                                  "configForm.mailgun_secret"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_secret"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_secret",
                                              placeholder: _vm.trans(
                                                "mail.secret"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.mailgun_secret
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_secret",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_secret"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.configForm.driver === "mandrill"
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(_vm._s(_vm.trans("mail.secret")))
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.configForm.mandrill_secret,
                                          expression:
                                            "configForm.mandrill_secret"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.configForm.errors.has(
                                          "mandrill_secret"
                                        )
                                      },
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        name: "mandrill_secret",
                                        placeholder: _vm.trans("mail.secret")
                                      },
                                      domProps: {
                                        value: _vm.configForm.mandrill_secret
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.configForm,
                                            "mandrill_secret",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.configForm,
                                        "prop-name": "mandrill_secret"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.configForm.driver === "smtp"
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.host"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.configForm.smtp_host,
                                                expression:
                                                  "configForm.smtp_host"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "smtp_host"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "smtp_host",
                                              placeholder: _vm.trans(
                                                "mail.host"
                                              )
                                            },
                                            domProps: {
                                              value: _vm.configForm.smtp_host
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "smtp_host",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "smtp_host"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.port"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.configForm.smtp_port,
                                                expression:
                                                  "configForm.smtp_port"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "smtp_port"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "smtp_port",
                                              placeholder: _vm.trans(
                                                "mail.port"
                                              )
                                            },
                                            domProps: {
                                              value: _vm.configForm.smtp_port
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "smtp_port",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "smtp_port"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.username"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm.smtp_username,
                                                expression:
                                                  "configForm.smtp_username"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "smtp_username"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "smtp_username",
                                              placeholder: _vm.trans(
                                                "mail.username"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.smtp_username
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "smtp_username",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "smtp_username"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.password"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm.smtp_password,
                                                expression:
                                                  "configForm.smtp_password"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "smtp_password"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "smtp_password",
                                              placeholder: _vm.trans(
                                                "mail.password"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.smtp_password
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "smtp_password",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "smtp_password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.trans("mail.encryption")
                                                ) + " "
                                              ),
                                              _c("show-tip", {
                                                attrs: {
                                                  type: "field",
                                                  module: "mail",
                                                  tip: "tip_encryption"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm
                                                    .smtp_encryption,
                                                expression:
                                                  "configForm.smtp_encryption"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "smtp_encryption"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "smtp_encryption",
                                              placeholder: _vm.trans(
                                                "mail.encryption"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.smtp_encryption
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "smtp_encryption",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "smtp_encryption"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.configForm.driver === "mailgun"
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.host"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm.mailgun_host,
                                                expression:
                                                  "configForm.mailgun_host"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_host"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_host",
                                              placeholder: _vm.trans(
                                                "mail.host"
                                              )
                                            },
                                            domProps: {
                                              value: _vm.configForm.mailgun_host
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_host",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_host"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.port"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm.mailgun_port,
                                                expression:
                                                  "configForm.mailgun_port"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_port"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_port",
                                              placeholder: _vm.trans(
                                                "mail.port"
                                              )
                                            },
                                            domProps: {
                                              value: _vm.configForm.mailgun_port
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_port",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_port"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.username"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm
                                                    .mailgun_username,
                                                expression:
                                                  "configForm.mailgun_username"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_username"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_username",
                                              placeholder: _vm.trans(
                                                "mail.username"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.mailgun_username
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_username",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_username"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("mail.password"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm
                                                    .mailgun_password,
                                                expression:
                                                  "configForm.mailgun_password"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_password"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_password",
                                              placeholder: _vm.trans(
                                                "mail.password"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm.mailgun_password
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_password",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.trans("mail.encryption")
                                                ) + " "
                                              ),
                                              _c("show-tip", {
                                                attrs: {
                                                  type: "field",
                                                  module: "mail",
                                                  tip: "tip_encryption"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.configForm
                                                    .mailgun_encryption,
                                                expression:
                                                  "configForm.mailgun_encryption"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.configForm.errors.has(
                                                "mailgun_encryption"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              value: "",
                                              name: "mailgun_encryption",
                                              placeholder: _vm.trans(
                                                "mail.encryption"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.configForm
                                                  .mailgun_encryption
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.configForm,
                                                  "mailgun_encryption",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.configForm,
                                              "prop-name": "mailgun_encryption"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Configurations")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }