var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("tax_payer.tax_payer")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/tax-payer" } }, [
                      _vm._v(_vm._s(_vm.trans("tax_payer.tax_payers")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("tax_payer.create_tax_payer")) +
                      "\n                            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/tax-payer")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("tax_payer.tax_payer_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.trans("tax_payer.create_tax_payer")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.storeTaxPayer.apply(null, arguments)
                  },
                  keydown: function($event) {
                    return _vm.taxPayerForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("tax_payer.type")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.taxPayerForm.type,
                                expression: "taxPayerForm.type"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.taxPayerForm.errors.has("type")
                            },
                            attrs: { name: "type" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.taxPayerForm,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Selet Type")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "individual" } }, [
                              _vm._v("Individual")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "business" } }, [
                              _vm._v("Business")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.taxPayerForm,
                            "prop-name": "type"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("user.email")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxPayerForm.email,
                              expression: "taxPayerForm.email"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.taxPayerForm.errors.has("email")
                          },
                          attrs: { type: "text", name: "email" },
                          domProps: { value: _vm.taxPayerForm.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.taxPayerForm,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.taxPayerForm,
                            "prop-name": "email"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("user.phone")))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxPayerForm.phone,
                              expression: "taxPayerForm.phone"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.taxPayerForm.errors.has("phone")
                          },
                          attrs: { type: "text", name: "phone" },
                          domProps: { value: _vm.taxPayerForm.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.taxPayerForm,
                                "phone",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.taxPayerForm,
                            "prop-name": "phone"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.taxPayerForm.type == "individual"
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.title")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.title,
                                    expression: "taxPayerForm.title"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "title"
                                  )
                                },
                                attrs: { name: "title" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "title",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("user.title")))
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.titles, function(title) {
                                  return _c(
                                    "option",
                                    { domProps: { value: title.id } },
                                    [_vm._v(_vm._s(title.id))]
                                  )
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "title"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.first_name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.first_name,
                                  expression: "taxPayerForm.first_name"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "first_name"
                                )
                              },
                              attrs: { type: "text", name: "first_name" },
                              domProps: { value: _vm.taxPayerForm.first_name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "first_name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "first_name"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.last_name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.last_name,
                                  expression: "taxPayerForm.last_name"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "last_name"
                                )
                              },
                              attrs: { type: "text", name: "last_name" },
                              domProps: { value: _vm.taxPayerForm.last_name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "last_name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "last_name"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.sector_id")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "sector_id"
                                )
                              },
                              attrs: {
                                options: _vm.sectors,
                                reduce: function(name) {
                                  return name.id
                                },
                                label: "name",
                                name: "sector_id"
                              },
                              on: { input: _vm.getSectorLines },
                              model: {
                                value: _vm.taxPayerForm.sector_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxPayerForm, "sector_id", $$v)
                                },
                                expression: "taxPayerForm.sector_id"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "sector_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.sector_line_id")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "sector_line_id"
                                )
                              },
                              attrs: {
                                options: _vm.sectorLines,
                                reduce: function(name) {
                                  return name.id
                                },
                                label: "name",
                                name: "sector_line_id"
                              },
                              model: {
                                value: _vm.taxPayerForm.sector_line_id,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "sector_line_id",
                                    $$v
                                  )
                                },
                                expression: "taxPayerForm.sector_line_id"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "sector_line_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Size")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.size,
                                    expression: "taxPayerForm.size"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "size"
                                  )
                                },
                                attrs: { name: "size" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "size",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "Large" } }, [
                                  _vm._v("Large")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Medium" } }, [
                                  _vm._v("Medium")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Small" } }, [
                                  _vm._v("Small")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "size"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.bvn")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.bvn,
                                  expression: "taxPayerForm.bvn"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has("bvn")
                              },
                              attrs: { type: "text", name: "bvn" },
                              domProps: { value: _vm.taxPayerForm.bvn },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "bvn",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "bvn"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.date_of_birth")))
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "date_of_birth"
                                )
                              },
                              attrs: {
                                bootstrapStyling: true,
                                name: "date_of_birth"
                              },
                              on: {
                                selected: function($event) {
                                  return _vm.taxPayerForm.errors.clear(
                                    "date_of_birth"
                                  )
                                }
                              },
                              model: {
                                value: _vm.taxPayerForm.date_of_birth,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "date_of_birth",
                                    $$v
                                  )
                                },
                                expression: "taxPayerForm.date_of_birth"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "date_of_birth"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.gender")))
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "radio radio-info" }, [
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.genders, function(gender) {
                                  return _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.taxPayerForm.gender,
                                            expression: "taxPayerForm.gender"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: gender.id,
                                          name: "gender"
                                        },
                                        domProps: {
                                          value: gender.id,
                                          checked:
                                            _vm.taxPayerForm.gender ==
                                            gender.id,
                                          checked: _vm._q(
                                            _vm.taxPayerForm.gender,
                                            gender.id
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.taxPayerForm.errors.clear(
                                              "gender"
                                            )
                                          },
                                          change: function($event) {
                                            return _vm.$set(
                                              _vm.taxPayerForm,
                                              "gender",
                                              gender.id
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: gender.id } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans("list." + gender.id)
                                              )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "gender"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.state")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.state_id,
                                    expression: "taxPayerForm.state_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "state_id"
                                  )
                                },
                                attrs: { name: "state_id", disabled: "" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "state_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.states, function(state) {
                                return _c(
                                  "option",
                                  { domProps: { value: state.id } },
                                  [_vm._v(_vm._s(state.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "state_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.lga_id")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.lga_id,
                                    expression: "taxPayerForm.lga_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "lga_id"
                                  )
                                },
                                attrs: { name: "lga_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "lga_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.lgas, function(lga) {
                                return _c(
                                  "option",
                                  { domProps: { value: lga.id } },
                                  [_vm._v(_vm._s(lga.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "lga_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.tax_office_id")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.tax_office_id,
                                    expression: "taxPayerForm.tax_office_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "tax_office_id"
                                  )
                                },
                                attrs: { name: "tax_office_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "tax_office_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.taxOffices, function(taxOffice) {
                                return _c(
                                  "option",
                                  { domProps: { value: taxOffice.id } },
                                  [_vm._v(_vm._s(taxOffice.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "tax_office_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.birth_place")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.birth_place,
                                  expression: "taxPayerForm.birth_place"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "birth_place"
                                )
                              },
                              attrs: { type: "text", name: "birth_place" },
                              domProps: { value: _vm.taxPayerForm.birth_place },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "birth_place",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "birth_place"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.occupation")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.occupation_id,
                                    expression: "taxPayerForm.occupation_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "occupation_id"
                                  )
                                },
                                attrs: { name: "occupation_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "occupation_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.occupations, function(occupation) {
                                return _c(
                                  "option",
                                  { domProps: { value: occupation.id } },
                                  [_vm._v(_vm._s(occupation.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "occupation_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("user.source_of_income_id"))
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.source_of_income_id,
                                    expression:
                                      "taxPayerForm.source_of_income_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "source_of_income_id"
                                  )
                                },
                                attrs: { name: "source_of_income_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "source_of_income_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.categories, function(category) {
                                return _c(
                                  "option",
                                  { domProps: { value: category.id } },
                                  [_vm._v(_vm._s(category.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "source_of_income_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.nationality")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.nationality,
                                  expression: "taxPayerForm.nationality"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "nationality"
                                )
                              },
                              attrs: { type: "text", name: "nationality" },
                              domProps: { value: _vm.taxPayerForm.nationality },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "nationality",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "nationality"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.address")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.address,
                                  expression: "taxPayerForm.address"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "address"
                                )
                              },
                              attrs: { type: "text", name: "address" },
                              domProps: { value: _vm.taxPayerForm.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "address",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "address"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.city")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.city,
                                  expression: "taxPayerForm.city"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "city"
                                )
                              },
                              attrs: { type: "text", name: "city" },
                              domProps: { value: _vm.taxPayerForm.city },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "city",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "city"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("user.state_of_origin_id"))
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.state_of_origin_id,
                                    expression:
                                      "taxPayerForm.state_of_origin_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "state_of_origin_id"
                                  )
                                },
                                attrs: { name: "state_of_origin_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "state_of_origin_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.states, function(state) {
                                return _c(
                                  "option",
                                  { domProps: { value: state.id } },
                                  [_vm._v(_vm._s(state.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "state_of_origin_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.tax_authority")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.tax_authority,
                                  expression: "taxPayerForm.tax_authority"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "tax_authority"
                                )
                              },
                              attrs: {
                                type: "text",
                                name: "tax_authority",
                                readonly: ""
                              },
                              domProps: {
                                value: _vm.taxPayerForm.tax_authority
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "tax_authority",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "tax_authority"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.employer_name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.employer_name,
                                  expression: "taxPayerForm.employer_name"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "employer_name"
                                )
                              },
                              attrs: { type: "text", name: "employer_name" },
                              domProps: {
                                value: _vm.taxPayerForm.employer_name
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "employer_name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "employer_name"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.employer_tin")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.employer_tin,
                                  expression: "taxPayerForm.employer_tin"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "employer_tin"
                                )
                              },
                              attrs: { type: "text", name: "employer_tin" },
                              domProps: {
                                value: _vm.taxPayerForm.employer_tin
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "employer_tin",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "employer_tin"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taxPayerForm.type == "business"
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.business_name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.business_name,
                                  expression: "taxPayerForm.business_name"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "business_name"
                                )
                              },
                              attrs: { type: "text", name: "business_name" },
                              domProps: {
                                value: _vm.taxPayerForm.business_name
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "business_name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "business_name"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.registered_name")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.registered_name,
                                  expression: "taxPayerForm.registered_name"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "registered_name"
                                )
                              },
                              attrs: { type: "text", name: "registered_name" },
                              domProps: {
                                value: _vm.taxPayerForm.registered_name
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "registered_name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "registered_name"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.organization_id")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.organization_id,
                                    expression: "taxPayerForm.organization_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "organization_id"
                                  )
                                },
                                attrs: { name: "organization_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "organization_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.organizations, function(organization) {
                                return _c(
                                  "option",
                                  { domProps: { value: organization.id } },
                                  [_vm._v(_vm._s(organization.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "organization_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.business_type")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.business_type_id,
                                    expression: "taxPayerForm.business_type_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "business_type_id"
                                  )
                                },
                                attrs: { name: "business_type_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "business_type_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.businessTypes, function(businessType) {
                                return _c(
                                  "option",
                                  { domProps: { value: businessType.id } },
                                  [_vm._v(_vm._s(businessType.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "business_type_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-6" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.sector_id")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "sector_id"
                                )
                              },
                              attrs: {
                                options: _vm.sectors,
                                reduce: function(name) {
                                  return name.id
                                },
                                label: "name",
                                name: "sector_id"
                              },
                              on: { input: _vm.getSectorLines },
                              model: {
                                value: _vm.taxPayerForm.sector_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.taxPayerForm, "sector_id", $$v)
                                },
                                expression: "taxPayerForm.sector_id"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "sector_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.sector_line_id")))
                            ]),
                            _vm._v(" "),
                            _c("v-select", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "sector_line_id"
                                )
                              },
                              attrs: {
                                options: _vm.sectorLines,
                                reduce: function(name) {
                                  return name.id
                                },
                                label: "name",
                                name: "sector_line_id"
                              },
                              model: {
                                value: _vm.taxPayerForm.sector_line_id,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "sector_line_id",
                                    $$v
                                  )
                                },
                                expression: "taxPayerForm.sector_line_id"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "sector_line_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Size")
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.size,
                                    expression: "taxPayerForm.size"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "size"
                                  )
                                },
                                attrs: { name: "size" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "size",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "Large" } }, [
                                  _vm._v("Large")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Medium" } }, [
                                  _vm._v("Medium")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "Small" } }, [
                                  _vm._v("Small")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "size"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.rc_no")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.rc_no,
                                  expression: "taxPayerForm.rc_no"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "rc_no"
                                )
                              },
                              attrs: { type: "text", name: "rc_no" },
                              domProps: { value: _vm.taxPayerForm.rc_no },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "rc_no",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "rc_no"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("user.enterprise_registration_no")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.taxPayerForm.enterprise_registration_no,
                                  expression:
                                    "taxPayerForm.enterprise_registration_no"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "enterprise_registration_no"
                                )
                              },
                              attrs: {
                                type: "text",
                                name: "enterprise_registration_no"
                              },
                              domProps: {
                                value:
                                  _vm.taxPayerForm.enterprise_registration_no
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "enterprise_registration_no",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "enterprise_registration_no"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("user.date_of_commencement"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "date_of_commencement"
                                )
                              },
                              attrs: {
                                bootstrapStyling: true,
                                name: "date_of_commencement"
                              },
                              on: {
                                selected: function($event) {
                                  return _vm.taxPayerForm.errors.clear(
                                    "date_of_commencement"
                                  )
                                }
                              },
                              model: {
                                value: _vm.taxPayerForm.date_of_commencement,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "date_of_commencement",
                                    $$v
                                  )
                                },
                                expression: "taxPayerForm.date_of_commencement"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "date_of_commencement"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.state")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.state_id,
                                    expression: "taxPayerForm.state_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "state_id"
                                  )
                                },
                                attrs: { name: "state_id", disabled: "" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "state_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.states, function(state) {
                                return _c(
                                  "option",
                                  { domProps: { value: state.id } },
                                  [_vm._v(_vm._s(state.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "state_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.lga_id")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.lga_id,
                                    expression: "taxPayerForm.lga_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "lga_id"
                                  )
                                },
                                attrs: { name: "lga_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "lga_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.lgas, function(lga) {
                                return _c(
                                  "option",
                                  { domProps: { value: lga.id } },
                                  [_vm._v(_vm._s(lga.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "lga_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.address")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.address,
                                  expression: "taxPayerForm.address"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "address"
                                )
                              },
                              attrs: { type: "text", name: "address" },
                              domProps: { value: _vm.taxPayerForm.address },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "address",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "address"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.city")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.city,
                                  expression: "taxPayerForm.city"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "city"
                                )
                              },
                              attrs: { type: "text", name: "city" },
                              domProps: { value: _vm.taxPayerForm.city },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "city",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "city"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.tax_office_id")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.taxPayerForm.tax_office_id,
                                    expression: "taxPayerForm.tax_office_id"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.taxPayerForm.errors.has(
                                    "tax_office_id"
                                  )
                                },
                                attrs: { name: "tax_office_id" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.taxPayerForm,
                                      "tax_office_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.taxOffices, function(taxOffice) {
                                return _c(
                                  "option",
                                  { domProps: { value: taxOffice.id } },
                                  [_vm._v(_vm._s(taxOffice.name))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "tax_office_id"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.tax_authority")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.tax_authority,
                                  expression: "taxPayerForm.tax_authority"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "tax_authority"
                                )
                              },
                              attrs: {
                                type: "text",
                                name: "tax_authority",
                                readonly: ""
                              },
                              domProps: {
                                value: _vm.taxPayerForm.tax_authority
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "tax_authority",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "tax_authority"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.company_tin")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.company_tin,
                                  expression: "taxPayerForm.company_tin"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "company_tin" },
                              domProps: { value: _vm.taxPayerForm.company_tin },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "company_tin",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "company_tin"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("user.date_of_incorporation"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("datepicker", {
                              class: {
                                "form-control is-invalid": _vm.taxPayerForm.errors.has(
                                  "date_of_incorporation"
                                )
                              },
                              attrs: {
                                bootstrapStyling: true,
                                name: "date_of_incorporation"
                              },
                              on: {
                                selected: function($event) {
                                  return _vm.taxPayerForm.errors.clear(
                                    "date_of_incorporation"
                                  )
                                }
                              },
                              model: {
                                value: _vm.taxPayerForm.date_of_incorporation,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "date_of_incorporation",
                                    $$v
                                  )
                                },
                                expression: "taxPayerForm.date_of_incorporation"
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "date_of_incorporation"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.issuance_place")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.issuance_place,
                                  expression: "taxPayerForm.issuance_place"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "issuance_place"
                                )
                              },
                              attrs: { type: "text", name: "issuance_place" },
                              domProps: {
                                value: _vm.taxPayerForm.issuance_place
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "issuance_place",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "issuance_place"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.updated_by")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.updated_by,
                                  expression: "taxPayerForm.updated_by"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "updated_by"
                                )
                              },
                              attrs: { type: "text", name: "updated_by" },
                              domProps: { value: _vm.taxPayerForm.updated_by },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "updated_by",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "updated_by"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-4" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("user.update_time")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taxPayerForm.update_time,
                                  expression: "taxPayerForm.update_time"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.taxPayerForm.errors.has(
                                  "update_time"
                                )
                              },
                              attrs: { type: "text", name: "update_time" },
                              domProps: { value: _vm.taxPayerForm.update_time },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.taxPayerForm,
                                    "update_time",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.taxPayerForm,
                                "prop-name": "update_time"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button-spinner",
                  {
                    staticClass:
                      "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                    staticStyle: { height: "39px", color: "#fff" },
                    attrs: {
                      "is-loading": _vm.taxPayerForm.isLoading,
                      disabled: _vm.taxPayerForm.isLoading
                    }
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.trans("general.save")) +
                        "\n                        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }