var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.categoryForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.categoryForm.name,
                    expression: "categoryForm.name"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.categoryForm.errors.has("name") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "name",
                  placeholder: "Name"
                },
                domProps: { value: _vm.categoryForm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.categoryForm, "name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.categoryForm, "prop-name": "name" }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { to: "/setting/category" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }