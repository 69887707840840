<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('tax_payer.tax_payer')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/tax-payer">{{trans('tax_payer.tax_payers')}}</router-link>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/user')"><i class="feather icon-list"></i> {{trans('user.user_list')}}</button>
                </div>
            </div>
        </div>

    <div class="content-body">
        <div id="user-profile">
            <user-sidebar menu="avatar" :id="id"></user-sidebar>
  
  <section id="profile-info">
    <div class="row">
        <user-summary :user="user"></user-summary>
      
      <div class="col-lg-8 col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{trans('user.avatar')}}</h4>
            </div>
          <div class="card-content">
              <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-1">

                <upload-image id="avatar" :upload-path="`/user/profile/avatar/${id}`" :remove-path="`/user/profile/avatar/remove/${id}`" :image-source="avatar.user" @uploaded="updateAvatar" @removed="updateAvatar"></upload-image>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
   
   </div>
    </div>
</template>

<script>
    import userSidebar from './user-sidebar'
    import uploadImage from '../../components/upload-image'
    import userSummary from './summary'

    export default {
        components : { userSidebar,uploadImage,userSummary },
        data() {
            return {
                id:this.$route.params.id,
                user: '',
                avatar: {
                    user: ''
                }
            };
        },
        mounted(){
            if(!helper.hasPermission('edit-tax-payer')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            axios.get('/api/user/'+this.id)
                .then(response => response.data)
                .then(response => {
                    this.user = response.user;
                    this.avatar.user = response.user.profile.avatar;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                    this.$router.push('/user');
                })
        },
        methods: {
            updateAvatar(val){
                if(helper.getAuthUser('id') == this.id){
                    this.$store.dispatch('setAuthUserDetail',{
                        avatar: val
                    });
                }
                this.user.profile.avatar = val;
            }
        }
    }
</script>
