var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    ref: "txtArea",
    staticClass: "form-control",
    attrs: { rows: _vm.rows, placeholder: _vm.placeholder, name: _vm.name },
    domProps: { value: _vm.value },
    on: { change: _vm.updateContent }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }