var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "card", attrs: { id: "start-kit-pages" } },
      [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v("Application Features")
          ]),
          _vm._v(" "),
          _c("a", { staticClass: "heading-elements-toggle" }, [
            _c("i", { staticClass: "fa fa-ellipsis-v font-medium-3" })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "heading-elements" }, [
            _c("ul", { staticClass: "list-inline mb-0" }, [
              _c("li", [
                _c("a", { attrs: { "data-action": "collapse" } }, [
                  _c("i", { staticClass: "feather icon-chevron-down" })
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { "data-action": "reload" } }, [
                  _c("i", { staticClass: "feather icon-rotate-cw" })
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { "data-action": "close" } }, [
                  _c("i", { staticClass: "feather icon-x" })
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card-content collapse show",
            attrs: { "aria-expanded": "true" }
          },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c(
                  "table",
                  { staticClass: "table table-bordered table-striped" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("Feature")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Description")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("2 Columns")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-2-columns.html",
                                target: "_blank"
                              }
                            },
                            [_vm._v("sk-layout-2-columns.html")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Fixed Navbar")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-fixed-navbar.html",
                                target: "_blank"
                              }
                            },
                            [_vm._v("sk-layout-fixed-navbar.html")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Floating Navbar")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-floating-navbar.html",
                                target: "_blank"
                              }
                            },
                            [_vm._v("sk-layout-floating-navbar.html")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [_vm._v("Fixed Layout")]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://pixinvent.com/demo/vuexy-html-bootstrap-admin-template/starter-kit/ltr/vertical-menu-template/sk-layout-fixed.html",
                                target: "_blank"
                              }
                            },
                            [_vm._v("sk-layout-fixed.html")]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }