var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-light navbar-shadow bg-success navbar-dark fixed-top"
    },
    [
      _c("div", { staticClass: "navbar-wrapper" }, [
        _c("div", { staticClass: "navbar-container content" }, [
          _c(
            "div",
            { staticClass: "navbar-collapse", attrs: { id: "navbar-mobile" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "mr-auto float-left bookmark-wrapper d-flex align-items-center"
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("ul", { staticClass: "nav navbar-nav bookmark-icons" }, [
                    _vm.getConfig("todo") && _vm.hasPermission("access-todo")
                      ? _c(
                          "li",
                          { staticClass: "nav-item d-none d-lg-block" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  to: "/todo",
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: _vm.trans("todo.todo")
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "ficon feather icon-check-square"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getConfig("message") &&
                    _vm.hasPermission("access-message")
                      ? _c(
                          "li",
                          { staticClass: "nav-item d-none d-lg-block" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  to: "/message",
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: _vm.trans("message.message")
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "ficon feather icon-mail"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getConfig("calendar") &&
                    _vm.hasPermission("access-calendar")
                      ? _c(
                          "li",
                          { staticClass: "nav-item d-none d-lg-block" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  to: "/calendar",
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title: "Calender"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "ficon feather icon-calendar"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "nav navbar-nav float-right" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "dropdown dropdown-notification nav-item" },
                  [
                    _vm.notifications.length
                      ? _c(
                          "a",
                          {
                            staticClass: "nav-link nav-link-label",
                            attrs: { href: "#", "data-toggle": "dropdown" }
                          },
                          [
                            _c("i", { staticClass: "ficon feather icon-bell" }),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-pill badge-primary badge-up"
                              },
                              [_vm._v(_vm._s(_vm.notifications.length))]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "dropdown-menu dropdown-menu-media dropdown-menu-right"
                      },
                      [
                        _c("li", { staticClass: "dropdown-menu-header" }, [
                          _c(
                            "div",
                            { staticClass: "dropdown-header m-0 p-2" },
                            [
                              _c("h3", { staticClass: "white" }, [
                                _vm._v(
                                  _vm._s(_vm.notifications.length) + " New"
                                )
                              ]),
                              _c("span", { staticClass: "grey darken-2" }, [
                                _vm._v("Notifications")
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.notifications, function(notification) {
                          return _c(
                            "li",
                            { staticClass: "scrollable-container media-list" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "d-flex justify-content-between",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.markAsRead(notification)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media d-flex align-items-start"
                                    },
                                    [
                                      _c("div", { staticClass: "media-left" }, [
                                        _c("i", {
                                          class: notification.data.icon
                                        })
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "media-body" }, [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "primary media-heading"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(notification.data.title)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "notification-text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  notification.data.message
                                                )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("small", [
                                        _c(
                                          "time",
                                          {
                                            staticClass: "media-meta",
                                            attrs: {
                                              datetime:
                                                "2015-06-11T18:29:20+08:00"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.ago))]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm._m(2)
                      ],
                      2
                    )
                  ]
                ),
                _vm._v(" "),
                _c("li", { staticClass: "dropdown dropdown-user nav-item" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "dropdown-toggle nav-link dropdown-user-link",
                      attrs: { href: "#", "data-toggle": "dropdown" }
                    },
                    [
                      _c("div", { staticClass: "user-nav d-sm-flex d-none" }, [
                        _c("span", { staticClass: "user-name text-bold-600" }, [
                          _vm._v(_vm._s(_vm.getAuthUser("full_name")))
                        ]),
                        _c("span", { staticClass: "user-status" }, [
                          _vm._v(_vm._s(_vm.getAuthUser("email")))
                        ])
                      ]),
                      _c("span", [
                        _c("img", {
                          staticClass: "round",
                          attrs: {
                            src: _vm.getAuthUser("avatar"),
                            alt: "user",
                            height: "40",
                            width: "40"
                          }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dropdown-menu dropdown-menu-right" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/profile" }
                        },
                        [
                          _c("i", { staticClass: "feather icon-user" }),
                          _vm._v(" " + _vm._s(_vm.trans("user.view_profile")))
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/change-password" }
                        },
                        [
                          _c("i", { staticClass: "feather icon-lock" }),
                          _vm._v(
                            " " + _vm._s(_vm.trans("user.change_password"))
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.logout.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "feather icon-power" }),
                          _vm._v(" " + _vm._s(_vm.trans("auth.logout")))
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav navbar-nav" }, [
      _c("li", { staticClass: "nav-item mobile-menu d-xl-none mr-auto" }, [
        _c(
          "a",
          {
            staticClass: "nav-link nav-menu-main menu-toggle hidden-xs",
            attrs: { href: "#" }
          },
          [_c("i", { staticClass: "ficon feather icon-menu" })]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item d-none d-lg-block" }, [
      _c("a", { staticClass: "nav-link nav-link-expand" }, [
        _c("i", { staticClass: "ficon feather icon-maximize" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "dropdown-menu-footer" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item p-1 text-center",
          attrs: { href: "javascript:void(0)" }
        },
        [_vm._v("Read all notifications")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }