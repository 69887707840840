var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("todo.todo")) + "\n                         "
              ),
              _vm.todos
                ? _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(
                      _vm._s(
                        _vm.trans("general.total_result_found", {
                          count: _vm.todos.total
                        })
                      )
                    )
                  ])
                : _c("span", { staticClass: "card-subtitle" }, [
                    _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                  ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c("div", { staticClass: "dropdown" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [
                    !_vm.showFilterPanel
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-sm pull-right m-r-10",
                            on: {
                              click: function($event) {
                                _vm.showFilterPanel = !_vm.showFilterPanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-filter" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-none d-sm-inline" }, [
                              _vm._v(_vm._s(_vm.trans("general.filter")))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "dropdown-item", attrs: { href: "#" } },
                  [
                    _vm.todos.total && !_vm.showCreatePanel
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-primary btn-sm pull-right m-r-10",
                            on: {
                              click: function($event) {
                                _vm.showCreatePanel = !_vm.showCreatePanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "d-none d-sm-inline" }, [
                              _vm._v(_vm._s(_vm.trans("todo.add_new_todo")))
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ])
            ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showCreatePanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("todo.add_new_todo")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("todo-form", {
                              on: {
                                completed: _vm.getTodos,
                                cancel: function($event) {
                                  _vm.showCreatePanel = !_vm.showCreatePanel
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(
                            "\n                                 " +
                              _vm._s(_vm.trans("general.filter")) +
                              "\n                             "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("todo.keyword")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterTodoForm.keyword,
                                      expression: "filterTodoForm.keyword"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "keyword" },
                                  domProps: {
                                    value: _vm.filterTodoForm.keyword
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.filterTodoForm,
                                        "keyword",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.filterTodoForm.status,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filterTodoForm,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "filterTodoForm.status"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.trans("todo.show_completed")) +
                                      "\n                                     "
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("date-range-picker", {
                                    attrs: {
                                      "start-date":
                                        _vm.filterTodoForm.start_date,
                                      "end-date": _vm.filterTodoForm.end_date,
                                      label: _vm.trans("general.date_between")
                                    },
                                    on: {
                                      "update:startDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterTodoForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:start-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterTodoForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:endDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterTodoForm,
                                          "end_date",
                                          $event
                                        )
                                      },
                                      "update:end-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterTodoForm,
                                          "end_date",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                  on: {
                                    click: function($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.todos.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterTodoForm.sort_by,
                              order: _vm.filterTodoForm.order
                            },
                            on: {
                              updateSortBy: function(value) {
                                _vm.filterTodoForm.sort_by = value
                              },
                              updateOrder: function(value) {
                                _vm.filterTodoForm.order = value
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm.todos.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("todo.title")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("todo.date")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("todo.status")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("todo.completed_at"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("todo.description"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm.trans("general.action")))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.todos.data, function(todo) {
                                  return _c("tr", [
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(todo.title)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm._f("moment")(todo.date))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.getStatus(todo))
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("momentDateTime")(
                                            todo.completed_at
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(todo.description)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "table-option" }, [
                                      _c("div", { staticClass: "btn-group" }, [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: todo.status
                                                  ? _vm.trans(
                                                      "todo.mark_as_incomplete"
                                                    )
                                                  : _vm.trans(
                                                      "todo.mark_as_complete"
                                                    ),
                                                expression:
                                                  "todo.status ? trans('todo.mark_as_incomplete') : trans('todo.mark_as_complete')"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-primary btn-sm",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.toggleStatus(todo)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              class: [
                                                "feather",
                                                todo.status
                                                  ? "icon-x"
                                                  : "icon-check"
                                              ]
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "todo.edit_todo"
                                                ),
                                                expression:
                                                  "trans('todo.edit_todo')"
                                              }
                                            ],
                                            staticClass: "btn btn-info btn-sm",
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                return _vm.editTodo(todo)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "feather icon-edit"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "confirm",
                                                rawName: "v-confirm",
                                                value: {
                                                  ok: _vm.confirmDelete(todo)
                                                },
                                                expression:
                                                  "{ok: confirmDelete(todo)}"
                                              },
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "todo.delete_todo"
                                                ),
                                                expression:
                                                  "trans('todo.delete_todo')"
                                              }
                                            ],
                                            key: todo.id,
                                            staticClass: "btn btn-danger btn-sm"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "feather icon-trash-2"
                                            })
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.todos.total
                        ? _c(
                            "module-info",
                            {
                              attrs: {
                                module: "todo",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "check-circle"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "btn" }, slot: "btn" },
                                [
                                  !_vm.showCreatePanel
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary btn-md",
                                          on: {
                                            click: function($event) {
                                              _vm.showCreatePanel = !_vm.showCreatePanel
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "feather icon-plus"
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.trans("general.add_new")
                                              )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterTodoForm.page_length,
                          records: _vm.todos
                        },
                        on: {
                          "update:pageLength": function($event) {
                            return _vm.$set(
                              _vm.filterTodoForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function($event) {
                            return _vm.$set(
                              _vm.filterTodoForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getTodos
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Todos")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "feather icon-settings" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }