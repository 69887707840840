var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("user.user")) + "\n                        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm.user.profile
                  ? _c("li", { staticClass: "breadcrumb-item active" }, [
                      _vm._v(
                        _vm._s(
                          _vm.user.profile.first_name +
                            " " +
                            _vm.user.profile.last_name
                        ) +
                          " (" +
                          _vm._s(_vm.user.email) +
                          ")"
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/user")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("user.user_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c(
        "div",
        { attrs: { id: "user-profile" } },
        [
          _c("user-sidebar", { attrs: { menu: "avatar", id: _vm.id } }),
          _vm._v(" "),
          _c("section", { attrs: { id: "profile-info" } }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("user-summary", { attrs: { user: _vm.user } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-8 col-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.avatar")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-content" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-start align-items-center mb-1"
                          },
                          [
                            _c("upload-image", {
                              attrs: {
                                id: "avatar",
                                "upload-path": "/user/profile/avatar/" + _vm.id,
                                "remove-path":
                                  "/user/profile/avatar/remove/" + _vm.id,
                                "image-source": _vm.avatar.user
                              },
                              on: {
                                uploaded: _vm.updateAvatar,
                                removed: _vm.updateAvatar
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("User")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }