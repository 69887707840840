var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("receipt.receipt")) + "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/receipt" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("receipt.receipt")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("receipt.receipt_details")) +
                      "\n                            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/receipt")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("receipt.receipt_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title float-left mb-0" }, [
            _vm._v(_vm._s(_vm.trans("receipt.receipt_details")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-4 col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _vm.receipt.has_attachment
                      ? _c("div", { staticClass: "card" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "list-group list-group-horizontal-sm m-t-20"
                              },
                              _vm._l(_vm.attachments, function(attachment) {
                                return _c(
                                  "li",
                                  { staticClass: "list-group-item" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            "/receipt/" +
                                            _vm.receipt.id +
                                            "/documents/" +
                                            attachment.uuid +
                                            "/download"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "feather icon-edit-2"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(attachment.user_file_type) +
                                            " -" +
                                            _vm._s(attachment.user_filename)
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-8 col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.trans("receipt.receipt_details")))
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass:
                            "feather icon-more-horizontal cursor-pointer"
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("receipt.tax_id")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.tin,
                                    expression: "receipt.tin"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.receipt.tin },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "tin",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("receipt.payer")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.payer,
                                    expression: "receipt.payer"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.receipt.payer },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "payer",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("receipt.address")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.address,
                                    expression: "receipt.address"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.receipt.address },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "address",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("receipt.amount")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.amount,
                                    expression: "receipt.amount"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.receipt.amount },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "amount",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("receipt.amount_paid")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.amount_paid,
                                    expression: "receipt.amount_paid"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.receipt.amount_paid },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "amount_paid",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("receipt.rev_code")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.rev_code,
                                    expression: "receipt.rev_code"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: { value: _vm.receipt.rev_code },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "rev_code",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("receipt.transaction_reference")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.transaction_reference,
                                    expression: "receipt.transaction_reference"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.receipt.transaction_reference
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "transaction_reference",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(_vm.trans("receipt.payment_details"))
                                )
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.receipt.payment_details,
                                    expression: "receipt.payment_details"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", readonly: "" },
                                domProps: {
                                  value: _vm.receipt.payment_details
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.receipt,
                                      "payment_details",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", [_vm._v("Documents")]),
      _vm._v(" "),
      _c("i", { staticClass: "feather icon-more-horizontal cursor-pointer" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }