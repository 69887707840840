<template>
    <form @submit.prevent="proceed" @keydown="annualReturnForm.errors.clear($event.target.name)">
        <div class="row">
            <div class="col-xl-12 col-md-6 col-12" v-if="userRole != 'user'">
                <div class="form-group">
                    <label for="">Tax Payer</label>
                    <v-select :options="taxPayers" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': annualReturnForm.errors.has('user_id') }" name="user_id" v-model="annualReturnForm.user_id"></v-select>
                    <show-error :form-name="annualReturnForm" prop-name="user_id"></show-error>
                </div>
            </div>
            
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.year_of_return')}}</label>
                    <input class="form-control" :class="{ 'is-invalid': annualReturnForm.errors.has('year_of_return') }" type="text" value="" v-model="annualReturnForm.year_of_return" name="year_of_return" :placeholder="trans('annual_return.year_of_return')">
                    <show-error :form-name="annualReturnForm" prop-name="year_of_return"></show-error>
                </div>
            </div>
            
            <div class="col-xl-12 col-md-6 col-12">
                <div class="form-group">
                    <label for="">{{trans('annual_return.date')}}</label>
                    <datepicker v-model="annualReturnForm.date" :bootstrapStyling="true" @selected="annualReturnForm.errors.clear('date')"  :class="{ 'form-control is-invalid': annualReturnForm.errors.has('date') }" :placeholder="trans('admission.date')"></datepicker>
                    <show-error :form-name="annualReturnForm" prop-name="date"></show-error>
                </div>
            </div>

            <div class="form-group">
            <file-upload-input :button-text="'Chose File'" :token="annualReturnForm.upload_token" module="annual_return" :clear-file="clear_message_attachment" :module-id="annualReturnForm.id" :has-file-type="true"></file-upload-input>
        </div>                              
        </div>
        <div class="row">
            <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">
                <span v-if="id">{{trans('general.update')}}</span>
                <span v-else>{{trans('general.save')}}</span>
                </button>

                <router-link to="/annual-return" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}
                </router-link>

            </div>
        </div>
    </form>
</template>


<script>
    import uuid from 'uuid/v4'
    import datepicker from 'vuejs-datepicker'
    import fileUploadInput from '../../components/file-upload-input'

    export default {
        components: {datepicker, fileUploadInput},
        props: ['uuid'],
        data() {
            return {
                annualReturnForm: new Form({
                    id: '',
                    user_id: '',
                    year_of_return: '',
                    date: '',
                    upload_token: ''
                }),
                clear_message_attachment: false,
                userRole: '',
                taxPayers: [],
            };
        },
        props: ['id'],
        mounted() {
            this.annualReturnForm.upload_token = uuid();
            if(this.id)
                this.getAnnualReturn();
            this.fetchPreRequisites();
            this.userRole = this.getRole()[0];
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getRole(role){
              return helper.getRole(role);
            },
            fetchPreRequisites(){
                axios.get('/api/guest/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.taxPayers = response.taxPayers;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updateAnnualReturn();
                else
                    this.storeAnnualReturn();
            },
            storeAnnualReturn(){
                this.annualReturnForm.date = moment(this.annualReturnForm.date).format('YYYY-MM-DD');
                this.annualReturnForm.post('/api/annual-return')
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/annual-return');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            getAnnualReturn(){
                axios.get('/api/annual-return/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.annualReturnForm.id = response.annualReturn.id;
                        this.annualReturnForm.user_id = response.annualReturn.user_id;
                        this.annualReturnForm.year_of_return = response.annualReturn.year_of_return;
                        this.annualReturnForm.date = response.annualReturn.date;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/annual-return');
                    });
            },
            updateAnnualReturn(){
                this.annualReturnForm.date = moment(this.annualReturnForm.date).format('YYYY-MM-DD');
                this.annualReturnForm.patch('/api/annual-return/'+this.id)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/annual-return');
                    })
                    .catch(error => {
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            }
           
        }
    }
</script>
