var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.tccForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tax Payer")]),
              _vm._v(" "),
              _c("v-select", {
                class: {
                  "form-control is-invalid": _vm.tccForm.errors.has("user_id")
                },
                attrs: {
                  options: _vm.taxPayers,
                  reduce: function(name) {
                    return name.id
                  },
                  label: "name",
                  name: "user_id"
                },
                on: { input: _vm.populateTaxPayerInputs },
                model: {
                  value: _vm.tccForm.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.tccForm, "user_id", $$v)
                  },
                  expression: "tccForm.user_id"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.tccForm, "prop-name": "user_id" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("TCC Type")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tccForm.type,
                      expression: "tccForm.type"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.tccForm.errors.has("type") },
                  attrs: { name: "type" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.tccForm,
                        "type",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Select Type")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Direct Assessment" } }, [
                    _vm._v("Direct Assessment")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Pay As You Earn (PAYE)" } }, [
                    _vm._v("Pay As You Earn (PAYE)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Dealership" } }, [
                    _vm._v("Dealership")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.tccForm, "prop-name": "type" }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.tccForm.tax_clearance_certificate_items, function(
        item,
        index
      ) {
        return _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-3 col-md-3 col-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("tcc.receipt_no")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.receipt_no,
                      expression: "item.receipt_no"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.tccForm.errors.has(
                      "tax_clearance_certificate_items." +
                        [index] +
                        ".receipt_no"
                    )
                  },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "receipt_no",
                    placeholder: _vm.trans("tcc.receipt_no")
                  },
                  domProps: { value: item.receipt_no },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "receipt_no", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.tccForm,
                    "prop-name":
                      "tax_clearance_certificate_items." +
                      [index] +
                      ".receipt_no"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-3 col-md-3 col-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("tcc.assessment_income")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.assessment_income,
                      expression: "item.assessment_income"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.tccForm.errors.has(
                      "tax_clearance_certificate_items." +
                        [index] +
                        ".assessment_income"
                    )
                  },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "assessment_income",
                    placeholder: _vm.trans("tcc.assessment_income")
                  },
                  domProps: { value: item.assessment_income },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "assessment_income", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.tccForm,
                    "prop-name":
                      "tax_clearance_certificate_items." +
                      [index] +
                      ".assessment_income"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-3 col-md-3 col-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("tcc.tax_payable")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.tax_payable,
                      expression: "item.tax_payable"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.tccForm.errors.has(
                      "tax_clearance_certificate_items." +
                        [index] +
                        ".tax_payable"
                    )
                  },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "tax_payable",
                    placeholder: _vm.trans("tcc.tax_payable")
                  },
                  domProps: { value: item.tax_payable },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "tax_payable", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.tccForm,
                    "prop-name":
                      "tax_clearance_certificate_items." +
                      [index] +
                      ".tax_payable"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-3 col-md-3 col-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.trans("tcc.year")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.year,
                      expression: "item.year"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.tccForm.errors.has(
                      "tax_clearance_certificate_items." + [index] + ".year"
                    )
                  },
                  attrs: {
                    type: "text",
                    value: "",
                    name: "year",
                    placeholder: _vm.trans("tcc.year")
                  },
                  domProps: { value: item.year },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "year", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.tccForm,
                    "prop-name":
                      "tax_clearance_certificate_items." + [index] + ".year"
                  }
                })
              ],
              1
            )
          ])
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                attrs: { type: "submit" }
              },
              [
                _vm.id
                  ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                  : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                attrs: { to: "/tcc" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }