var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.virtualAccountForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _vm.userRole != "user"
                  ? _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tax Payer")
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            class: {
                              "form-control is-invalid": _vm.virtualAccountForm.errors.has(
                                "user_id"
                              )
                            },
                            attrs: {
                              options: _vm.taxPayers,
                              reduce: function(name) {
                                return name.id
                              },
                              label: "name",
                              name: "user_id"
                            },
                            on: { input: _vm.populateTaxPayerInputs },
                            model: {
                              value: _vm.virtualAccountForm.user_id,
                              callback: function($$v) {
                                _vm.$set(_vm.virtualAccountForm, "user_id", $$v)
                              },
                              expression: "virtualAccountForm.user_id"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.virtualAccountForm,
                              "prop-name": "user_id"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("virtual_account.payer")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.virtualAccountForm.payer,
                            expression: "virtualAccountForm.payer"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.virtualAccountForm.errors.has(
                            "payer"
                          )
                        },
                        attrs: {
                          type: "text",
                          value: "",
                          name: "payer",
                          placeholder: _vm.trans("virtual_account.payer")
                        },
                        domProps: { value: _vm.virtualAccountForm.payer },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.virtualAccountForm,
                              "payer",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.virtualAccountForm,
                          "prop-name": "payer"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("virtual_account.email")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.virtualAccountForm.email,
                            expression: "virtualAccountForm.email"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.virtualAccountForm.errors.has(
                            "email"
                          )
                        },
                        attrs: {
                          type: "text",
                          value: "",
                          name: "email",
                          placeholder: _vm.trans("virtual_account.email")
                        },
                        domProps: { value: _vm.virtualAccountForm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.virtualAccountForm,
                              "email",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.virtualAccountForm,
                          "prop-name": "email"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("virtual_account.bvn")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.virtualAccountForm.bvn,
                            expression: "virtualAccountForm.bvn"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.virtualAccountForm.errors.has("bvn")
                        },
                        attrs: {
                          type: "text",
                          value: "",
                          name: "bvn",
                          placeholder: _vm.trans("virtual_account.bvn")
                        },
                        domProps: { value: _vm.virtualAccountForm.bvn },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.virtualAccountForm,
                              "bvn",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.virtualAccountForm,
                          "prop-name": "bvn"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c(
              "button-spinner",
              {
                staticClass:
                  "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                staticStyle: { height: "39px", color: "#fff" },
                attrs: {
                  "is-loading": _vm.virtualAccountForm.isLoading,
                  disabled: _vm.virtualAccountForm.isLoading
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                attrs: { to: "/virtual-account" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }