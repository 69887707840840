var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      {
        staticClass:
          "col-xl-7 col-md-9 col-10 d-flex justify-content-center px-0"
      },
      [
        _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6 col-12 p-0" }, [
              _c("div", { staticClass: "card rounded-0 mb-0 px-2 py-1" }, [
                _c("div", { staticClass: "card-header pb-1" }, [
                  _c("div", { staticClass: "card-title" }, [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.trans("passwords.reset_password")))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "px-2 mb-0" }, [
                  _vm._v(
                    "Please enter your email address and we'll send you instructions on how to reset your password."
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "form",
                      {
                        attrs: { id: "passwordform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                          keydown: function($event) {
                            return _vm.passwordForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-label-group" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.passwordForm.email,
                                  expression: "passwordForm.email"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.passwordForm.errors.has(
                                  "email"
                                )
                              },
                              attrs: {
                                type: "text",
                                name: "email",
                                placeholder: _vm.trans("auth.email"),
                                autocomplete: "email",
                                autofocus: ""
                              },
                              domProps: { value: _vm.passwordForm.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.passwordForm,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "email" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.email")))
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.passwordForm,
                                "prop-name": "email"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.getConfig("recaptcha") &&
                        _vm.getConfig("reset_password_recaptcha")
                          ? _c("div", {
                              staticClass: "form-group g-recaptcha",
                              attrs: {
                                "data-sitekey": _vm.getConfig("recaptcha_key")
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "float-md-left d-block mb-1" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-outline-primary btn-block px-75",
                                attrs: { to: "/login" }
                              },
                              [_vm._v(_vm._s(_vm.trans("auth.back_to_login?")))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "float-md-right d-block mb-1" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-block px-75",
                                attrs: { type: "submit" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("passwords.reset_password"))
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-lg-6 d-lg-block d-none text-center align-self-center"
      },
      [
        _c("img", {
          attrs: {
            src: "images/pages/login.png",
            width: "500px",
            alt: "branding logo"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }