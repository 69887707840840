var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("general.report")) + "\n                     "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                 "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 " +
                      _vm._s(_vm.trans("general.report")) +
                      "\n                             "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            !_vm.showFilterPanel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right",
                    on: {
                      click: function($event) {
                        _vm.showFilterPanel = !_vm.showFilterPanel
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "feather icon-filter" }),
                    _vm._v(" " + _vm._s(_vm.trans("general.filter")))
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c(
                        "div",
                        { staticClass: "card-header" },
                        [
                          _c(
                            "h4",
                            { staticClass: "card-title float-left mb-0" },
                            [_vm._v(_vm._s(_vm.trans("general.filter")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "export-excel",
                            {
                              staticClass:
                                "btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right",
                              attrs: {
                                data: _vm.reports.data,
                                fields: _vm.json_fields,
                                worksheet:
                                  "Sokoto State Inland Revenue Service",
                                name: "report.xls"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "feather icon-file-text"
                              }),
                              _vm._v(
                                " Export to Excel\n                              \n                             "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-4" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trans("payment.reference_rrr_trx")
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterReportForm.keyword,
                                      expression: "filterReportForm.keyword"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "keyword" },
                                  domProps: {
                                    value: _vm.filterReportForm.keyword
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.filterReportForm,
                                        "keyword",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-4" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("MDA")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.mdas,
                                      reduce: function(name) {
                                        return name.id
                                      },
                                      label: "name",
                                      name: "mda_id"
                                    },
                                    model: {
                                      value: _vm.filterReportForm.mda_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filterReportForm,
                                          "mda_id",
                                          $$v
                                        )
                                      },
                                      expression: "filterReportForm.mda_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-4" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("payment.gateway_id"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.filterReportForm.gateway_id,
                                        expression:
                                          "filterReportForm.gateway_id"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      name: "gateway_id",
                                      placeholder:
                                        "Select Payment \n                                         Gateway"
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.filterReportForm,
                                          "gateway_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Select Payment Channel")
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.gateways, function(gateway) {
                                      return _c(
                                        "option",
                                        { domProps: { value: gateway.id } },
                                        [_vm._v(_vm._s(gateway.name))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("date-range-picker", {
                                    attrs: {
                                      "start-date":
                                        _vm.filterReportForm.start_date,
                                      "end-date": _vm.filterReportForm.end_date,
                                      label: _vm.trans("general.date_between")
                                    },
                                    on: {
                                      "update:startDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterReportForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:start-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterReportForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:endDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterReportForm,
                                          "end_date",
                                          $event
                                        )
                                      },
                                      "update:end-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterReportForm,
                                          "end_date",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                  on: {
                                    click: function($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.reports.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterReportForm.sort_by,
                              order: _vm.filterReportForm.order
                            },
                            on: {
                              updateSortBy: function(value) {
                                _vm.filterReportForm.sort_by = value
                              },
                              updateOrder: function(value) {
                                _vm.filterReportForm.order = value
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm.reports.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.serial_number"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("general.date")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("payment.tax_id")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("payment.payer")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("payment.pay_item"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("payment.amount")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("payment.reference"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("general.status")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("payment.gateway")))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.reports.data, function(
                                  report,
                                  index
                                ) {
                                  return _c("tr", [
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(index + 1)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("momentDateTime")(
                                            report.created_at
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(report.user.tax_id)
                                      }
                                    }),
                                    _vm._v(" "),
                                    report.user.type == "individual"
                                      ? _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              report.user.profile.first_name +
                                                " " +
                                                report.user.profile.last_name
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    report.user.type == "business"
                                      ? _c("td", {
                                          domProps: {
                                            textContent: _vm._s(
                                              report.user.profile.business_name
                                            )
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      _vm._l(report.payment_items, function(
                                        payment_item
                                      ) {
                                        return _c("span", [
                                          _vm._v(
                                            "\n                                                 " +
                                              _vm._s(
                                                payment_item.pay_item.name
                                              ) +
                                              ",\n                                             "
                                          )
                                        ])
                                      }),
                                      0
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatMoney")(report.amount)
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(report.reference)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(_vm.getStatus(report))
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(report.gateway.name)
                                      }
                                    })
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.reports.total
                        ? _c(
                            "module-info",
                            {
                              attrs: {
                                module: "report",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "check-circle"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "btn" }, slot: "btn" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-md",
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push(
                                            "/report/create"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "feather icon-plus"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.trans("general.add_new"))
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterReportForm.page_length,
                          records: _vm.reports
                        },
                        on: {
                          "update:pageLength": function($event) {
                            return _vm.$set(
                              _vm.filterReportForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function($event) {
                            return _vm.$set(
                              _vm.filterReportForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getReports
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }