var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      { staticClass: "col-xl-7 col-10 d-flex justify-content-center" },
      [
        _c(
          "div",
          { staticClass: "card bg-authentication rounded-0 mb-0 w-100" },
          [
            _c("div", { staticClass: "row m-0" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-6 col-12 p-0" }, [
                _c("div", { staticClass: "card rounded-0 mb-0 px-2" }, [
                  _c("div", { staticClass: "card-header pb-1" }, [
                    _c("div", { staticClass: "card-title" }, [
                      _c("h4", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.trans("passwords.reset_password")))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "px-2" }, [
                    _vm._v("Please enter your new password.")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c("div", { staticClass: "card-body pt-1" }, [
                      !_vm.showMessage
                        ? _c("div", [
                            _c(
                              "form",
                              {
                                attrs: { id: "resetform" },
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.submit.apply(null, arguments)
                                  },
                                  keydown: function($event) {
                                    return _vm.resetForm.errors.clear(
                                      $event.target.name
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "form-label-group form-group position-relative has-icon-left"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.resetForm.email,
                                          expression: "resetForm.email"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.resetForm.errors.has(
                                          "email"
                                        )
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "email",
                                        placeholder: _vm.trans("auth.email")
                                      },
                                      domProps: { value: _vm.resetForm.email },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.resetForm,
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm._m(1),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: "email" } }, [
                                      _vm._v(_vm._s(_vm.trans("auth.email")))
                                    ]),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.resetForm,
                                        "prop-name": "email"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "form-label-group position-relative has-icon-left"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.resetForm.password,
                                          expression: "resetForm.password"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.resetForm.errors.has(
                                          "password"
                                        )
                                      },
                                      attrs: {
                                        type: "password",
                                        name: "password",
                                        placeholder: _vm.trans("auth.password")
                                      },
                                      domProps: {
                                        value: _vm.resetForm.password
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.resetForm,
                                            "password",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm._m(2),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { attrs: { for: "password" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.trans("auth.password"))
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.resetForm,
                                        "prop-name": "password"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "form-label-group position-relative has-icon-left"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.resetForm.password_confirmation,
                                          expression:
                                            "resetForm.password_confirmation"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.resetForm.errors.has(
                                          "password_confirmation"
                                        )
                                      },
                                      attrs: {
                                        type: "password",
                                        name: "password",
                                        placeholder: _vm.trans(
                                          "auth.confirm_password"
                                        )
                                      },
                                      domProps: {
                                        value:
                                          _vm.resetForm.password_confirmation
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.resetForm,
                                            "password_confirmation",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { attrs: { for: "password" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.trans("auth.confirm_password")
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.resetForm,
                                        "prop-name": "password_confirmation"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "row pt-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6 mb-1" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "btn btn-outline-primary btn-block px-0",
                                          attrs: { to: "/login" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans("auth.back_to_login?")
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-6 mb-1" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-primary btn-block px-0",
                                          attrs: { type: "submit" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.trans(
                                                "passwords.reset_password"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        : _c("div", { staticClass: "text-center" }, [
                            _vm.status
                              ? _c("h5", {
                                  staticClass: "alert alert-success",
                                  domProps: { textContent: _vm._s(_vm.message) }
                                })
                              : _c("h5", {
                                  staticClass: "alert alert-danger",
                                  domProps: { textContent: _vm._s(_vm.message) }
                                }),
                            _vm._v(" "),
                            _c("div", { staticClass: "row pt-2" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "btn btn-outline-primary btn-block px-0",
                                      attrs: { to: "/login" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.trans("auth.back_to_login?"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("guest-footer")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-lg-6 d-lg-block d-none text-center align-self-center p-0"
      },
      [
        _c("img", {
          attrs: {
            src: "images/pages/login.png",
            width: "500px",
            alt: "branding logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-lock" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-lock" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }