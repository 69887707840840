var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.efillingForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _vm.userRole != "user"
                  ? _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tax Payer")
                          ]),
                          _vm._v(" "),
                          _c("v-select", {
                            class: {
                              "form-control is-invalid": _vm.efillingForm.errors.has(
                                "user_id"
                              )
                            },
                            attrs: {
                              options: _vm.taxPayers,
                              reduce: function(name) {
                                return name.id
                              },
                              label: "name",
                              name: "user_id"
                            },
                            model: {
                              value: _vm.efillingForm.user_id,
                              callback: function($$v) {
                                _vm.$set(_vm.efillingForm, "user_id", $$v)
                              },
                              expression: "efillingForm.user_id"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.efillingForm,
                              "prop-name": "user_id"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Source of Income")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("efilling.total_income")))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xs-5" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.efillingForm.total_income,
                                expression: "efillingForm.total_income"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.efillingForm.errors.has(
                                "total_income"
                              )
                            },
                            attrs: {
                              type: "text",
                              value: "",
                              name: "total_income",
                              placeholder: _vm.trans("efilling.total_income")
                            },
                            domProps: { value: _vm.efillingForm.total_income },
                            on: {
                              blur: function($event) {
                                return _vm.calculate($event)
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.efillingForm,
                                  "total_income",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.efillingForm,
                          "prop-name": "total_income"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xl-12 col-md-6 col-12 mb-1" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("switches", {
                      staticClass: "m-l-20",
                      attrs: { theme: "bootstrap", color: "success" },
                      model: {
                        value: _vm.efillingForm.has_deduction,
                        callback: function($$v) {
                          _vm.$set(_vm.efillingForm, "has_deduction", $$v)
                        },
                        expression: "efillingForm.has_deduction"
                      }
                    }),
                    _vm._v(" Add Deduction\n                        ")
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.efillingForm.has_deduction
                ? _c("div", [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Allowable Deduction")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("efilling.national_housing_fund")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.efillingForm.national_housing_fund,
                                  expression:
                                    "efillingForm.national_housing_fund"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.efillingForm.errors.has(
                                  "national_housing_fund"
                                )
                              },
                              attrs: {
                                type: "text",
                                value: "",
                                name: "national_housing_fund",
                                placeholder: _vm.trans(
                                  "efilling.national_housing_fund"
                                )
                              },
                              domProps: {
                                value: _vm.efillingForm.national_housing_fund
                              },
                              on: {
                                blur: function($event) {
                                  return _vm.calculate($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.efillingForm,
                                    "national_housing_fund",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.efillingForm,
                                "prop-name": "national_housing_fund"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("efilling.national_pension_scheme")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.efillingForm.national_pension_scheme,
                                  expression:
                                    "efillingForm.national_pension_scheme"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.efillingForm.errors.has(
                                  "national_pension_scheme"
                                )
                              },
                              attrs: {
                                type: "text",
                                value: "",
                                name: "national_pension_scheme",
                                placeholder: _vm.trans(
                                  "efilling.national_pension_scheme"
                                )
                              },
                              domProps: {
                                value: _vm.efillingForm.national_pension_scheme
                              },
                              on: {
                                blur: function($event) {
                                  return _vm.calculate($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.efillingForm,
                                    "national_pension_scheme",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.efillingForm,
                                "prop-name": "national_pension_scheme"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(_vm.trans("efilling.life_insurance"))
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.efillingForm.life_insurance,
                                  expression: "efillingForm.life_insurance"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.efillingForm.errors.has(
                                  "life_insurance"
                                )
                              },
                              attrs: {
                                type: "text",
                                value: "",
                                name: "life_insurance",
                                placeholder: _vm.trans(
                                  "efilling.life_insurance"
                                )
                              },
                              domProps: {
                                value: _vm.efillingForm.life_insurance
                              },
                              on: {
                                blur: function($event) {
                                  return _vm.calculate($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.efillingForm,
                                    "life_insurance",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.efillingForm,
                                "prop-name": "life_insurance"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("efilling.wht")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.efillingForm.wht,
                                  expression: "efillingForm.wht"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.efillingForm.errors.has("wht")
                              },
                              attrs: {
                                type: "text",
                                value: "",
                                name: "wht",
                                placeholder: _vm.trans("efilling.wht")
                              },
                              domProps: { value: _vm.efillingForm.wht },
                              on: {
                                blur: function($event) {
                                  return _vm.calculate($event)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.efillingForm,
                                    "wht",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.efillingForm,
                                "prop-name": "wht"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.trans("efilling.chargable_income")))
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.efillingForm.chargable_income,
                            expression: "efillingForm.chargable_income"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.efillingForm.errors.has(
                            "chargable_income"
                          )
                        },
                        attrs: {
                          type: "text",
                          value: "",
                          name: "chargable_income",
                          placeholder: _vm.trans("efilling.chargable_income"),
                          disabled: ""
                        },
                        domProps: { value: _vm.efillingForm.chargable_income },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.efillingForm,
                              "chargable_income",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.efillingForm,
                          "prop-name": "chargable_income"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c(
              "button",
              {
                staticClass:
                  "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                attrs: { type: "submit" }
              },
              [
                _vm.id
                  ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                  : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                attrs: { to: "/e-filling" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [
        _vm._v("Personal Income Tax Self Assessment Returns")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("h4", { staticStyle: { "margin-top": "5px", padding: "0px 5px" } }, [
        _vm._v("₦")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }