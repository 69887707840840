var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.storeRole.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.roleForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(_vm._s(_vm.trans("role.name")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.roleForm.name,
                expression: "roleForm.name"
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.roleForm.errors.has("name") },
            attrs: {
              type: "text",
              value: "",
              name: "name",
              placeholder: _vm.trans("role.name")
            },
            domProps: { value: _vm.roleForm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.roleForm, "name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("show-error", {
            attrs: { "form-name": _vm.roleForm, "prop-name": "name" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { type: "submit" }
        },
        [_c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }