var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("tax_payer.tax_payer")) +
                  "\n                        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/tax-payer" } }, [
                      _vm._v(_vm._s(_vm.trans("tax_payer.tax_payers")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.user.profile
                  ? _c("li", { staticClass: "breadcrumb-item active" }, [
                      _vm._v(
                        _vm._s(
                          _vm.user.profile.first_name +
                            " " +
                            _vm.user.profile.last_name
                        ) +
                          " (" +
                          _vm._s(_vm.user.email) +
                          ")"
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/tax-payer")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("tax_payer.tax_payer_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c(
        "div",
        { attrs: { id: "user-profile" } },
        [
          _c("user-sidebar", { attrs: { menu: "password", id: _vm.id } }),
          _vm._v(" "),
          _c("section", { attrs: { id: "profile-info" } }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("user-summary", { attrs: { user: _vm.user } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-8 col-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.password")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-content" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-start align-items-center mb-1"
                          },
                          [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.submit.apply(null, arguments)
                                  },
                                  keydown: function($event) {
                                    return _vm.passwordForm.errors.clear(
                                      $event.target.name
                                    )
                                  }
                                }
                              },
                              [
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v(
                                    _vm._s(_vm.trans("auth.change_password"))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("auth.new_password")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _vm.getConfig(
                                            "password_strength_meter"
                                          )
                                            ? _c(
                                                "div",
                                                [
                                                  _c("password", {
                                                    attrs: {
                                                      name: "new_password",
                                                      defaultClass:
                                                        "form-control",
                                                      placeholder: _vm.trans(
                                                        "auth.new_password"
                                                      ),
                                                      required: false
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.passwordForm
                                                          .new_password,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.passwordForm,
                                                          "new_password",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "passwordForm.new_password"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.passwordForm
                                                          .new_password,
                                                      expression:
                                                        "passwordForm.new_password"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  class: {
                                                    "is-invalid": _vm.passwordForm.errors.has(
                                                      "new_password"
                                                    )
                                                  },
                                                  attrs: {
                                                    type: "password",
                                                    name: "new_password",
                                                    placeholder: _vm.trans(
                                                      "auth.new_password"
                                                    )
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.passwordForm
                                                        .new_password
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.passwordForm,
                                                        "new_password",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.passwordForm,
                                              "prop-name": "new_password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "auth.new_password_confirmation"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.passwordForm
                                                    .new_password_confirmation,
                                                expression:
                                                  "passwordForm.new_password_confirmation"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.passwordForm.errors.has(
                                                "new_password_confirmation"
                                              )
                                            },
                                            attrs: {
                                              type: "password",
                                              value: "",
                                              name: "new_password_confirmation",
                                              placeholder: _vm.trans(
                                                "auth.new_password_confirmation"
                                              )
                                            },
                                            domProps: {
                                              value:
                                                _vm.passwordForm
                                                  .new_password_confirmation
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.passwordForm,
                                                  "new_password_confirmation",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.passwordForm,
                                              "prop-name":
                                                "new_password_confirmation"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                                    attrs: { type: "submit" }
                                  },
                                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }