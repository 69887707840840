var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.type
    ? _c("div", { staticClass: "alert alert-info", attrs: { role: "alert" } }, [
        _c("span", [
          _c("i", {
            staticClass: "feather icon-alert-triangle mr-1 align-middle"
          }),
          _vm._v(
            " " + _vm._s(_vm.trans(_vm.module + "." + _vm.tip)) + "\n    \t"
          )
        ])
      ])
    : _vm.type
    ? _c("div", { staticClass: "position-relative d-inline-block" }, [
        _c(
          "span",
          {
            staticClass: "text-warning",
            attrs: {
              "data-toggle": "tooltip",
              "data-placement": "right",
              title: _vm.trans(_vm.module + "." + _vm.tip)
            }
          },
          [_c("i", { staticClass: "feather icon-alert-circle" })]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }