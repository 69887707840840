var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.receiptForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Tax Payer")]),
              _vm._v(" "),
              _c("v-select", {
                class: {
                  "form-control is-invalid": _vm.receiptForm.errors.has(
                    "user_id"
                  )
                },
                attrs: {
                  options: _vm.taxPayers,
                  reduce: function(name) {
                    return name.id
                  },
                  label: "name",
                  name: "user_id"
                },
                on: { input: _vm.populateTaxPayerInputs },
                model: {
                  value: _vm.receiptForm.user_id,
                  callback: function($$v) {
                    _vm.$set(_vm.receiptForm, "user_id", $$v)
                  },
                  expression: "receiptForm.user_id"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.receiptForm, "prop-name": "user_id" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.payer")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.payer,
                    expression: "receiptForm.payer"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.receiptForm.errors.has("payer") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "payer",
                  placeholder: _vm.trans("receipt.payer")
                },
                domProps: { value: _vm.receiptForm.payer },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.receiptForm, "payer", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.receiptForm, "prop-name": "payer" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.phone")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.phone,
                    expression: "receiptForm.phone"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.receiptForm.errors.has("phone") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "phone",
                  placeholder: _vm.trans("receipt.phone")
                },
                domProps: { value: _vm.receiptForm.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.receiptForm, "phone", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.receiptForm, "prop-name": "phone" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-4 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.address")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.address,
                    expression: "receiptForm.address"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.receiptForm.errors.has("address") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "address",
                  placeholder: _vm.trans("receipt.address")
                },
                domProps: { value: _vm.receiptForm.address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.receiptForm, "address", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.receiptForm, "prop-name": "address" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Pay Item(Revenue Code)")
              ]),
              _vm._v(" "),
              _c("v-select", {
                class: {
                  "form-control is-invalid": _vm.receiptForm.errors.has(
                    "pay_item_id"
                  )
                },
                attrs: {
                  options: _vm.payItems,
                  reduce: function(name) {
                    return name.id
                  },
                  label: "name"
                },
                model: {
                  value: _vm.receiptForm.pay_item_id,
                  callback: function($$v) {
                    _vm.$set(_vm.receiptForm, "pay_item_id", $$v)
                  },
                  expression: "receiptForm.pay_item_id"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.receiptForm,
                  "prop-name": "pay_item_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.amount")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.amount,
                    expression: "receiptForm.amount"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.receiptForm.errors.has("amount") },
                attrs: {
                  type: "text",
                  value: "",
                  name: "amount",
                  placeholder: _vm.trans("receipt.amount")
                },
                domProps: { value: _vm.receiptForm.amount },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.receiptForm, "amount", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.receiptForm, "prop-name": "amount" }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.amount_paid")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.amount_paid,
                    expression: "receiptForm.amount_paid"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.receiptForm.errors.has("amount_paid")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "amount_paid",
                  placeholder: _vm.trans("receipt.amount_paid")
                },
                domProps: { value: _vm.receiptForm.amount_paid },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.receiptForm,
                      "amount_paid",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.receiptForm,
                  "prop-name": "amount_paid"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.transaction_reference")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.transaction_reference,
                    expression: "receiptForm.transaction_reference"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.receiptForm.errors.has(
                    "transaction_reference"
                  )
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "transaction_reference",
                  placeholder: _vm.trans("receipt.transaction_reference")
                },
                domProps: { value: _vm.receiptForm.transaction_reference },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.receiptForm,
                      "transaction_reference",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.receiptForm,
                  "prop-name": "transaction_reference"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-6 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.trans("receipt.payment_details")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.receiptForm.payment_details,
                    expression: "receiptForm.payment_details"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "is-invalid": _vm.receiptForm.errors.has("payment_details")
                },
                attrs: {
                  type: "text",
                  value: "",
                  name: "payment_details",
                  placeholder: _vm.trans("receipt.payment_details")
                },
                domProps: { value: _vm.receiptForm.payment_details },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.receiptForm,
                      "payment_details",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.receiptForm,
                  "prop-name": "payment_details"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("file-upload-input", {
              attrs: {
                "button-text": "Upload Teller/Bank Statement",
                token: _vm.receiptForm.upload_token,
                module: "receipt",
                "clear-file": _vm.clear_message_attachment,
                "module-id": _vm.receiptForm.id,
                "has-file-type": true
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c(
              "button-spinner",
              {
                staticClass:
                  "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                staticStyle: { height: "39px", color: "#fff" },
                attrs: {
                  "is-loading": _vm.receiptForm.isLoading,
                  disabled: _vm.receiptForm.isLoading
                }
              },
              [
                _vm.id
                  ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                  : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                attrs: { to: "/receipt" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }