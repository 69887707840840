var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      { staticClass: "col-xl-8 col-10 d-flex justify-content-center" },
      [
        _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-12 p-0" }, [
              _c("div", { staticClass: "card rounded-0 mb-0 p-2" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("p", { staticClass: "px-2" }, [
                  _vm._v("Fill the below form to generate an invoice.")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body pt-0" }, [
                    _c(
                      "form",
                      {
                        attrs: { id: "registerform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.storePayment.apply(null, arguments)
                          },
                          keydown: function($event) {
                            return _vm.paymentForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xl-4 col-md-12 col-12" },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  { staticClass: "form-check-label" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paymentForm.type,
                                          expression: "paymentForm.type"
                                        }
                                      ],
                                      staticClass:
                                        "form-check-input radio-inline",
                                      attrs: {
                                        type: "radio",
                                        name: "type",
                                        id: "type1",
                                        value: "registered",
                                        checked: ""
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.paymentForm.type,
                                          "registered"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.paymentForm,
                                            "type",
                                            "registered"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                                            Registered Tax Payer"
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label",
                                    staticStyle: { "margin-left": "20px" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paymentForm.type,
                                          expression: "paymentForm.type"
                                        }
                                      ],
                                      staticClass:
                                        "form-check-input radio-inline",
                                      attrs: {
                                        type: "radio",
                                        name: "type",
                                        id: "type2",
                                        value: "non_registered"
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.paymentForm.type,
                                          "non_registered"
                                        )
                                      },
                                      on: {
                                        click: _vm.clearData,
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.paymentForm,
                                            "type",
                                            "non_registered"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(
                                      "\n                                            Non Registered Tax Payer"
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm.paymentForm.type == "registered"
                            ? _c(
                                "div",
                                { staticClass: "col-xl-9 col-md-12 col-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Tax Payer")
                                      ]),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        class: {
                                          "form-control is-invalid": _vm.paymentForm.errors.has(
                                            "name"
                                          )
                                        },
                                        attrs: {
                                          options: _vm.taxPayers,
                                          reduce: function(name) {
                                            return name.id
                                          },
                                          label: "name",
                                          name: "name"
                                        },
                                        on: {
                                          input: _vm.populateTaxPayerInputs
                                        },
                                        model: {
                                          value: _vm.paymentForm.name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.paymentForm,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "paymentForm.name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.paymentForm,
                                          "prop-name": "name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.paymentForm.type == "non_registered"
                            ? _c(
                                "div",
                                { staticClass: "col-xl-3 col-md-12 col-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(_vm._s(_vm.trans("user.name")))
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.paymentForm.name,
                                            expression: "paymentForm.name"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid": _vm.paymentForm.errors.has(
                                            "name"
                                          )
                                        },
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "name",
                                          placeholder: _vm.trans("user.name")
                                        },
                                        domProps: {
                                          value: _vm.paymentForm.name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.paymentForm,
                                              "name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.paymentForm,
                                          "prop-name": "name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.paymentForm.type == "non_registered"
                            ? _c(
                                "div",
                                { staticClass: "col-xl-3 col-md-12 col-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(_vm._s(_vm.trans("user.phone")))
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.paymentForm.phone,
                                            expression: "paymentForm.phone"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid": _vm.paymentForm.errors.has(
                                            "phone"
                                          )
                                        },
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "phone",
                                          placeholder: _vm.trans("user.phone")
                                        },
                                        domProps: {
                                          value: _vm.paymentForm.phone
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.paymentForm,
                                              "phone",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.paymentForm,
                                          "prop-name": "phone"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.paymentForm.type == "non_registered"
                            ? _c(
                                "div",
                                { staticClass: "col-xl-3 col-md-12 col-12" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(_vm._s(_vm.trans("user.email")))
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.paymentForm.email,
                                            expression: "paymentForm.email"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid": _vm.paymentForm.errors.has(
                                            "email"
                                          )
                                        },
                                        attrs: {
                                          type: "text",
                                          value: "",
                                          name: "email",
                                          placeholder: _vm.trans("user.email")
                                        },
                                        domProps: {
                                          value: _vm.paymentForm.email
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.paymentForm,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("show-error", {
                                        attrs: {
                                          "form-name": _vm.paymentForm,
                                          "prop-name": "email"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xl-3 col-md-12 col-12" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(_vm._s(_vm.trans("payment.year")))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paymentForm.payment_year,
                                          expression: "paymentForm.payment_year"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.paymentForm.errors.has(
                                          "payment_year"
                                        )
                                      },
                                      attrs: { name: "payment_year" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.paymentForm,
                                            "payment_year",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.years(), function(year) {
                                        return _c(
                                          "option",
                                          { domProps: { value: year } },
                                          [_vm._v(_vm._s(year))]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.paymentForm,
                                      "prop-name": "payment_year"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xl-12 col-md-12 col-12" },
                            [
                              _c("table", { staticClass: "table table-sm" }, [
                                _vm._m(2),
                                _vm._v(" "),
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "5" } }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 mb-2" },
                                          [
                                            _c("v-select", {
                                              class: {
                                                "form-control is-invalid": _vm.paymentForm.errors.has(
                                                  "pay_item_id"
                                                )
                                              },
                                              attrs: {
                                                options: _vm.payItems,
                                                reduce: function(name) {
                                                  return name.id
                                                },
                                                label: "name"
                                              },
                                              model: {
                                                value:
                                                  _vm.paymentForm.pay_item_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.paymentForm,
                                                    "pay_item_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "paymentForm.pay_item_id"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.paymentForm,
                                                "prop-name": "pay_item_id"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-xl-3 col-md-12" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.paymentForm.amount,
                                                  expression:
                                                    "paymentForm.amount"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.paymentForm.errors.has(
                                                  "amount"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                placeholder: "Amount"
                                              },
                                              domProps: {
                                                value: _vm.paymentForm.amount
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.paymentForm,
                                                    "amount",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.paymentForm,
                                                "prop-name": "amount"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-xl-3 col-sm-12" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.paymentForm.description,
                                                  expression:
                                                    "paymentForm.description"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.paymentForm.errors.has(
                                                  "description"
                                                )
                                              },
                                              attrs: {
                                                type: "text",
                                                value: "",
                                                placeholder: "Description"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.paymentForm.description
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.paymentForm,
                                                    "description",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.paymentForm,
                                                "prop-name": "description"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-xl-3 col-md-12" },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.paymentForm
                                                        .month_from,
                                                    expression:
                                                      "paymentForm.month_from"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  "is-invalid": _vm.paymentForm.errors.has(
                                                    "month_from"
                                                  )
                                                },
                                                attrs: { name: "month_from" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.paymentForm,
                                                      "month_from",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("Month From")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "January" }
                                                  },
                                                  [_vm._v("January")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "February" }
                                                  },
                                                  [_vm._v("February")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "March" } },
                                                  [_vm._v("March")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "April" } },
                                                  [_vm._v("April")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "May" } },
                                                  [_vm._v("May")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "June" } },
                                                  [_vm._v("June")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "July" } },
                                                  [_vm._v("July")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "August" }
                                                  },
                                                  [_vm._v("August")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "September"
                                                    }
                                                  },
                                                  [_vm._v("September")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "October" }
                                                  },
                                                  [_vm._v("October")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "November" }
                                                  },
                                                  [_vm._v("November")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "December" }
                                                  },
                                                  [_vm._v("December")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.paymentForm,
                                                "prop-name": "month_from"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-xl-3 col-md-12" },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.paymentForm.month_to,
                                                    expression:
                                                      "paymentForm.month_to"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  "is-invalid": _vm.paymentForm.errors.has(
                                                    "month_to"
                                                  )
                                                },
                                                attrs: { name: "month_to" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.paymentForm,
                                                      "month_to",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "" } },
                                                  [_vm._v("Month To")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "January" }
                                                  },
                                                  [_vm._v("January")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "February" }
                                                  },
                                                  [_vm._v("February")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "March" } },
                                                  [_vm._v("March")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "April" } },
                                                  [_vm._v("April")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "May" } },
                                                  [_vm._v("May")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "June" } },
                                                  [_vm._v("June")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "July" } },
                                                  [_vm._v("July")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "August" }
                                                  },
                                                  [_vm._v("August")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "September"
                                                    }
                                                  },
                                                  [_vm._v("September")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "October" }
                                                  },
                                                  [_vm._v("October")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "November" }
                                                  },
                                                  [_vm._v("November")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "December" }
                                                  },
                                                  [_vm._v("December")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("show-error", {
                                              attrs: {
                                                "form-name": _vm.paymentForm,
                                                "prop-name": "month_to"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xl-6 col-md-6 col-6" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("user.tax_office_id"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paymentForm.tax_office_id,
                                          expression:
                                            "paymentForm.tax_office_id"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.paymentForm.errors.has(
                                          "tax_office_id"
                                        )
                                      },
                                      attrs: { name: "tax_office_id" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.paymentForm,
                                            "tax_office_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select Tax Office")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.taxOffices, function(
                                        taxOffice
                                      ) {
                                        return _c(
                                          "option",
                                          { domProps: { value: taxOffice.id } },
                                          [_vm._v(_vm._s(taxOffice.name))]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.paymentForm,
                                      "prop-name": "tax_office_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xl-6 col-md-6 col-6" },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("payment.gateway_id"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.paymentForm.gateway_id,
                                          expression: "paymentForm.gateway_id"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.paymentForm.errors.has(
                                          "gateway_id"
                                        )
                                      },
                                      attrs: {
                                        name: "gateway_id",
                                        placeholder:
                                          "Select Payment \n                                            Gateway"
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.paymentForm,
                                            "gateway_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Select Payment Channel")
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.gateways, function(gateway) {
                                        return _c(
                                          "option",
                                          { domProps: { value: gateway.id } },
                                          [_vm._v(_vm._s(gateway.name))]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.paymentForm,
                                      "prop-name": "gateway_id"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "btn btn-outline-primary float-left btn-inline mb-50",
                            attrs: { to: "/login" }
                          },
                          [_vm._v("Back")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button-spinner",
                          {
                            staticClass:
                              "btn btn-primary float-right btn-inline mb-50",
                            staticStyle: { height: "39px", color: "#fff" },
                            attrs: {
                              "is-loading": _vm.paymentForm.isLoading,
                              disabled: _vm.paymentForm.isLoading
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    Confirm\n                                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "modal fade",
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "confirmPayment",
                      tabindex: "-1",
                      role: "dialog",
                      "aria-labelledby": "paymentDetail",
                      "aria-hidden": "true"
                    }
                  },
                  [
                    _c("div", { staticClass: "modal-dialog modal-lg" }, [
                      _c("div", { staticClass: "modal-content" }, [
                        _vm._m(4),
                        _vm._v(" "),
                        _vm.showConfirm
                          ? _c("div", { staticClass: "modal-body" }, [
                              _c("h4", [_vm._v("Confirm Payment")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("Transaction Reference: "),
                                _c("strong", [_vm._v(_vm._s(_vm.reference))])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("TAX ID: "),
                                _c("strong", [_vm._v(_vm._s(_vm.tax_id))])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("Phone NO: "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.paymentForm.phone))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("Email: "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.paymentForm.email))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("Year: "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.paymentForm.payment_year))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("table", { staticClass: "table" }, [
                                _vm._m(5),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [_vm._v(_vm._s(_vm.pay_item_name))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.paymentForm.amount))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.paymentForm.description))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.paymentForm.month_from))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(_vm.paymentForm.month_to))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v("Total Amount: "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.paymentForm.amount))
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger waves-effect text-left",
                              attrs: { type: "button", "data-dismiss": "modal" }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.close")))]
                          ),
                          _vm._v(" "),
                          _vm.paymentForm.gateway_id == 1
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary waves-effect",
                                  attrs: { type: "button" },
                                  on: { click: _vm.generateRRR }
                                },
                                [_vm._v("Generate RRR")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary waves-effect",
                              attrs: { type: "button" },
                              on: { click: _vm.confirmPayment }
                            },
                            [_vm._v("Confirm")]
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "modal fade",
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "notification",
                      tabindex: "-1",
                      role: "dialog",
                      "aria-labelledby": "notificationDetails",
                      "aria-hidden": "true"
                    }
                  },
                  [
                    _c("div", { staticClass: "modal-dialog modal-lg" }, [
                      _c("div", { staticClass: "modal-content" }, [
                        _vm._m(6),
                        _vm._v(" "),
                        _vm._m(7),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-danger waves-effect text-left",
                              attrs: { type: "button", "data-dismiss": "modal" }
                            },
                            [_vm._v(_vm._s(_vm.trans("general.close")))]
                          )
                        ])
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-lg-12 d-lg-block d-none text-center align-self-center",
        staticStyle: { padding: "40px", "margin-top": "20px" }
      },
      [
        _c("img", {
          attrs: {
            src: "images/pages/login.png",
            width: "332px",
            alt: "branding logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header pt-50 pb-1" }, [
      _c("div", { staticClass: "card-title" }, [
        _c("h4", { staticClass: "mb-0" }, [_vm._v("E-TAX PAYMENT")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { attrs: { width: "50%" } }, [_vm._v("Pay Item")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "paymentDetail" } }, [
        _vm._v("Payment Confirmation")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Item")]),
      _vm._v(" "),
      _c("th", [_vm._v("Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Month From")]),
      _vm._v(" "),
      _c("th", [_vm._v("Month To")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h4",
        { staticClass: "modal-title", attrs: { id: "notificationDetails" } },
        [_vm._v("Notice")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("Dear Taxpayers,")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          'We wish to notify you of our ETax portal currently being upgraded. You can log in to your existing profile using your State TaxID as default username and a default password as "'
        ),
        _c("strong", [_vm._v("password")]),
        _vm._v('" which we advise you to update accordingly.')
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "You can pay your taxes, levies, fines, and charges online from your comfort zone, as well as file your annual returns and request for Tax Clearance Certificate."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("For any inquiry or support fell free to contact us via "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("info@irs.sk.gov.ng")]),
        _vm._v(" or call "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("+234 708 534 9849")]),
        _vm._v(".")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                                        Thank you.\n                                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }