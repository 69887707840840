var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer static footer-light" }, [
    _c("p", { staticClass: "clearfix blue-grey lighten-2 mb-0" }, [
      _c(
        "span",
        { staticClass: "float-md-left d-block d-md-inline-block mt-25" },
        [
          _vm._v("COPYRIGHT © 2022"),
          _c(
            "a",
            {
              staticClass: "text-bold-800 grey darken-2",
              attrs: { href: "https://hilinksnetworks.ng", target: "_blank" }
            },
            [_vm._v(_vm._s(_vm.getConfig("footer_credit")) + ",")]
          ),
          _vm._v("All rights Reserved")
        ]
      ),
      _c("span", { staticClass: "float-md-right d-none d-md-block" }, [
        _vm._v("Version 2.1")
      ]),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-primary btn-icon scroll-top",
        attrs: { type: "button" }
      },
      [_c("i", { staticClass: "feather icon-arrow-up" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }