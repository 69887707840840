var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("receipt.verify_receipt")) +
                  "\n                     "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                 "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/receipt" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("receipt.receipt")) +
                          "\n                                 "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 " +
                      _vm._s(_vm.trans("receipt.verify_receipt")) +
                      "\n                             "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _c("div", { staticClass: "card border-bottom" }, [
                  _c(
                    "div",
                    { staticClass: "card-header" },
                    [
                      _c("h4", { staticClass: "card-title float-left mb-0" }, [
                        _vm._v(_vm._s(_vm.trans("general.filter")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "export-excel",
                        {
                          staticClass:
                            "btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right",
                          attrs: {
                            data: _vm.receipts.data,
                            fields: _vm.json_fields,
                            worksheet: "Sokoto State Inland Revenue Service",
                            name: "receipt.xls"
                          }
                        },
                        [
                          _c("i", { staticClass: "feather icon-file-text" }),
                          _vm._v(
                            " Export to Excel\n                              \n                             "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-8" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("receipt.transaction_reference")
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.searchReceiptForm.paymentId,
                                  expression: "searchReceiptForm.paymentId"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name: "transaction_reference",
                                placeholder: "Enter Payment Reference"
                              },
                              domProps: {
                                value: _vm.searchReceiptForm.paymentId
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.searchReceiptForm,
                                    "paymentId",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-primary waves-effect waves-light pull-left",
                              on: { click: _vm.search }
                            },
                            [_vm._v(_vm._s(_vm.trans("receipt.find")))]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.showSearchPanel
                ? _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-content" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _vm.receipts.total
                          ? _c("div", { staticClass: "table-responsive" }, [
                              _c("table", { staticClass: "table" }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("receipt.date")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.trans("receipt.tax_id"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("receipt.payer")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.trans("receipt.phone")))
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.trans("receipt.amount_paid"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.trans("receipt.rev_code"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.trans(
                                            "receipt.transaction_reference"
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(_vm.trans("receipt.status"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "table-option" }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("general.action"))
                                      )
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.receipts.data, function(
                                    receipt,
                                    index
                                  ) {
                                    return _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("momentDateTime")(
                                              receipt.created_at
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            receipt.user.tax_id
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(receipt.payer)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(receipt.phone)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatMoney")(
                                              receipt.amount_paid
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(receipt.rev_code)
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          textContent: _vm._s(
                                            receipt.transaction_reference
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getStatus(receipt)
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "table-option" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "btn-group" },
                                            [
                                              _vm.hasPermission("print-receipt")
                                                ? _c(
                                                    "a",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "receipt.print_receipt"
                                                          ),
                                                          expression:
                                                            "trans('receipt.print_receipt')"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "btn btn-primary btn-sm",
                                                      attrs: {
                                                        href:
                                                          "/receipt/" +
                                                          receipt.reference_no +
                                                          "/print"
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "feather icon-printer"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "receipt.view_receipt"
                                                      ),
                                                      expression:
                                                        "trans('receipt.view_receipt')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-success btn-sm",
                                                  attrs: {
                                                    to: "/receipt/" + receipt.id
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "feather icon-eye"
                                                  })
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.receipts.total
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "alert alert-danger mt-1 alert-validation-msg",
                                attrs: { role: "alert" }
                              },
                              [_vm._m(0)]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "alert-body d-flex align-items-center" }, [
      _c("i", { staticClass: "me-50", attrs: { "data-feather": "info" } }),
      _vm._v(" "),
      _c("span", [
        _vm._v("No receipt found for this "),
        _c("strong", [_vm._v("TIN")]),
        _vm._v(" Number.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }