<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('user.profile')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Profile</a>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/change-password')"><i class="feather icon-lock"></i> {{trans('auth.change_password')}}</button>
                </div>
            </div>
        </div>

    <div class="content-body">
        <div id="user-profile">
  
  <section id="profile-info">
    <div class="row">
        <div class="col-lg-4 col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{ imageTitle }}</h4>
            <i class="feather icon-more-horizontal cursor-pointer"></i>
          </div>
          <div class="card-body">
            <upload-image id="avatar" :upload-path="`/user/profile/avatar/${getAuthUser('id')}`" :remove-path="`/user/profile/avatar/remove/${getAuthUser('id')}`" :image-source="avatar.user" @uploaded="updateAvatar" @removed="updateAvatar"></upload-image>
          </div>
        </div>
        
      </div>

      
      <div class="col-lg-8 col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{trans('user.update_profile')}} {{ title(profileForm.type) }}</h4>
            </div>
          <div class="card-content">
              <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-1">
                <form @submit.prevent="updateProfile" @keydown="profileForm.errors.clear($event.target.name)">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.username')}}</label>
                                <input class="form-control" type="text" name="username" v-model="profileForm.username" disabled>
                                <show-error :form-name="profileForm" prop-name="username"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.email')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('email') }" type="text" name="email" v-model="profileForm.email">
                                <show-error :form-name="profileForm" prop-name="email"></show-error>
                            </div>
                        </div>
                    </div>
                    <div v-if="profileForm.type == 'individual'" class="row">
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.title')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('title') }" name="title" v-model="profileForm.title">
                                    <option value="">{{trans('user.title')}}</option>
                                    <option v-for="title in titles" :value="title.id">{{title.id}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="title"></show-error>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.first_name')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('first_name') }" type="text" name="first_name" v-model="profileForm.first_name">
                                <show-error :form-name="profileForm" prop-name="first_name"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.last_name')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('last_name') }" type="text" name="last_name" v-model="profileForm.last_name">
                                <show-error :form-name="profileForm" prop-name="last_name"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.bvn')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('bvn') }" type="text" name="bvn" v-model="profileForm.bvn">
                                <show-error :form-name="profileForm" prop-name="bvn"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.sector_id')}}</label>
                                <v-select @input="getSectorLines" :options="sectors" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': profileForm.errors.has('sector_id') }" name="sector_id" v-model="profileForm.sector_id"></v-select>
                                <show-error :form-name="profileForm" prop-name="sector_id"></show-error>
                            </div>
                        </div>
                                                
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                                <label for="">{{trans('user.sector_line_id')}}</label>
                                <v-select :options="sectorLines" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': profileForm.errors.has('sector_line_id') }" name="sector_line_id" v-model="profileForm.sector_line_id"></v-select>
                                <show-error :form-name="profileForm" prop-name="sector_line_id"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-2">
                            <div class="form-group">
                                <label for="">Size</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('size') }" name="size" v-model="profileForm.size">
                                    <option value="Large">Large</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Small">Small</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="size"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.date_of_birth')}}</label>
                                <datepicker v-model="profileForm.date_of_birth" :bootstrapStyling="true" :class="{ 'form-control is-invalid': profileForm.errors.has('date_of_birth') }" name="date_of_birth" @selected="profileForm.errors.clear('date_of_birth')"></datepicker>
                                <show-error :form-name="profileForm" prop-name="date_of_birth"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.gender')}}</label>
                                <div class="radio radio-info">
                                    <div class="row">
                                        <div v-for="gender in genders" class="col-12 col-sm-4">
                                            <input type="radio" :value="gender.id" :id="gender.id" v-model="profileForm.gender" :checked="profileForm.gender == gender.id" name="gender" @click="profileForm.errors.clear('gender')">
                                            <label :for="gender.id"> {{trans('list.'+gender.id)}}</label>
                                        </div>
                                    </div>
                                </div>
                                <show-error :form-name="profileForm" prop-name="gender"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.state')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('state_id') }" name="state_id" v-model="profileForm.state_id" disabled>
                                    <option v-for="state in states" :value="state.id">{{state.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="state_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.lga_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('lga_id') }" name="lga_id" v-model="profileForm.lga_id">
                                    <option v-for="lga in lgas" :value="lga.id">{{lga.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="lga_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.tax_office_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('tax_office_id') }" name="tax_office_id" v-model="profileForm.tax_office_id">
                                    <option v-for="taxOffice in taxOffices" :value="taxOffice.id">{{taxOffice.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="tax_office_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.birth_place')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('birth_place') }" type="text" name="birth_place" v-model="profileForm.birth_place">
                                <show-error :form-name="profileForm" prop-name="birth_place"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.occupation')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('occupation_id') }" name="occupation_id" v-model="profileForm.occupation_id">
                                    <option v-for="occupation in occupations" :value="occupation.id">{{occupation.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="occupation_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.source_of_income_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('source_of_income_id') }" name="source_of_income_id" v-model="profileForm.source_of_income_id">
                                    <option v-for="category in categories" :value="category.id">{{category.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="source_of_income_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.nationality')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('nationality') }" type="text" name="nationality" v-model="profileForm.nationality">
                                <show-error :form-name="profileForm" prop-name="nationality"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.address')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('address') }" type="text" name="address" v-model="profileForm.address">
                                <show-error :form-name="profileForm" prop-name="address"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.city')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('city') }" type="text" name="city" v-model="profileForm.city">
                                <show-error :form-name="profileForm" prop-name="city"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.state_of_origin_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('state_of_origin_id') }" name="state_of_origin_id" v-model="profileForm.state_of_origin_id">
                                    <option v-for="state in states" :value="state.id">{{state.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="state_of_origin_id"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.tax_authority')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('tax_authority') }" type="text" name="tax_authority" v-model="profileForm.tax_authority" readonly>
                                <show-error :form-name="profileForm" prop-name="tax_authority"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.employer_name')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('employer_name') }" type="text" name="employer_name" v-model="profileForm.employer_name">
                                <show-error :form-name="profileForm" prop-name="employer_name"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.employer_tin')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('employer_tin') }" type="text" name="employer_tin" v-model="profileForm.employer_tin">
                                <show-error :form-name="profileForm" prop-name="employer_tin"></show-error>
                            </div>
                        </div>
                    </div>
                    <div v-if="profileForm.type == 'business'" class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.business_name')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('business_name') }" type="text" name="business_name" v-model="profileForm.business_name">
                                <show-error :form-name="profileForm" prop-name="business_name"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.registered_name')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('registered_name') }" type="text" name="registered_name" v-model="profileForm.registered_name">
                                <show-error :form-name="profileForm" prop-name="registered_name"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.organization_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('organization_id') }" name="organization_id" v-model="profileForm.organization_id">
                                    <option v-for="organization in organizations" :value="organization.id">{{organization.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="organization_id"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.business_type')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('business_type_id') }" name="business_type_id" v-model="profileForm.business_type_id">
                                    <option v-for="businessType in businessTypes" :value="businessType.id">{{businessType.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="business_type_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="">{{trans('user.sector_id')}}</label>
                                <v-select @input="getSectorLines" :options="sectors" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': profileForm.errors.has('sector_id') }" name="sector_id" v-model="profileForm.sector_id"></v-select>
                                <show-error :form-name="profileForm" prop-name="sector_id"></show-error>
                            </div>
                        </div>
                                                
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                                <label for="">{{trans('user.sector_line_id')}}</label>
                                <v-select :options="sectorLines" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': profileForm.errors.has('sector_line_id') }" name="sector_line_id" v-model="profileForm.sector_line_id"></v-select>
                                <show-error :form-name="profileForm" prop-name="sector_line_id"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-2">
                            <div class="form-group">
                                <label for="">Size</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('size') }" name="size" v-model="profileForm.size">
                                    <option value="Large">Large</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Small">Small</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="size"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.rc_no')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('rc_no') }" type="text" name="rc_no" v-model="profileForm.rc_no">
                                <show-error :form-name="profileForm" prop-name="rc_no"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.enterprise_registration_no')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('enterprise_registration_no') }" type="text" name="enterprise_registration_no" v-model="profileForm.enterprise_registration_no">
                                <show-error :form-name="profileForm" prop-name="enterprise_registration_no"></show-error>
                            </div>
                        </div>
                       
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.date_of_commencement')}}</label>
                                <datepicker v-model="profileForm.date_of_commencement" :bootstrapStyling="true" :class="{ 'form-control is-invalid': profileForm.errors.has('date_of_commencement') }" name="date_of_commencement" @selected="profileForm.errors.clear('date_of_commencement')"></datepicker>
                                <show-error :form-name="profileForm" prop-name="date_of_commencement"></show-error>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.state')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('state_id') }" name="state_id" v-model="profileForm.state_id" disabled>
                                    <option v-for="state in states" :value="state.id">{{state.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="state_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.lga_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('lga_id') }" name="lga_id" v-model="profileForm.lga_id">
                                    <option v-for="lga in lgas" :value="lga.id">{{lga.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="lga_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.address')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('address') }" type="text" name="address" v-model="profileForm.address">
                                <show-error :form-name="profileForm" prop-name="address"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.city')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('city') }" type="text" name="city" v-model="profileForm.city">
                                <show-error :form-name="profileForm" prop-name="city"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.tax_office_id')}}</label>
                                <select class="form-control" :class="{ 'is-invalid': profileForm.errors.has('tax_office_id') }" name="tax_office_id" v-model="profileForm.tax_office_id">
                                    <option v-for="taxOffice in taxOffices" :value="taxOffice.id">{{taxOffice.name}}</option>
                                </select>
                                <show-error :form-name="profileForm" prop-name="tax_office_id"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.tax_authority')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('tax_authority') }" type="text" name="tax_authority" v-model="profileForm.tax_authority" readonly>
                                <show-error :form-name="profileForm" prop-name="tax_authority"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.company_tin')}}</label>
                                <input class="form-control" type="text" name="company_tin" v-model="profileForm.company_tin">
                                <show-error :form-name="profileForm" prop-name="company_tin"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.date_of_incorporation')}}</label>
                                <datepicker v-model="profileForm.date_of_incorporation" :bootstrapStyling="true" :class="{ 'form-control is-invalid': profileForm.errors.has('date_of_incorporation') }" name="date_of_incorporation" @selected="profileForm.errors.clear('date_of_incorporation')"></datepicker>
                                <show-error :form-name="profileForm" prop-name="date_of_incorporation"></show-error>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.issuance_place')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('issuance_place') }" type="text" name="issuance_place" v-model="profileForm.issuance_place">
                                <show-error :form-name="profileForm" prop-name="issuance_place"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.updated_by')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('updated_by') }" type="text" name="updated_by" v-model="profileForm.updated_by">
                                <show-error :form-name="profileForm" prop-name="updated_by"></show-error>
                            </div>
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label for="">{{trans('user.update_time')}}</label>
                                <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('update_time') }" type="text" name="update_time" v-model="profileForm.update_time">
                                <show-error :form-name="profileForm" prop-name="update_time"></show-error>
                            </div>
                        </div>
                       
                    </div>

                    <div v-if="profileForm.type == 'staff'" class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.first_name')}}</label>
                                        <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('first_name') }" type="text" name="first_name" v-model="profileForm.first_name">
                                        <show-error :form-name="profileForm" prop-name="first_name"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.last_name')}}</label>
                                        <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('last_name') }" type="text" name="last_name" v-model="profileForm.last_name">
                                        <show-error :form-name="profileForm" prop-name="last_name"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.date_of_birth')}}</label>
                                        <datepicker v-model="profileForm.date_of_birth" :bootstrapStyling="true" :class="{ 'form-control is-invalid': profileForm.errors.has('date_of_birth') }" name="date_of_birth" @selected="profileForm.errors.clear('date_of_birth')"></datepicker>
                                        <show-error :form-name="profileForm" prop-name="date_of_birth"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.date_of_anniversary')}}</label>
                                        <datepicker v-model="profileForm.date_of_anniversary" :bootstrapStyling="true" :class="{ 'form-control is-invalid': profileForm.errors.has('date_of_anniversary') }" name="date_of_anniversary" @selected="profileForm.errors.clear('date_of_anniversary')"></datepicker>
                                        <show-error :form-name="profileForm" prop-name="date_of_anniversary"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.gender')}}</label>
                                        <div class="radio radio-info">
                                            <div class="row">
                                                <div v-for="gender in genders" class="col-12 col-sm-4">
                                                    <input type="radio" :value="gender.id" :id="gender.id" v-model="profileForm.gender" :checked="profileForm.gender == gender.id" name="gender" @click="profileForm.errors.clear('gender')">
                                                    <label :for="gender.id"> {{trans('list.'+gender.id)}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <show-error :form-name="profileForm" prop-name="gender"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">

                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.sm_profile',{name: 'Facebook'})}}</label>
                                        <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('facebook_profile') }" type="text" name="facebook_profile" v-model="profileForm.facebook_profile">
                                        <show-error :form-name="profileForm" prop-name="facebook_profile"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.sm_profile',{name: 'Twitter'})}}</label>
                                        <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('twitter_profile') }" type="text" name="twitter_profile" v-model="profileForm.twitter_profile">
                                        <show-error :form-name="profileForm" prop-name="twitter_profile"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.sm_profile',{name: 'Google Plus'})}}</label>
                                        <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('google_plus_profile') }" type="text" name="google_plus_profile" v-model="profileForm.google_plus_profile">
                                        <show-error :form-name="profileForm" prop-name="google_plus_profile"></show-error>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="">{{trans('user.sm_profile',{name: 'Linkedin'})}}</label>
                                        <input class="form-control" :class="{ 'is-invalid': profileForm.errors.has('linkedin_profile') }" type="text" name="linkedin_profile" v-model="profileForm.linkedin_profile">
                                        <show-error :form-name="profileForm" prop-name="linkedin_profile"></show-error>
                                    </div>
                                </div>
                            </div>
                    <button type="submit" class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right">{{trans('general.save')}}</button>
                </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
   
   </div>
    </div>
</template>

<script>
    import datepicker from 'vuejs-datepicker'
    import uploadImage from '../../components/upload-image'

    export default {
        components : { datepicker,uploadImage },
        data() {
            return {
                profileForm: new Form({
                    user_id: '',
                    type: '',
                    username: '',
                    email: '',
                    first_name : '',
                    last_name : '',
                    date_of_birth : '',
                    date_of_anniversary : '',
                    gender : '',
                    title : '',
                    bvn : '',
                    state_id : '34',	
                    lga_id : '',
                    tax_office_id : '',	
                    address : '',
                    city: '',
                    birth_place : '',	
                    occupation_id : '',
                    source_of_income_id : '',		
                    nationality : '',
                    state_of_origin_id : '',	
                    tax_authority : 'Sokoto State Inland Revenue',
                    employer_name : '',
                    employer_tin : '',
                    business_name : '',	
                    registered_name : '',
                    organization_id : '',
                    business_type_id : '',
                    rc_no : '',
                    enterprise_registration_no : '',
                    date_of_commencement : '',	
                    sector_id : '',
                    sector_line_id : '',
                    size : '',
                    company_tin : '',
                    date_of_incorporation : '',
                    issuance_place : '',
                    updated_by : '',
                    update_time : '',
                    facebook_profile : '',
                    twitter_profile : '',
                    google_plus_profile : '',
                    linkedin_profile : ''
                }, false),
                avatar: {
                    user: ''
                },
                genders: [],
                titles: [],
                states: [],
                lgas: [],
                taxOffices: [],
                categories: [],
                organizations: [],
                occupations: [],
                sectors: [],
                sectorLines: [],
                businessTypes: [],
                user: {
                    profile: {}
                }
            };
        },
        mounted(){
            axios.get('/api/user/profile/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.profileForm.user_id = response.user.profile.user_id;
                    this.user = response.user;
                    this.profileForm.type = response.user.type;
                    this.profileForm.username = response.user.username;
                    this.profileForm.email = response.user.email;
                    this.profileForm.first_name = response.user.profile.first_name;
                    this.profileForm.last_name = response.user.profile.last_name;
                    this.profileForm.date_of_birth = response.user.profile.date_of_birth;
                    this.profileForm.date_of_anniversary = response.user.profile.date_of_anniversary;
                    this.profileForm.gender = response.user.profile.gender;
                    this.profileForm.facebook_profile = response.user.profile.facebook_profile;
                    this.profileForm.twitter_profile = response.user.profile.twitter_profile;
                    this.profileForm.google_plus_profile = response.user.profile.google_plus_profile;
                    this.profileForm.linkedin_profile = response.user.profile.linkedin_profile;
                    this.avatar.user = response.user.profile.avatar;
                    this.genders = response.genders;
                    this.titles = response.titles;
                    this.states = response.states;
                    this.lgas = response.lgas;
                    this.taxOffices = response.taxOffices;
                    this.categories = response.categories;
                    this.organizations = response.organizations;
                    this.occupations = response.occupations;
                    this.businessTypes = response.businessTypes;
                    this.sectors = response.sectors;
                    this.profileForm.title  = response.user.profile.title;
                    this.profileForm.bvn  = response.user.profile.bvn;
                    this.profileForm.state_id = 34;
                    this.profileForm.lga_id  = response.user.profile.lga_id;
                    this.profileForm.tax_office_id  = response.user.profile.tax_office_id;	
                    this.profileForm.address  = response.user.profile.address;
                    this.profileForm.city  = response.user.profile.city;
                    this.profileForm.birth_place  = response.user.profile.birth_place;
                    this.profileForm.occupation_id  = response.user.profile.occupation_id;
                    this.profileForm.source_of_income_id  = response.user.profile.source_of_income_id;	
                    this.profileForm.nationality  = response.user.profile.nationality;
                    this.profileForm.state_of_origin_id  = response.user.profile.state_of_origin_id;
                    this.profileForm.tax_authority  = 'Sokoto State Internal Revenue Service'; //response.user.profile.tax_authority;
                    this.profileForm.employer_name  = response.user.profile.employer_name;
                    this.profileForm.employer_tin  = response.user.profile.employer_tin;
                    this.profileForm.business_name  = response.user.profile.business_name;
                    this.profileForm.registered_name  = response.user.profile.registered_name;
                    this.profileForm.organization_id  = response.user.profile.organization_id;
                    this.profileForm.rc_no  = response.user.profile.rc_no;
                    this.profileForm.enterprise_registration_no  = response.user.profile.enterprise_registration_no;
                    this.profileForm.date_of_commencement  = response.user.profile.date_of_commencement;
                    this.profileForm.business_type_id  = response.user.profile.business_type_id;
                    this.profileForm.sector_id  = response.user.profile.sector_id;
                    this.profileForm.sector_line_id  = response.user.profile.sector_line_id;
                    this.profileForm.size  = response.user.profile.size;
                    this.profileForm.company_tin  = response.user.profile.company_tin;
                    this.profileForm.date_of_incorporation  = response.user.profile.date_of_incorporation;
                    this.profileForm.issuance_place  = response.user.profile.issuance_place;
                    this.profileForm.updated_by  = response.user.profile.updated_by;
                    this.profileForm.update_time  = response.user.profile.update_time;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
            updateProfile() {
                this.profileForm.date_of_birth = (this.profileForm.date_of_birth) ? moment(this.profileForm.date_of_birth).format('YYYY-MM-DD') : null;
                this.profileForm.date_of_anniversary = (this.profileForm.date_of_anniversary) ? moment(this.profileForm.date_of_anniversary).format('YYYY-MM-DD') : null;
                this.profileForm.date_of_commencement = (this.profileForm.date_of_commencement) ? moment(this.profileForm.date_of_commencement).format('YYYY-MM-DD') : null;
                this.profileForm.date_of_incorporation = (this.profileForm.date_of_incorporation) ? moment(this.profileForm.date_of_incorporation).format('YYYY-MM-DD') : null;
                this.profileForm.post('/api/user/profile/update')
                    .then(response => {
                        toastr.success(response.message);
                        this.$store.dispatch('setAuthUserDetail',{
                            first_name: this.profileForm.first_name,
                            last_name: this.profileForm.last_name
                        });
                        this.$router.push('/print-details');
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            updateAvatar(val){
                this.$store.dispatch('setAuthUserDetail',{
                    avatar: val
                });
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },

            getRole(role){
              return helper.getRole(role);
            },

            title(user){
                if (this.getRole() == 'user') {
                    return '(' + user + ' Tax Payer)';
                } else {
                    return '';
                }
                
            },
            getSectorLines(event){
                axios.get('/api/sector-lines-by-sector-id/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.sectorLines = response.sectorLines;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
        },
        computed: {
            imageTitle(){
                if (this.profileForm.type == 'individual') {
                    return 'Passport';
                } else {
                    return 'Logo';
                }
			}
        }
    }
</script>
