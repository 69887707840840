var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.progress
    ? _c("div", { staticClass: "progress" }, [
        _c(
          "div",
          {
            staticClass: "progress-bar progress-bar-info progress-bar-striped",
            style: { width: _vm.getProgress },
            attrs: { role: "progressbar" }
          },
          [
            _vm.progress < 100 && _vm.showProgressText
              ? _c("span", [
                  _vm._v(
                    "\n            " + _vm._s(_vm.getProgress) + "\n        "
                  )
                ])
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }