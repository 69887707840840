<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('general.report')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('general.report') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" v-if="!showFilterPanel" @click="showFilterPanel = !showFilterPanel"><i class="feather icon-filter"></i> {{trans('general.filter')}}</button>

                    <button class="btn btn-primary btn-sm pull-right mr-2" @click="printReport()"><i class="feather icon-printer"></i> Print</button>
                </div>
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom" v-if="showFilterPanel">
                            <div class="card-header">
                                <h4 class="card-title float-left mb-0">{{trans('general.filter')}}</h4>
                                
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">{{trans('payment.reference_rrr_trx')}}</label>
                                            <input class="form-control" name="keyword" v-model="filterReportForm.keyword">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">MDA</label>
                                            <v-select :options="mdas" :reduce="name => name.id" label="name" name="mda_id" v-model="filterReportForm.mda_id"></v-select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">Source</label>
                                            <select class="form-control" name="source" v-model="filterReportForm.source" placeholder="Select Source">
                                                <option value="all">All</option>
                                                <option value="online">Online</option>
                                                <option value="receipt">Receipt</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="">Status</label>
                                            <select class="form-control" name="status" v-model="filterReportForm.status" placeholder="Select Status">
                                                <option value="all">All</option>
                                                <option value="paid">Paid</option>
                                                <option value="unpaid">Unpaid</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <date-range-picker :start-date.sync="filterReportForm.start_date" :end-date.sync="filterReportForm.end_date" :label="trans('general.date_between')"></date-range-picker>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1" v-if="showFilterPanel" @click="showFilterPanel = !showFilterPanel">{{trans('general.cancel')}}</button>
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card">
                        <div class="card-header">
                            <h4 v-if="reports.total" class="card-title">
                                <sort-by :order-by-options="orderByOptions" :sort-by="filterReportForm.sort_by" :order="filterReportForm.order" @updateSortBy="value => {filterReportForm.sort_by = value}"  @updateOrder="value => {filterReportForm.order = value}"></sort-by>
                            </h4>
                        </div>
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="reports.total">
                                    <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('general.serial_number')}}</th>
                                            <th>{{trans('general.date')}}</th>
                                            <th>{{trans('payment.tax_id')}}</th>
                                            <th>{{trans('payment.payer')}}</th>
                                            <th>{{ trans('payment.pay_item') }}</th>
                                            <th>{{trans('payment.amount')}}</th>
                                            <th>{{trans('payment.reference')}}</th>
                                            <th>{{trans('general.status')}}</th>
                                            <th>Source</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(report, index) in reports.data">
                                            <td v-text="index+1"></td>
                                            <td>{{report.created_at | momentDateTime}}</td>
                                            <td v-text="report.tax_id"></td>
                                            <td v-text="report.tax_payer_name"></td>
                                            <td v-text="report.pay_item_name"></td>
                                            <td>{{ report.amount | formatMoney }}</td>
                                            <td v-text="report.transaction_reference"></td>
                                            <td v-html="getStatus(report)"></td>
                                            <td v-text="report.payment_source"></td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                            </div>
                            <module-info v-if="!reports.total" module="report" title="module_info_title" description="module_info_description" icon="check-circle">
                                <div slot="btn">
                                    <button class="btn btn-primary btn-md" @click="$router.push('/report/create')"><i class="feather icon-plus"></i> {{trans('general.add_new')}}</button>
                                </div>
                            </module-info>
                            <pagination-record :page-length.sync="filterReportForm.page_length" :records="reports" @updateRecords="getReports"></pagination-record>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    import sortBy from '../../components/sort-by'
    import dateRangePicker from '../../components/date-range-picker'

    export default {
        components : { sortBy,dateRangePicker },
        data() {
            return {
                userRole: '',
                reports: {
                    total: 0,
                    data: []
                },
                filterReportForm: {
                    keyword: '',
                    mda_id: '',
                    source: 'all',
                    status: 'all',
                    start_date: moment().startOf('month').format('YYYY-MM-DD'),
                    end_date: moment().endOf('month').format('YYYY-MM-DD'),
                    sort_by : 'created_at',
                    order: 'desc',
                    page_length: 500
                },
                mdas: [],
                gateways: [],
                orderByOptions: [
                    {
                        value: 'tax_id',
                        translation: i18n.payment.tax_id
                    },
                    {
                        value: 'payer',
                        translation: i18n.payment.payer
                    },
                    {
                        value: 'reference',
                        translation: i18n.payment.reference
                    }
                ],
                showFilterPanel: true,
            };
        },
        mounted(){
            if(!helper.hasPermission('list-report')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('report')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getReports();
            this.userRole = this.getRole()[0];
            this.fetchPreRequisites();
        },
        methods: {
            printReport() {
                let url = window.appUrl;
                window.open(url + '?type=mda&mda_id=' + this.filterReportForm.mda_id + '&start_date=' + this.filterReportForm.start_date + '&end_date=' + this.filterReportForm.end_date + '&source=' + this.filterReportForm.source + '&status=' + this.filterReportForm.status, "_blank");
            },
            getGatewayName(report) {
                return report.gateway?.name ?? "--";
            },
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.mdas = response.mdas;
                        this.gateways = response.gateways;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            getRole(role){
              return helper.getRole(role);
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            getReports(page){
                if (typeof page !== 'number') {
                    page = 1;
                }
                let url = helper.getFilterURL(this.filterReportForm);
                axios.get('/api/report/by-mda?page=' + page + url)
                    .then(response => response.data)
                    .then(response => this.reports = response)
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getStatus(payment){
                return payment.remark == 'success' ? ('<span class="badge badge-success">'+i18n.payment.paid+'</span>') : ('<span class="badge badge-danger">'+i18n.payment.not_paid+'</span>') ;
            }
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
        watch: {
            filterReportForm: {
                handler(val){
                    setTimeout(() => {
                        this.getReports();
                    }, 500)
                },
                deep: true
            }
        }
    }
</script>
