<template>
    <section class="row flexbox-container">
  <div class="col-xl-7 col-10 d-flex justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0 w-100">
          <div class="row m-0">
              <div class="col-lg-6 d-lg-block d-none text-center align-self-center p-0">
                  <img src="images/pages/login.png" width="500px" alt="branding logo">
              </div>
              <div class="col-lg-6 col-12 p-0">
                  <div class="card rounded-0 mb-0 px-2">
                      <div class="card-header pb-1">
                          <div class="card-title">
                              <h4 class="mb-0">{{trans('passwords.reset_password')}}</h4>
                          </div>
                      </div>
                      <p class="px-2">Please enter your new password.</p>
                      <div class="card-content">
                          <div class="card-body pt-1">
                            <div v-if="!showMessage">
                            <form id="resetform" @submit.prevent="submit" @keydown="resetForm.errors.clear($event.target.name)">

                                <fieldset class="form-label-group form-group position-relative has-icon-left">
                                    <input type="text" name="email" class="form-control" :class="{ 'is-invalid': resetForm.errors.has('email') }" :placeholder="trans('auth.email')" v-model="resetForm.email">
                            

                                  <div class="form-control-position">
                                      <i class="feather icon-user"></i>
                                  </div>
                                  <label for="email">{{ trans('auth.email') }}</label>
                                    

                                      <show-error :form-name="resetForm" prop-name="email"></show-error>
                                </fieldset>

                                <fieldset class="form-label-group position-relative has-icon-left">

                                  <input type="password" name="password" class="form-control" :class="{ 'is-invalid': resetForm.errors.has('password') }" :placeholder="trans('auth.password')" v-model="resetForm.password">

                                  <div class="form-control-position">
                                      <i class="feather icon-lock"></i>
                                  </div>
                                  <label for="password">{{ trans('auth.password') }}</label>
                                  <show-error :form-name="resetForm" prop-name="password"></show-error>
                                    
                              </fieldset>

                                <fieldset class="form-label-group position-relative has-icon-left">

                                  <input type="password" name="password" class="form-control" :class="{ 'is-invalid': resetForm.errors.has('password_confirmation') }" :placeholder="trans('auth.confirm_password')" v-model="resetForm.password_confirmation">

                                  <div class="form-control-position">
                                      <i class="feather icon-lock"></i>
                                  </div>
                                  <label for="password">{{ trans('auth.confirm_password') }}</label>
                                  <show-error :form-name="resetForm" prop-name="password_confirmation"></show-error>
                                    
                              </fieldset>
                                <div class="row pt-2">
                                    <div class="col-12 col-md-6 mb-1">
                                        <router-link to="/login" class="btn btn-outline-primary btn-block px-0">{{trans('auth.back_to_login?')}}</router-link>
                                    </div>
                                    <div class="col-12 col-md-6 mb-1">
                                        <button type="submit" class="btn btn-primary btn-block px-0">{{trans('passwords.reset_password')}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div v-else class="text-center">
                            <h5 v-text="message" class="alert alert-success" v-if="status"></h5>
                            <h5 v-text="message" class="alert alert-danger" v-else></h5>

                            <div class="row pt-2">
                                    <div class="col-12">
                                        <router-link to="/login" class="btn btn-outline-primary btn-block px-0">{{trans('auth.back_to_login?')}}</router-link>
                                    </div>
                                </div>
                        </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <guest-footer></guest-footer>
      </div>
  </div>
</section>
    
</template>

<script>
    import guestFooter from '../../layouts/guest-footer.vue'

    export default {
        data() {
            return {
                resetForm: new Form ({
                    email: '',
                    password: '',
                    password_confirmation: '',
                    token:this.$route.params.token,
                }),
                message: '',
                status: true,
                showMessage: false
            }
        },
        components: {
            guestFooter
        },
        mounted(){
            if(!helper.featureAvailable('reset_password')){
                helper.featureNotAvailableMsg();
                return this.$router.push('/home');
            }

            axios.post('/api/auth/validate-password-reset',{
                token: this.resetForm.token
            }).then(response => {
                this.showMessage = false;
            }).catch(error => {
                this.message = helper.fetchDataErrorMsg(error);
                this.showMessage = true;
                this.status = false;
            });
        },
        methods: {
            submit(e){
                this.resetForm.post('/api/auth/reset').then(response =>  {
                    this.message = response.message;
                    this.showMessage = true;
                    this.status = true;
                }).catch(error => {
                    helper.showErrorMsg(error);
                });
            }
        }
    }
</script>
