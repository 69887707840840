var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "row flexbox-container" }, [
      _c(
        "div",
        {
          staticClass: "col-xl-7 col-md-8 col-12 d-flex justify-content-center"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100"
            },
            [
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "card-body text-center" }, [
                  _c("img", {
                    staticClass: "img-fluid align-self-center",
                    attrs: {
                      src: "images/pages/not-authorized.png",
                      alt: "branding logo"
                    }
                  }),
                  _vm._v(" "),
                  _c("h1", { staticClass: "font-large-2 my-2" }, [
                    _vm._v("IP Restricted")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "p-2" }, [
                    _vm._v(
                      "\n            Unfortunately, This IP is not allowed to access this page!\n          "
                    )
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }