<template>
    <div>
        <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Home</h2>
                    <div class="breadcrumb-wrapper col-12">
                        
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Dashboard</a>
                                </li>
                                <li class="breadcrumb-item active">
                                    Home ({{ start_date }} ~ {{ end_date }})
                                </li>
                            </ol> 
                    </div>
                </div>
            </div>
        </div>

        <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
            <div class="form-group breadcrum-right d-flex">
                <select class="form-control mr-1" @change="handleSelectItem($event, 'gateway')" style="width: 100%" name="gateway_id" v-model="gateway_id" placeholder="Payment 
                Gateway">
                    <option value="all">All Payment Gateways</option>
                    <option v-for="gateway in gateways" :value="gateway.id">{{gateway.name}}</option>
                </select>
                <div class="dropdown">
                    <button class="btn-icon btn btn-primary btn-round btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="feather icon-filter"></i> Filter</button>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" v-for="item in items" :key="item.id" v-on:click="handleSelectItem(item, 'filter')">
                            {{item.name}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="content-body">
        <section id="dashboard-ecommerce">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-all-tax-payers')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/tax-payer" class="success">
                              <h2 class="text-bold-700 mt-1">{{ users }}</h2>
                              <p class="mb-0">Total Tax Payers</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-individual-tax-payers')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-warning p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-warning font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/tax-payer" class="warning">
                              <h2 class="text-bold-700 mt-1">{{ individual_tax_payers }}</h2>
                              <p class="mb-0">Individual Tax Payers</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-non-individual-tax-payers')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-info p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-info font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/tax-payer" class="info">
                              <h2 class="text-bold-700 mt-1">{{ non_individual_tax_payers }}</h2>
                              <p class="mb-0">Non Individual Tax Payers</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-staff')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-users text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/staff" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ staffs }}</h2>
                              <p class="mb-0">Staffs</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-successful-payment')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="success">
                              <h2 class="text-bold-700 mt-1">{{ successfull_payments | formatMoney }}</h2>
                              <p class="mb-0">Successfull Payments (Online)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-pending-payments')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ pending_payments | formatMoney }}</h2>
                              <p class="mb-0">Pending Payments (Online)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-receipt')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-printer text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/receipt" class="success">
                              <h2 class="text-bold-700 mt-1">{{ successfull_receipt_payments | formatMoney }}</h2>
                              <p class="mb-0">Successfull Payments(Receipt)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-receipt')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-warning p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-printer text-warning font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/receipt" class="warning">
                              <h2 class="text-bold-700 mt-1">{{ pending_receipt_payments | formatMoney }}</h2>
                              <p class="mb-0">Pending Payments(Receipt)</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12" v-if="hasPermission('home-successful-payment')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="success">
                              <h2 class="text-bold-700 mt-1">{{ successfull_payments + successfull_receipt_payments | formatMoney }}</h2>
                              <p class="mb-0">Total Successfull Payments</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12" v-if="hasPermission('home-pending-payments')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-dollar-sign text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/payment" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ pending_payments + pending_receipt_payments | formatMoney }}</h2>
                              <p class="mb-0">Total Pending Payments</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-tcc')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-info p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-printer text-info font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/tcc" class="info">
                              <h2 class="text-bold-700 mt-1">{{ tcc }}</h2>
                              <p class="mb-0">Total TCC</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-efilling')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-primary p-50 m-0">
                                <div class="avatar-content">
                                    <i class="feather icon-file-text text-primary font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/e-filling" class="primary">
                              <h2 class="text-bold-700 mt-1">{{ efillings }}</h2>
                              <p class="mb-0">Total E-Filings Submitted</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-annual-return')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-success p-50 m-0">
                                <div class="avatar-content">
                                    <i class="fa fa-desktop text-success font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/annual-return" class="success">
                              <h2 class="text-bold-700 mt-1">{{ annual_returns }}</h2>
                              <p class="mb-0">Total Annual Return Submitted</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12" v-if="hasPermission('home-mda')">
                    <div class="card">
                        <div class="card-header d-flex flex-column align-items-start pb-0">
                            <div class="avatar bg-rgba-danger p-50 m-0">
                                <div class="avatar-content">
                                    <i class="fa fa-building text-danger font-medium-5"></i>
                                </div>
                            </div>
                            <router-link  to="/setting/mda" class="danger">
                              <h2 class="text-bold-700 mt-1">{{ mdas }}</h2>
                              <p class="mb-0">MDA's</p>
                            </router-link>
                        </div>
                        
                    </div>
                </div>
                
            </div>

            <div class="row" v-if="hasPermission('view-payment-graph')">
                <div class="col-lg-8 col-md-6 col-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between align-items-end">
                            <h4 class="card-title">Payment Summary By MDA's</h4>
                            <p class="font-medium-5 mb-0"><i class="feather icon-settings text-muted cursor-pointer"></i></p>
                        </div>
                        <div class="card-content">
                            <div class="card-body pb-0">
                                <vue-chart type="bar" :data="paymentChart"></vue-chart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12" v-if="hasPermission('view-status-report')">
                    <div class="card">
                      <div class="card-header d-flex justify-content-between pb-0">
                          <h4>Status Report</h4>
                          <div class="dropdown chart-dropdown">
                              <button class="btn btn-sm border-0 dropdown-toggle p-0" type="button" id="dropdownItem2"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Last 7 Days
                              </button>
                              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownItem2">
                                <a class="dropdown-item" href="#">Last 28 Days</a>
                                <a class="dropdown-item" href="#">Last Month</a>
                                <a class="dropdown-item" href="#">Last Year</a>
                              </div>
                          </div>
                      </div>
                      <div class="card-content">
                          <div class="card-body">
                              <div class="mb-3"></div>
                              <div class="chart-info d-flex justify-content-between mb-1">
                                  <div class="series-info d-flex align-items-center">
                                      <i class="fa fa-circle-o text-bold-700 text-success"></i>
                                      <span class="text-bold-600 ml-50">Approved TCC</span>
                                  </div>
                                  <div class="product-result">
                                      <span>{{ approved_tcc }}</span>
                                  </div>
                              </div>
                             
                              <div class="chart-info d-flex justify-content-between mb-75">
                                  <div class="series-info d-flex align-items-center">
                                      <i class="fa fa-circle-o text-bold-700 text-danger"></i>
                                      <span class="text-bold-600 ml-50">Pending TCC</span>
                                  </div>
                                  <div class="product-result">
                                      <span>{{ pending_tcc }}</span>
                                  </div>
                              </div>
                              <div class="chart-info d-flex justify-content-between mb-1">
                                  <div class="series-info d-flex align-items-center">
                                      <i class="fa fa-circle-o text-bold-700 text-success"></i>
                                      <span class="text-bold-600 ml-50">Approved Annual Returns</span>
                                  </div>
                                  <div class="product-result">
                                      <span>{{ approved_annual_reports }}</span>
                                  </div>
                              </div>
                             
                              <div class="chart-info d-flex justify-content-between mb-75">
                                  <div class="series-info d-flex align-items-center">
                                      <i class="fa fa-circle-o text-bold-700 text-danger"></i>
                                      <span class="text-bold-600 ml-50">Pending Annual Returns</span>
                                  </div>
                                  <div class="product-result">
                                      <span>{{ pending_annual_reports }}</span>
                                  </div>
                              </div>
                              <div class="chart-info d-flex justify-content-between mb-1">
                                  <div class="series-info d-flex align-items-center">
                                      <i class="fa fa-circle-o text-bold-700 text-success"></i>
                                      <span class="text-bold-600 ml-50">Approved E-Fillings</span>
                                  </div>
                                  <div class="product-result">
                                      <span>{{ approved_efillings }}</span>
                                  </div>
                              </div>
                             
                              <div class="chart-info d-flex justify-content-between mb-75">
                                  <div class="series-info d-flex align-items-center">
                                      <i class="fa fa-circle-o text-bold-700 text-danger"></i>
                                      <span class="text-bold-600 ml-50">Pending E-Fillings</span>
                                  </div>
                                  <div class="product-result">
                                      <span>{{ pending_efillings }}</span>
                                  </div>
                              </div>
                              
                          </div>
                      </div>
                    </div>
                </div>
            </div>
           
        </section>
   
   </div>
    </div>
</template>

<script>
import VueChart from 'vue-chart-js'
    export default {
        components: { VueChart },
        mounted(){
            if(this.$route.query.reload)
                window.location = window.location.pathname;
            axios.get('/api/dashboard?start_date=' + this.start_date + '&' + 'end_date=' + this.end_date)
                .then(response => response.data)
                .then(response => {
                    this.users = response.users;
                    this.staffs = response.staffs;
                    this.individual_tax_payers = response.individual_tax_payers;
                    this.non_individual_tax_payers = response.non_individual_tax_payers;
                    this.approved_annual_reports = response.approved_annual_reports;
                    this.pending_annual_reports = response.pending_annual_reports;
                    this.approved_efillings = response.approved_efillings;
                    this.pending_efillings = response.pending_efillings;
                    this.approved_tcc = response.approved_tcc;
                    this.pending_tcc = response.pending_tcc;
                    this.todos = response.todos;
                    this.efillings = response.efillings;
                    this.annual_returns = response.annual_returns;
                    this.tcc = response.tcc;
                    this.mdas = response.mdas;
                    this.receipts = response.receipts;
                    this.payments = response.payments;
                    this.successfull_payments = response.successfull_payments;
                    this.pending_payments = response.pending_payments;
                    this.successfull_receipt_payments = response.successfull_receipt_payments;
                    this.pending_receipt_payments = response.pending_receipt_payments;
                    this.paymentChart.labels = response.labels;
                    this.paymentChart.datasets[0].data = response.data; 
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })

            axios.get('/api/user/preference/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.color_themes = response.color_themes;
                    this.directions = response.directions;
                    this.sidebar = response.sidebar;
                    this.locales = response.locales;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
            axios.get('/api/user/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.gateways = response.gateways;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error)
                });
            this.userRole = this.getRole()[0];
        },
        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            getRole(role){
              return helper.getRole(role);
            },
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login')
                })
            },
            updatePreference(){
                this.preferenceForm.post('/api/user/preference')
                    .then(response => {
                        toastr.success(response.message);

                        $('#theme').attr('href','/css/colors/'+this.preferenceForm.color_theme+'.css');

                        if(this.user_preference.direction != this.preferenceForm.direction || this.user_preference.sidebar != this.preferenceForm.sidebar || this.user_preference.locale != this.preferenceForm.locale)
                            location.reload();
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    })
            },
            handleSelectItem(item, type){
                this.selectedItem = item.name;
                var filter = item.id;
                
                switch (filter) {
                    case 1:
                        this.start_date = moment().format('YYYY-MM-DD');
                        this.end_date = moment().format('YYYY-MM-DD');
                        break;
                    case 2:
                        this.start_date = moment().subtract(1,'d').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'d').format('YYYY-MM-DD');
                    break;
                    case 3:
                        this.start_date = moment().subtract(7,'d').format('YYYY-MM-DD');
                        this.end_date = moment().format('YYYY-MM-DD');
                        break;
                    case 4:
                        this.start_date = moment().subtract(30,'d').format('YYYY-MM-DD');
                        this.end_date = moment().format('YYYY-MM-DD');
                    break;
                    case 5:
                        this.start_date = moment().startOf('month').format('YYYY-MM-DD');
                        this.end_date = moment().endOf('month').format('YYYY-MM-DD');
                    break;
                    case 6:
                        this.start_date = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
                    break;
                    case 7:
                        this.start_date = moment().subtract(1,'years').startOf('month').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'years').endOf('month').format('YYYY-MM-DD');
                    break;
                    case 8:
                        this.start_date = moment().startOf('year').format('YYYY-MM-DD');
                        this.end_date = moment().endOf('year').format('YYYY-MM-DD');
                    break;
                    case 9:
                        this.start_date = moment().subtract(1,'years').startOf('year').format('YYYY-MM-DD');
                        this.end_date = moment().subtract(1,'years').endOf('year').format('YYYY-MM-DD');
                    break;
                    default:
                        break;
                }
                if (this.type == 'gateway') {
                    this.gateway_id = item.target.value;
                }
                axios.get('/api/dashboard?start_date=' + this.start_date + '&end_date=' + this.end_date + '&gateway_id=' + this.gateway_id)
                .then(response => response.data)
                .then(response => {
                    this.successfull_payments = response.successfull_payments;
                    this.pending_payments = response.pending_payments;
                    this.successfull_receipt_payments = response.successfull_receipt_payments;
                    this.pending_receipt_payments = response.pending_receipt_payments;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
            }
        },
        data() {
            return {
                gateway_id: 'all',
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').format('YYYY-MM-DD'),
                selectedItem: null,
                items: [
                    {
                        id: 1,
                        name: "Today"
                    },
                    {
                        id: 2,
                        name: "Yesterday"
                    },
                    {
                        id: 3,
                        name: "Last 7 Days"
                    },
                    {
                        id: 4,
                        name: "Last 30 Days"
                    },
                    {
                        id: 5,
                        name: "This Month"
                    },
                    {
                        id: 6,
                        name: "Last Month"
                    },
                    {
                        id: 7,
                        name: "This Month Last Year"
                    },
                    {
                        id: 8,
                        name: "This Year"
                    },
                    {
                        id: 9,
                        name: "Last Year"
                    }
                ],
                paymentChart: {
                    labels: [],
                    datasets: [
                        {
                            label: 'Payments',
                            backgroundColor: 'rgb(153,145,244)',
                            borderColor: 'rgb(153,145,244)',
                            data: []
                        }
                    ]
                },
                users: 0,
                staffs: 0,
                individual_tax_payers: 0,
                non_individual_tax_payers: 0,
                approved_annual_reports: 0,
                pending_annual_reports: 0,
                efillings: 0,
                annual_returns: 0,
                tcc: 0,
                mdas: 0,
                receipts: 0,
                payments: 0,
                successfull_payments: 0,
                pending_payments: 0,
                successfull_receipt_payments: 0,
                pending_receipt_payments: 0,
                approved_efillings: 0,
                pending_efillings: 0,
                approved_tcc: 0,
                pending_tcc: 0,
                todos: {},
                color_themes: [],
                directions: [],
                sidebar: [],
                locales: [],
                role: [],
                userRole: '',
                gateways: [],
                preferenceForm: new Form({
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                },false),
                user_preference: {
                    color_theme: helper.getConfig('user_color_theme') || helper.getConfig('color_theme'),
                    direction: helper.getConfig('user_direction') || helper.getConfig('direction'),
                    locale: helper.getConfig('user_locale') || helper.getConfig('locale'),
                    sidebar: helper.getConfig('user_sidebar') || helper.getConfig('sidebar')
                }
            }
        },
        computed: {
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          moment(date) {
            return helper.formatDate(date);
          },
          formatMoney(value){
            return helper.formatMoney(value);
          }
        },
    }
</script>
<style>
    .shw-rside{
        width: 500px;
    }
</style>