<template>
    <section class="row flexbox-container">
  <div class="col-xl-7 col-md-9 col-10 d-flex justify-content-center px-0">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-6 d-lg-block d-none text-center align-self-center">
                  <img src="images/pages/login.png" width="500px" alt="branding logo">
              </div>
              <div class="col-lg-6 col-12 p-0">
                  <div class="card rounded-0 mb-0 px-2 py-1">
                      <div class="card-header pb-1">
                          <div class="card-title">
                              <h4 class="mb-0">{{trans('passwords.reset_password')}}</h4>
                          </div>
                      </div>
                      <p class="px-2 mb-0">Please enter your email address and we'll send you instructions on how to reset your password.</p>
                      <div class="card-content">
                          <div class="card-body">

                              <form id="passwordform" @submit.prevent="submit" @keydown="passwordForm.errors.clear($event.target.name)">
                                  <div class="form-label-group">
                                      <input type="text" name="email" class="form-control" :class="{ 'is-invalid': passwordForm.errors.has('email') }" :placeholder="trans('auth.email')" v-model="passwordForm.email" autocomplete="email" autofocus>
                        

                                      <label for="email">{{ trans('auth.email') }}</label>

                                      <show-error :form-name="passwordForm" prop-name="email"></show-error>
                                  </div>

                                  <div class="form-group g-recaptcha" v-if="getConfig('recaptcha') && getConfig('reset_password_recaptcha')" :data-sitekey="getConfig('recaptcha_key')"></div>

                                  <div class="float-md-left d-block mb-1">
                                    <router-link to="/login" class="btn btn-outline-primary btn-block px-75">{{trans('auth.back_to_login?')}}</router-link>
                                  </div>
                                  <div class="float-md-right d-block mb-1">
                                    <button type="submit" class="btn btn-primary btn-block px-75">{{trans('passwords.reset_password')}}</button>
                                  </div>
                              </form>

                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
   
</template>

<script>
    import guestFooter from '../../layouts/guest-footer.vue'

    export default {
        data() {
            return {
                passwordForm: new Form({
                    email: ''
                })
            }
        },
        components: {
            guestFooter
        },
        mounted(){
            if(!helper.featureAvailable('reset_password')){
                helper.featureNotAvailableMsg();
                return this.$router.push('/home');
            }
        },
        methods: {
            submit(e){
                this.passwordForm.post('/api/auth/password')
                    .then(response =>  {
                        toastr.success(response.message);
                        this.$router.push('/login');
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        }
    }
</script>
