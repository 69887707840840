<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-8 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('demand_notice.demand_notice')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/demand-notice">
                                        {{ trans('demand_notice.demand_notice') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('demand_notice.create_demand_notice') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-4 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/demand-notice')"><i class="feather icon-list"></i> {{trans('demand_notice.demand_notice_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{trans('demand_notice.create_demand_notice')}}</h4>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <form @submit.prevent="proceed" @keydown="demandNoticeForm.errors.clear($event.target.name)">
                            <div class="row">
                                <div class="col-xl-7 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">Tax Payer</label>
                                        <v-select @input="populateTaxPayerInputs" :options="taxPayers" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': demandNoticeForm.errors.has('user_id') }" name="user_id" v-model="demandNoticeForm.user_id"></v-select>
                                        <show-error :form-name="demandNoticeForm" prop-name="user_id"></show-error>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">Sector</label>
                                        <v-select :options="sectors" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': demandNoticeForm.errors.has('sector_id') }" v-model="demandNoticeForm.sector_id"></v-select>
                                        <show-error :form-name="demandNoticeForm" :prop-name="'sector_id'"></show-error>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-md-12 col-12">
                                    <div class="form-group">
                                        <label for="">{{trans('payment.year')}}</label>
                                        <select class="form-control" :class="{ 'is-invalid': demandNoticeForm.errors.has('payment_year') }" name="payment_year" v-model="demandNoticeForm.payment_year">
                                            <option value="">Select</option>
                                            <option v-for="year in years()" :value="year">{{year}}</option>
                                        </select>
                                        <show-error :form-name="demandNoticeForm" prop-name="payment_year"></show-error>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-md-12 col-12">
                                    <table class="table table-sm">
                            <thead class="thead-light">
                            <tr>
                                <th width="50%">Pay Item</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pay_item, k) in demandNoticeForm.pay_items" :key="k">
                                <td colspan="5">
                                    <div class="row">
                                        <div class="col-12 mb-2">
                                            <v-select :options="payItems" :reduce="name => name.id" label="name" :class="{ 'is-invalid': demandNoticeForm.errors.has('pay_items.'+[k]+'.pay_item_id') }" v-model="pay_item.pay_item_id"></v-select>
                                            <show-error :form-name="demandNoticeForm" :prop-name="'pay_items.'+[k]+'.pay_item_id'"></show-error>
                                        </div>

                                        <div class="col-xl-3 col-md-12">
                                            <input class="form-control" :class="{ 'is-invalid': demandNoticeForm.errors.has('pay_items.'+[k]+'.amount') }" type="text" value="" v-model="pay_item.amount" :placeholder="'Amount'">
                                            <show-error :form-name="demandNoticeForm" :prop-name="'pay_items.'+[k]+'.amount'"></show-error>
                                        </div>

                                        <div class="col-xl-3 col-sm-12">
                                            <input class="form-control" :class="{ 'is-invalid': demandNoticeForm.errors.has('pay_items.'+[k]+'.description') }" type="text" value="" v-model="pay_item.description" :placeholder="'Description'">
                                            <show-error :form-name="demandNoticeForm" :prop-name="'pay_items.'+[k]+'.description'"></show-error>
                                        </div>

                                        <div class="col-xl-2 col-md-12">
                                            <select class="form-control" :class="{ 'is-invalid': demandNoticeForm.errors.has('pay_items.'+[k]+'.month_from') }"  v-model="pay_item.month_from">
                                                <option value="">Month From</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                            </select>
                                            <show-error :form-name="demandNoticeForm" :prop-name="'pay_items.'+[k]+'.month_from'"></show-error>
                                        </div>
                                        <div class="col-xl-2 col-md-12">
                                            <select class="form-control" :class="{ 'is-invalid': demandNoticeForm.errors.has('pay_items.'+[k]+'.month_to') }" v-model="pay_item.month_to">
                                                <option value="">Month To</option>
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                            </select>
                                            <show-error :form-name="demandNoticeForm" :prop-name="'pay_items.'+[k]+'.month_to'"></show-error>
                                        </div>

                                        <div class="col-xl-2 col-md-12">
                                            <button type="button" class="btn btn-primary mr-1 mb-1 waves-effect waves-light btn-sm pull-right" v-tooltip="trans('general.add')" @click="addNewRow">
                                                <i class="feather icon-plus"></i>
                                            </button>

                                            <button v-if="k > 0" type="button" class="btn btn-danger mr-1 mb-1 waves-effect waves-light btn-sm pull-right"  v-tooltip="trans('general.remove')" @click="deleteRow(k, pay_item)">
                                                <i class="feather icon-trash-2"></i>
                                            </button>
                                        </div>
                                        
                                    </div>
                                    
                                </td>
                            </tr>
                        </tbody>
                        </table>
                                    
                                </div>
                                
                                            
                            </div>
                            <div class="row">
                                <div class="col-md-8 offset-md-4">
                                    <button-spinner
                                        :is-loading="demandNoticeForm.isLoading" 
                                        :disabled="demandNoticeForm.isLoading"
                                        
                                        class="btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right"
                                        style="height: 39px; color: #fff;"
                                        >
                                        <span v-if="id">{{trans('general.update')}}</span>
                                        <span v-else>{{trans('general.save')}}</span>
                                    </button-spinner>

                                    <router-link to="/payment" class="btn btn-danger waves-effect waves-light pull-right mr-1 mb-1">{{trans('general.cancel')}}
                                    </router-link>

                                </div>
                            </div>
                        </form>

                        <div class="modal fade" id="confirmPayment" tabindex="-1" role="dialog" aria-labelledby="paymentDetail" aria-hidden="true" style="display: none;">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="paymentDetail">Demand Notice Created</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                    </div>
                                    <div class="modal-body" v-if="showConfirm">
                                        <h4>Print Demand Notice</h4>
                                        <p>Transaction Reference: <strong>{{ reference }}</strong></p>
                                        <p>TAX ID: <strong>{{ tax_id }}</strong></p>
                                        <p>Phone NO: <strong>{{ demandNoticeForm.phone }}</strong></p>
                                        <p>Email: <strong>{{ demandNoticeForm.email }}</strong></p>
                                        <p>Year: <strong>{{ demandNoticeForm.payment_year }}</strong></p>
                                        <table class="table">
                                            <tr>
                                                <th>Item</th>
                                                <th>Amount</th>
                                                <th>Description</th>
                                                <th>Month From</th>
                                                <th>Month To</th>
                                            </tr>
                                            <tr v-for="item in payment_items">
                                                <td>{{ item.pay_item.name }}</td>
                                                <td>{{ item.amount }}</td>
                                                <td>{{ item.description }}</td>
                                                <td>{{ item.month_from }}</td>
                                                <td>{{ item.month_to }}</td>
                                            </tr>
                                        </table>
                                        <p>Total Amount: <strong>{{ demandNoticeForm.amount }}</strong></p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-danger waves-effect text-left" @click="closeModal">{{trans('general.close')}}</button>
                                        <button type="button" class="btn btn-primary waves-effect" @click="printDemandNotice">Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                demandNoticeForm: new Form({
                    isLoading: false,
                    id: '',
                    user_id: '',
                    name: '',
                    phone: '',
                    email: '',
                    amount: '',
                    payment_year: new Date().getFullYear(),
                    sector_id: '',
                    pay_items: [{
                        pay_item_id: '',
                        amount: '',
                        description: '',
                        month_from: '',
                        month_to: ''
                    }]
                }),
                showConfirm: false,
                payment_items: '',
                reference: '',
                tax_id: '',
                payItems: [],
                rrr: '',
                taxPayers: [],
                sectors: [],
            };
        },
        props: ['id'],
        mounted() {
            if(this.id)
                this.getPayment();
            this.fetchPreRequisites();
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/user/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.taxPayers = response.taxPayers;
                        this.payItems = response.payItems;
                        this.sectors = response.sectors;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            proceed(){
                if(this.id)
                    this.updatePayment();
                else
                    this.storePayment();
            },
            storePayment(){
                this.demandNoticeForm.isLoading = true;
                this.demandNoticeForm.post('/api/demand-notice/generate')
                    .then(response => {
                        this.reference = response.reference;
                        this.tax_id = response.user.tax_id;
                        this.demandNoticeForm.phone = response.user.phone;
                        this.demandNoticeForm.email = response.user.email;
                        this.demandNoticeForm.pay_items = response.payment_items;
                        this.payment_items = response.payment_items;
                        this.demandNoticeForm.amount = response.amount;
                        this.demandNoticeForm.payment_year = response.payment_year;
                        this.rrr = response.rrr;
                        this.demandNoticeForm.isLoading = false;
                        this.showConfirm = true;
                        $('#confirmPayment').modal('show');
                        
                    })
                    .catch(error => {
                        this.demandNoticeForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            printDemandNotice(){
                this.clearFormData();
                $('#confirmPayment').modal('hide');
                window.open('/demand-notice/' + this.reference + '/print');
            },
            closeModal(){
                this.clearFormData();
            },
            clearFormData(){
                this.demandNoticeForm.user_id = '';
                this.demandNoticeForm.name = '';
                this.demandNoticeForm.phone = '';
                this.demandNoticeForm.email = '';
                this.demandNoticeForm.amount = '';
                this.demandNoticeForm.payment_year = new Date().getFullYear(),
                this.demandNoticeForm.sector_id = '';
                this.demandNoticeForm.pay_items = [];
                this.demandNoticeForm.pay_items.push({
                    pay_item_id: '',
                    amount: '',
                    description: '',
                    month_from: '',
                    month_to: ''
                });
            },
            getPayment(){
                axios.get('/api/payment/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.demandNoticeForm.id = response.id;
                        this.demandNoticeForm.user_id = response.user_id;
                        this.demandNoticeForm.phone = response.user.phone;
                        this.demandNoticeForm.email = response.user.email;
                        this.demandNoticeForm.amount = response.amount;
                        this.demandNoticeForm.pay_items = response.payment_items;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/payment');
                    });
            },
            updatePayment(){
                this.demandNoticeForm.isLoading = true;
                this.demandNoticeForm.patch('/api/payment/'+this.id)
                    .then(response => {
                        this.reference = response.reference;
                        this.tax_id = response.user.tax_id;
                        this.demandNoticeForm.phone = response.user.phone;
                        this.demandNoticeForm.email = response.user.email;
                        this.demandNoticeForm.pay_items = response.payment_items;
                        this.payment_items = response.payment_items;
                        this.demandNoticeForm.amount = response.amount;
                        this.rrr = response.rrr;
                        this.demandNoticeForm.isLoading = false;
                        this.showConfirm = true;
                        $('#confirmPayment').modal('show');
                        
                    })
                    .catch(error => {
                        this.demandNoticeForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            populateTaxPayerInputs(event){
                axios.get('/api/guest/user/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.demandNoticeForm.user_id = response.id;
                        this.demandNoticeForm.email = response.email;
                        this.demandNoticeForm.phone = response.phone;
                        this.demandNoticeForm.name = response.name;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            years(){
                let years = [];
                for (let index = 0; index < 5; index++) {
                    years.push(moment().subtract(index, 'year').format('YYYY'));
                    
                }
                return years;
            },
            
            addNewRow(){
                this.demandNoticeForm.pay_items.push({
                    pay_item_id: '',
                    amount: '',
                    description: '',
                    month_from: '',
                    month_to: ''
                })
            },

            deleteRow(index, row){
                var idx = this.demandNoticeForm.pay_items.indexOf(row);
                if(idx > -1){
                    this.demandNoticeForm.pay_items.splice(idx, 1);
                }
            }
        }
    }
</script>
