var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      { staticClass: "col-xl-7 col-md-8 col-12 d-flex justify-content-center" },
      [
        _c(
          "div",
          {
            staticClass:
              "card auth-card bg-transparent shadow-none rounded-0 mb-0 w-100"
          },
          [
            _c("div", { staticClass: "card-content" }, [
              _c(
                "div",
                { staticClass: "card-body text-center" },
                [
                  _c("img", {
                    staticClass: "img-fluid align-self-center",
                    attrs: {
                      src: "images/pages/maintenance-2.png",
                      alt: "branding logo"
                    }
                  }),
                  _vm._v(" "),
                  _c("h1", { staticClass: "font-large-2 my-1" }, [
                    _vm._v("Maintenance Mode")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "px-2" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.getConfig("maintenance_mode_message")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary btn-lg mt-1",
                      attrs: { to: "/" }
                    },
                    [_vm._v(_vm._s(_vm.trans("general.back")))]
                  )
                ],
                1
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }