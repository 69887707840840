var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("demand_notice.demand_notice")) +
                  "\n                    "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/demand-notice" } }, [
                      _vm._v(
                        "\n                                    " +
                          _vm._s(_vm.trans("demand_notice.demand_notice")) +
                          "\n                                "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                " +
                      _vm._s(_vm.trans("demand_notice.create_demand_notice")) +
                      "\n                            "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/demand-notice")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(
                  " " + _vm._s(_vm.trans("demand_notice.demand_notice_list"))
                )
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h4", { staticClass: "card-title" }, [
            _vm._v(_vm._s(_vm.trans("demand_notice.create_demand_notice")))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-content" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.proceed.apply(null, arguments)
                  },
                  keydown: function($event) {
                    return _vm.demandNoticeForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xl-7 col-md-12 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Tax Payer")
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          class: {
                            "form-control is-invalid": _vm.demandNoticeForm.errors.has(
                              "user_id"
                            )
                          },
                          attrs: {
                            options: _vm.taxPayers,
                            reduce: function(name) {
                              return name.id
                            },
                            label: "name",
                            name: "user_id"
                          },
                          on: { input: _vm.populateTaxPayerInputs },
                          model: {
                            value: _vm.demandNoticeForm.user_id,
                            callback: function($$v) {
                              _vm.$set(_vm.demandNoticeForm, "user_id", $$v)
                            },
                            expression: "demandNoticeForm.user_id"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.demandNoticeForm,
                            "prop-name": "user_id"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-3 col-md-12 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Sector")]),
                        _vm._v(" "),
                        _c("v-select", {
                          class: {
                            "form-control is-invalid": _vm.demandNoticeForm.errors.has(
                              "sector_id"
                            )
                          },
                          attrs: {
                            options: _vm.sectors,
                            reduce: function(name) {
                              return name.id
                            },
                            label: "name"
                          },
                          model: {
                            value: _vm.demandNoticeForm.sector_id,
                            callback: function($$v) {
                              _vm.$set(_vm.demandNoticeForm, "sector_id", $$v)
                            },
                            expression: "demandNoticeForm.sector_id"
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.demandNoticeForm,
                            "prop-name": "sector_id"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-2 col-md-12 col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(_vm.trans("payment.year")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.demandNoticeForm.payment_year,
                                expression: "demandNoticeForm.payment_year"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.demandNoticeForm.errors.has(
                                "payment_year"
                              )
                            },
                            attrs: { name: "payment_year" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.demandNoticeForm,
                                  "payment_year",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Select")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.years(), function(year) {
                              return _c(
                                "option",
                                { domProps: { value: year } },
                                [_vm._v(_vm._s(year))]
                              )
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.demandNoticeForm,
                            "prop-name": "payment_year"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-xl-12 col-md-12 col-12" }, [
                    _c("table", { staticClass: "table table-sm" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.demandNoticeForm.pay_items, function(
                          pay_item,
                          k
                        ) {
                          return _c("tr", { key: k }, [
                            _c("td", { attrs: { colspan: "5" } }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 mb-2" },
                                  [
                                    _c("v-select", {
                                      class: {
                                        "is-invalid": _vm.demandNoticeForm.errors.has(
                                          "pay_items." + [k] + ".pay_item_id"
                                        )
                                      },
                                      attrs: {
                                        options: _vm.payItems,
                                        reduce: function(name) {
                                          return name.id
                                        },
                                        label: "name"
                                      },
                                      model: {
                                        value: pay_item.pay_item_id,
                                        callback: function($$v) {
                                          _vm.$set(pay_item, "pay_item_id", $$v)
                                        },
                                        expression: "pay_item.pay_item_id"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.demandNoticeForm,
                                        "prop-name":
                                          "pay_items." + [k] + ".pay_item_id"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-xl-3 col-md-12" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: pay_item.amount,
                                          expression: "pay_item.amount"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.demandNoticeForm.errors.has(
                                          "pay_items." + [k] + ".amount"
                                        )
                                      },
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        placeholder: "Amount"
                                      },
                                      domProps: { value: pay_item.amount },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            pay_item,
                                            "amount",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.demandNoticeForm,
                                        "prop-name":
                                          "pay_items." + [k] + ".amount"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-xl-3 col-sm-12" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: pay_item.description,
                                          expression: "pay_item.description"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.demandNoticeForm.errors.has(
                                          "pay_items." + [k] + ".description"
                                        )
                                      },
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        placeholder: "Description"
                                      },
                                      domProps: { value: pay_item.description },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            pay_item,
                                            "description",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.demandNoticeForm,
                                        "prop-name":
                                          "pay_items." + [k] + ".description"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-xl-2 col-md-12" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: pay_item.month_from,
                                            expression: "pay_item.month_from"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid": _vm.demandNoticeForm.errors.has(
                                            "pay_items." + [k] + ".month_from"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              pay_item,
                                              "month_from",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("Month From")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "January" } },
                                          [_vm._v("January")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "February" } },
                                          [_vm._v("February")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "March" } },
                                          [_vm._v("March")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "April" } },
                                          [_vm._v("April")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "May" } },
                                          [_vm._v("May")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "June" } },
                                          [_vm._v("June")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "July" } },
                                          [_vm._v("July")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "August" } },
                                          [_vm._v("August")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "September" } },
                                          [_vm._v("September")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "October" } },
                                          [_vm._v("October")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "November" } },
                                          [_vm._v("November")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "December" } },
                                          [_vm._v("December")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.demandNoticeForm,
                                        "prop-name":
                                          "pay_items." + [k] + ".month_from"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-xl-2 col-md-12" },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: pay_item.month_to,
                                            expression: "pay_item.month_to"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        class: {
                                          "is-invalid": _vm.demandNoticeForm.errors.has(
                                            "pay_items." + [k] + ".month_to"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              pay_item,
                                              "month_to",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("Month To")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "January" } },
                                          [_vm._v("January")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "February" } },
                                          [_vm._v("February")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "March" } },
                                          [_vm._v("March")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "April" } },
                                          [_vm._v("April")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "May" } },
                                          [_vm._v("May")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "June" } },
                                          [_vm._v("June")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "July" } },
                                          [_vm._v("July")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "August" } },
                                          [_vm._v("August")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "September" } },
                                          [_vm._v("September")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "October" } },
                                          [_vm._v("October")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "November" } },
                                          [_vm._v("November")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "December" } },
                                          [_vm._v("December")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.demandNoticeForm,
                                        "prop-name":
                                          "pay_items." + [k] + ".month_to"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-xl-2 col-md-12" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.trans("general.add"),
                                            expression: "trans('general.add')"
                                          }
                                        ],
                                        staticClass:
                                          "btn btn-primary mr-1 mb-1 waves-effect waves-light btn-sm pull-right",
                                        attrs: { type: "button" },
                                        on: { click: _vm.addNewRow }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "feather icon-plus"
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    k > 0
                                      ? _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "general.remove"
                                                ),
                                                expression:
                                                  "trans('general.remove')"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-danger mr-1 mb-1 waves-effect waves-light btn-sm pull-right",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteRow(
                                                  k,
                                                  pay_item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "feather icon-trash-2"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-8 offset-md-4" },
                    [
                      _c(
                        "button-spinner",
                        {
                          staticClass:
                            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                          staticStyle: { height: "39px", color: "#fff" },
                          attrs: {
                            "is-loading": _vm.demandNoticeForm.isLoading,
                            disabled: _vm.demandNoticeForm.isLoading
                          }
                        },
                        [
                          _vm.id
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.trans("general.update")))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.trans("general.save")))
                              ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                          attrs: { to: "/payment" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.trans("general.cancel")) +
                              "\n                                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal fade",
                staticStyle: { display: "none" },
                attrs: {
                  id: "confirmPayment",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "paymentDetail",
                  "aria-hidden": "true"
                }
              },
              [
                _c("div", { staticClass: "modal-dialog modal-lg" }, [
                  _c("div", { staticClass: "modal-content" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm.showConfirm
                      ? _c("div", { staticClass: "modal-body" }, [
                          _c("h4", [_vm._v("Print Demand Notice")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Transaction Reference: "),
                            _c("strong", [_vm._v(_vm._s(_vm.reference))])
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("TAX ID: "),
                            _c("strong", [_vm._v(_vm._s(_vm.tax_id))])
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Phone NO: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.demandNoticeForm.phone))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Email: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.demandNoticeForm.email))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Year: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.demandNoticeForm.payment_year))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "table",
                            { staticClass: "table" },
                            [
                              _vm._m(2),
                              _vm._v(" "),
                              _vm._l(_vm.payment_items, function(item) {
                                return _c("tr", [
                                  _c("td", [
                                    _vm._v(_vm._s(item.pay_item.name))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.amount))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.description))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.month_from))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(item.month_to))])
                                ])
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Total Amount: "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.demandNoticeForm.amount))
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger waves-effect text-left",
                          attrs: { type: "button" },
                          on: { click: _vm.closeModal }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.close")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary waves-effect",
                          attrs: { type: "button" },
                          on: { click: _vm.printDemandNotice }
                        },
                        [_vm._v("Print")]
                      )
                    ])
                  ])
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { attrs: { width: "50%" } }, [_vm._v("Pay Item")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title", attrs: { id: "paymentDetail" } }, [
        _vm._v("Demand Notice Created")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Item")]),
      _vm._v(" "),
      _c("th", [_vm._v("Amount")]),
      _vm._v(" "),
      _c("th", [_vm._v("Description")]),
      _vm._v(" "),
      _c("th", [_vm._v("Month From")]),
      _vm._v(" "),
      _c("th", [_vm._v("Month To")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }