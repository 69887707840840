<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('annual_return.annual_return')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/annual-return">
                                        {{ trans('annual_return.annual_return') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('annual_return.annual_return_details') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/annual-return/create')"><i class="feather icon-plus"></i> {{trans('annual_return.create_annual_return')}}</button>

                    <button class="btn btn-primary btn-sm pull-right ml-1" @click="$router.push('/annual-return')"><i class="feather icon-list"></i> {{trans('annual_return.annual_return_list')}}</button>
                    
                </div>
            </div>

        </div>

        <div class="content-body">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title float-left mb-0">{{trans('annual_return.annual_return_details')}}</h4>

                    <button v-if="annualReturn.status == 0 && hasPermission('approve-annual-return')" class="btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right" @click="approve"> <i class="feather icon-check"></i> {{trans('annual_return.approve')}}</button>
                </div>

                <div class="card-content">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4 col-12">
                                <div class="row">
                                    
                                    <div class="col-sm-12">
                                        <div class="card" v-if="annualReturn.has_attachment">
                                          <div class="card-header">
                                            <h4>Documents</h4>
                                            <i class="feather icon-more-horizontal cursor-pointer"></i>
                                          </div>
                                          <div class="card-body">
                                            <ul class="list-group list-group-horizontal-sm m-t-20">
                                                <li class="list-group-item" v-for="attachment in attachments">
                                                <a :href="`/annual-return/${annualReturn.id}/documents/${attachment.uuid}/download`"><i class="feather icon-edit-2"></i> {{attachment.user_file_type}} -{{attachment.user_filename}}</a>
                                            </li>
                                            </ul>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div class="col-lg-8 col-12">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="card">
                                          <div class="card-header">
                                            <h4>{{trans('annual_return.annual_return_details')}}</h4>
                                            <i class="feather icon-more-horizontal cursor-pointer"></i>
                                          </div>
                                          <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('annaul_return.tax_id')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="annualReturn.user.tax_id">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('annual_return.name')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="annualReturn.user.name">
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('annual_return.year_of_return')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="annualReturn.year_of_return">
                                                            
                                                        </div>
                                                    </div>
                        
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="">{{trans('annual_return.date')}}</label>
                                                            <input class="form-control" type="text" readonly v-model="annualReturn.date">
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                id:this.$route.params.id,
                annualReturn: '',
                attachments: '',
            }
        },
        mounted(){
            if(!helper.hasPermission('list-annual-return')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('annual_return')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.getAnnualReturn();
        },

        methods: {
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getAnnualReturn(){
                
                axios.get('/api/annual-return/'+this.id)
                    .then(response => response.data)
                    .then(response => {
                        console.log(response);
                        this.annualReturn = response.annualReturn;
                        this.attachments = response.attachments;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                        this.$router.push('/annual-return');
                    });
            },

            approve(){
               axios.post('/api/annual-return/'+this.id+'/status')
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/annual-return');
                    })
                    .catch(error => {
                        helper.showDataErrorMsg();
                    });
            }
        }
    }
</script>
