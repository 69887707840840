var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "row flexbox-container" }, [
    _c(
      "div",
      { staticClass: "col-xl-8 col-11 d-flex justify-content-center" },
      [
        _c("div", { staticClass: "card bg-authentication rounded-0 mb-0" }, [
          _c("div", { staticClass: "row m-0" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6 col-12 p-0" }, [
              _c("div", { staticClass: "card rounded-0 mb-0 px-2" }, [
                _c("div", { staticClass: "card-header pb-1" }, [
                  _c(
                    "div",
                    {
                      staticClass: "card-title",
                      staticStyle: { "margin-top": "60px" }
                    },
                    [
                      _c("h4", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.trans("auth.login")))
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "px-2" }, [
                  _vm._v("Welcome back, please login to your account.")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body pt-1" }, [
                    _c(
                      "form",
                      {
                        attrs: { id: "loginform" },
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          },
                          keydown: function($event) {
                            return _vm.loginForm.errors.clear(
                              $event.target.name
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "form-label-group form-group position-relative has-icon-left"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginForm.username,
                                  expression: "loginForm.username"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.loginForm.errors.has(
                                  "username"
                                )
                              },
                              attrs: {
                                type: "text",
                                name: "username",
                                placeholder: _vm.trans("auth.username")
                              },
                              domProps: { value: _vm.loginForm.username },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.loginForm,
                                    "username",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm._m(1),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "username" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.username")))
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.loginForm,
                                "prop-name": "username"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          {
                            staticClass:
                              "form-label-group position-relative has-icon-left"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.loginForm.password,
                                  expression: "loginForm.password"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.loginForm.errors.has(
                                  "password"
                                )
                              },
                              attrs: {
                                type: "password",
                                name: "password",
                                placeholder: _vm.trans("auth.password")
                              },
                              domProps: { value: _vm.loginForm.password },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.loginForm,
                                    "password",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "password" } }, [
                              _vm._v(_vm._s(_vm.trans("auth.password")))
                            ]),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.loginForm,
                                "prop-name": "password"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group d-flex justify-content-between align-items-center"
                          },
                          [
                            _c("div", { staticClass: "text-left" }, [
                              _vm.getConfig("recaptcha") &&
                              _vm.getConfig("login_recaptcha")
                                ? _c("div", {
                                    staticClass: "g-recaptcha",
                                    attrs: {
                                      "data-sitekey": _vm.getConfig(
                                        "recaptcha_key"
                                      )
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.getConfig("reset_password")
                              ? _c(
                                  "div",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "card-link",
                                        attrs: { to: "/password" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.trans("auth.reset_here!"))
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        _vm._v(" "),
                        _vm.getConfig("registration")
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-outline-primary float-left btn-inline",
                                attrs: { to: "/register" }
                              },
                              [_vm._v(_vm._s(_vm.trans("auth.register")))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button-spinner",
                          {
                            staticClass:
                              "btn btn-primary float-right btn-inline",
                            staticStyle: { height: "39px", color: "#fff" },
                            attrs: {
                              "is-loading": _vm.loginForm.isLoading,
                              disabled: _vm.loginForm.isLoading
                            }
                          },
                          [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.trans("auth.login")) +
                                "\n                                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "login-footer" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "footer-btn d-inline" },
                    [
                      _vm.getConfig("registration")
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn btn-outline-primary btn-inline",
                              attrs: { to: "/pay-tax" }
                            },
                            [_vm._v("Pay Tax")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-outline-primary float-right btn-inline",
                          attrs: { type: "button" }
                        },
                        [_vm._v("Tax Calculator")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modal fade",
            staticStyle: { display: "none" },
            attrs: {
              id: "notification",
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "notificationDetails",
              "aria-hidden": "true"
            }
          },
          [
            _c("div", { staticClass: "modal-dialog modal-lg" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(4),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger waves-effect text-left",
                      attrs: { type: "button", "data-dismiss": "modal" }
                    },
                    [_vm._v(_vm._s(_vm.trans("general.close")))]
                  )
                ])
              ])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0"
      },
      [
        _c("img", {
          attrs: {
            src: "images/pages/login.png",
            width: "500px",
            alt: "branding logo"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-control-position" }, [
      _c("i", { staticClass: "feather icon-lock" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "divider" }, [
      _c("div", { staticClass: "divider-text" }, [_vm._v("OR")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h4",
        { staticClass: "modal-title", attrs: { id: "notificationDetails" } },
        [_vm._v("Notice")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-hidden": "true"
          }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-body" }, [
      _c("p", [_vm._v("Dear Taxpayers,")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          'We wish to notify you of our ETax portal currently being upgraded. You can log in to your existing profile using your State TaxID as default username and a default password as "'
        ),
        _c("strong", [_vm._v("password")]),
        _vm._v('" which we advise you to update accordingly.')
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "You can pay your taxes, levies, fines, and charges online from your comfort zone, as well as file your annual returns and request for Tax Clearance Certificate."
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("For any inquiry or support fell free to contact us via "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("info@irs.sk.gov.ng")]),
        _vm._v(" or call "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("+234 708 534 9849")]),
        _vm._v(".")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n                            Thank you.\n                        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }