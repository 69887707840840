var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-8 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("general.menu")) + "\n                     "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(_vm._s(_vm.trans("general.menu")))
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-4 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/home")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-home" }),
                _vm._v(" " + _vm._s(_vm.trans("general.home")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-content" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("show-tip", {
                      attrs: { module: "configuration", tip: "tip_menu" }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("general.menu")))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-content" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit.apply(null, arguments)
                        },
                        keydown: function($event) {
                          return _vm.configForm.errors.clear($event.target.name)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("user.user")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_user_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_user_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_user_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("todo.todo")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_todo_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_todo_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_todo_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("message.message")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_message_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_message_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_message_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans(
                                        "configuration.configuration"
                                      )
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value:
                                        _vm.configForm.show_configuration_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_configuration_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_configuration_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("backup.backup")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_backup_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_backup_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_backup_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("template.email_template")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value:
                                        _vm.configForm.show_email_template_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_email_template_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_email_template_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("mail.email_log")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_email_log_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_email_log_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_email_log_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("activity.activity_log")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value:
                                        _vm.configForm.show_activity_log_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_activity_log_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_activity_log_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("message.message")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_message_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_message_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_message_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("sms.sms")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_sms_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_sms_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_sms_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("mail.email")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_email_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_email_menu",
                                          $$v
                                        )
                                      },
                                      expression: "configForm.show_email_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [_vm._v("Show Settings Menu")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value: _vm.configForm.show_settings_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_settings_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_settings_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("permission.permission")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value:
                                        _vm.configForm.show_permission_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_permission_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_permission_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trans("configuration.show_menu", {
                                      menu: _vm.trans("general.scheduled_job")
                                    })
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value:
                                        _vm.configForm.show_schedule_job_menu,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.configForm,
                                          "show_schedule_job_menu",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "configForm.show_schedule_job_menu"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary waves-effect waves-light pull-right mr-1 mb-1",
                          attrs: { type: "submit" }
                        },
                        [_vm._v(_vm._s(_vm.trans("general.save")))]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Configurations")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }