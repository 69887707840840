import { render, staticRenderFns } from "./user-sidebar.vue?vue&type=template&id=2b58012f&"
import script from "./user-sidebar.vue?vue&type=script&lang=js&"
export * from "./user-sidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/hamzakambaza/Desktop/Sites/soirs/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b58012f')) {
      api.createRecord('2b58012f', component.options)
    } else {
      api.reload('2b58012f', component.options)
    }
    module.hot.accept("./user-sidebar.vue?vue&type=template&id=2b58012f&", function () {
      api.rerender('2b58012f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/user/user-sidebar.vue"
export default component.exports