<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('general.print')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="/">Print</a>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                
            </div>
        </div>

    <div class="content-body">
       
   
   </div>
    </div>
</template>

<script>
    import datepicker from 'vuejs-datepicker'
    import uploadImage from '../../components/upload-image'

    export default {
        components : { datepicker,uploadImage },
        data() {
            return {
                profileForm: new Form({
                    type: '',
                    first_name : '',
                    last_name : '',
                    date_of_birth : '',
                    date_of_anniversary : '',
                    gender : '',
                    title : '',
                    bvn : '',
                    state_id : '34',	
                    lga_id : '',
                    tax_office_id : '',	
                    address : '',
                    city: '',
                    birth_place : '',	
                    occupation : '',
                    source_of_income_id : '',		
                    nationality : '',
                    state_of_origin_id : '',	
                    tax_authority : 'Sokoto State Inland Revenue',
                    employer_name : '',
                    employer_tin : '',
                    business_name : '',	
                    registered_name : '',
                    organization_id : '',
                    business_type_id : '',
                    rc_no : '',
                    enterprise_registration_no : '',
                    date_of_commencement : '',	
                    sector_id : '',
                    company_tin : '',
                    date_of_incorporation : '',
                    issuance_place : '',
                    updated_by : '',
                    update_time : '',
                    facebook_profile : '',
                    twitter_profile : '',
                    google_plus_profile : '',
                    linkedin_profile : ''
                }, false),
                avatar: {
                    user: ''
                },
                genders: [],
                titles: [],
                states: [],
                lgas: [],
                taxOffices: [],
                categories: [],
                organizations: [],
                sectors: [],
                sectorLines: [],
                businessTypes: [],
                user: {
                    profile: {}
                }
            };
        },
        mounted(){
            axios.get('/api/user/profile/pre-requisite')
                .then(response => response.data)
                .then(response => {
                    this.user = response.user;
                    this.profileForm.type = response.user.type;
                    this.profileForm.first_name = response.user.profile.first_name;
                    this.profileForm.last_name = response.user.profile.last_name;
                    this.profileForm.date_of_birth = response.user.profile.date_of_birth;
                    this.profileForm.date_of_anniversary = response.user.profile.date_of_anniversary;
                    this.profileForm.gender = response.user.profile.gender;
                    this.profileForm.facebook_profile = response.user.profile.facebook_profile;
                    this.profileForm.twitter_profile = response.user.profile.twitter_profile;
                    this.profileForm.google_plus_profile = response.user.profile.google_plus_profile;
                    this.profileForm.linkedin_profile = response.user.profile.linkedin_profile;
                    this.avatar.user = response.user.profile.avatar;
                    this.genders = response.genders;
                    this.titles = response.titles;
                    this.states = response.states;
                    this.lgas = response.lgas;
                    this.taxOffices = response.taxOffices;
                    this.categories = response.categories;
                    this.organizations = response.organizations;
                    this.businessTypes = response.businessTypes;
                    this.sectors = response.sectors;
                    this.profileForm.title  = response.user.profile.title;
                    this.profileForm.bvn  = response.user.profile.bvn;
                    this.profileForm.state_id = 34;
                    this.profileForm.lga_id  = response.user.profile.lga_id;
                    this.profileForm.tax_office_id  = response.user.profile.tax_office_id;	
                    this.profileForm.address  = response.user.profile.address;
                    this.profileForm.city  = response.user.profile.city;
                    this.profileForm.birth_place  = response.user.profile.birth_place;
                    this.profileForm.occupation  = response.user.profile.occupation;
                    this.profileForm.source_of_income_id  = response.user.profile.source_of_income_id;	
                    this.profileForm.nationality  = response.user.profile.nationality;
                    this.profileForm.state_of_origin_id  = response.user.profile.state_of_origin_id;
                    this.profileForm.tax_authority  = 'Sokoto State Internal Revenue Service'; //response.user.profile.tax_authority;
                    this.profileForm.employer_name  = response.user.profile.employer_name;
                    this.profileForm.employer_tin  = response.user.profile.employer_tin;
                    this.profileForm.business_name  = response.user.profile.business_name;
                    this.profileForm.registered_name  = response.user.profile.registered_name;
                    this.profileForm.organization_id  = response.user.profile.organization_id;
                    this.profileForm.rc_no  = response.user.profile.rc_no;
                    this.profileForm.enterprise_registration_no  = response.user.profile.enterprise_registration_no;
                    this.profileForm.date_of_commencement  = response.user.profile.date_of_commencement;
                    this.profileForm.business_type_id  = response.user.profile.business_type_id;
                    this.profileForm.sector_id  = response.user.profile.sector_id;
                    this.profileForm.sector_line_id  = response.user.profile.sector_line_id;
                    this.profileForm.company_tin  = response.user.profile.company_tin;
                    this.profileForm.date_of_incorporation  = response.user.profile.date_of_incorporation;
                    this.profileForm.issuance_place  = response.user.profile.issuance_place;
                    this.profileForm.updated_by  = response.user.profile.updated_by;
                    this.profileForm.update_time  = response.user.profile.update_time;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });
        },
        methods: {
            updateProfile() {
                this.profileForm.date_of_birth = (this.profileForm.date_of_birth) ? moment(this.profileForm.date_of_birth).format('YYYY-MM-DD') : null;
                this.profileForm.date_of_anniversary = (this.profileForm.date_of_anniversary) ? moment(this.profileForm.date_of_anniversary).format('YYYY-MM-DD') : null;
                this.profileForm.date_of_commencement = (this.profileForm.date_of_commencement) ? moment(this.profileForm.date_of_commencement).format('YYYY-MM-DD') : null;
                this.profileForm.date_of_incorporation = (this.profileForm.date_of_incorporation) ? moment(this.profileForm.date_of_incorporation).format('YYYY-MM-DD') : null;
                this.profileForm.post('/api/user/profile/update')
                    .then(response => {
                        toastr.success(response.message);
                        this.$store.dispatch('setAuthUserDetail',{
                            first_name: this.profileForm.first_name,
                            last_name: this.profileForm.last_name
                        });
                        this.$router.push('/print-details');
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            updateAvatar(val){
                this.$store.dispatch('setAuthUserDetail',{
                    avatar: val
                });
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },

            getRole(role){
              return helper.getRole(role);
            },

            title(user){
                if (this.getRole() == 'user') {
                    return '(' + user + ' Tax Payer)';
                } else {
                    return '';
                }
                
            },
            getSectorLines(event){
                axios.get('/api/sector-lines-by-sector-id/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.sectorLines = response.sectorLines;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
        },
        computed: {
            imageTitle(){
                if (this.profileForm.type == 'individual') {
                    return 'Passport';
                } else {
                    return 'Logo';
                }
			}
        }
    }
</script>
