<template>
    <section class="row flexbox-container">
  <div class="col-xl-8 col-10 d-flex justify-content-center">
      <div class="card bg-authentication rounded-0 mb-0">
          <div class="row m-0">
              <div class="col-lg-12 d-lg-block d-none text-center align-self-center" style="padding: 40px; margin-top: 20px;">
                  <img src="images/pages/login.png" width="332px" alt="branding logo">
              </div>
              <div class="col-lg-12 col-12 p-0">
                  <div class="card rounded-0 mb-0 p-2">
                      <div class="card-header pt-50 pb-1">
                          <div class="card-title">
                              <h4 class="mb-0">E-TAX PAYMENT</h4>
                          </div>
                      </div>
                      <p class="px-2">Fill the below form to generate an invoice.</p>
                      <div class="card-content">
                          <div class="card-body pt-0">
                            <form id="registerform" @submit.prevent="storePayment" @keydown="paymentForm.errors.clear($event.target.name)">
                                <div class="row">
                                    <div class="col-xl-4 col-md-12 col-12">
                                        <div class="form-group">
                                            <label class="form-check-label">
                                            <input class="form-check-input radio-inline" type="radio" name="type" id="type1" v-model="paymentForm.type" value="registered" checked>
                                            Registered Tax Payer</label>
                                            <label class="form-check-label" style="margin-left: 20px">
                                            <input class="form-check-input radio-inline" type="radio" name="type" id="type2" v-model="paymentForm.type" value="non_registered" v-on:click="clearData">
                                            Non Registered Tax Payer</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="paymentForm.type == 'registered'" class="col-xl-9 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Tax Payer</label>
                                            <v-select @input="populateTaxPayerInputs" :options="taxPayers" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': paymentForm.errors.has('name') }" name="name" v-model="paymentForm.name"></v-select>
                                            <show-error :form-name="paymentForm" prop-name="name"></show-error>
                                        </div>
                                    </div>
                                    <div v-if="paymentForm.type == 'non_registered'" class="col-xl-3 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">{{trans('user.name')}}</label>
                                            <input class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('name') }" type="text" value="" v-model="paymentForm.name" name="name" :placeholder="trans('user.name')">
                                            <show-error :form-name="paymentForm" prop-name="name"></show-error>
                                        </div>
                                    </div>
                                    
                                    <div v-if="paymentForm.type == 'non_registered'" class="col-xl-3 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">{{trans('user.phone')}}</label>
                                            <input class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('phone') }" type="text" value="" v-model="paymentForm.phone" name="phone" :placeholder="trans('user.phone')">
                                            <show-error :form-name="paymentForm" prop-name="phone"></show-error>
                                        </div>
                                    </div>
                                    <div v-if="paymentForm.type == 'non_registered'" class="col-xl-3 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">{{trans('user.email')}}</label>
                                            <input class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('email') }" type="text" value="" v-model="paymentForm.email" name="email" :placeholder="trans('user.email')">
                                            <show-error :form-name="paymentForm" prop-name="email"></show-error>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">{{trans('payment.year')}}</label>
                                            <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('payment_year') }" name="payment_year" v-model="paymentForm.payment_year">
                                                <option value="">Select</option>
                                                <option v-for="year in years()" :value="year">{{year}}</option>
                                            </select>
                                            <show-error :form-name="paymentForm" prop-name="payment_year"></show-error>
                                        </div>
                                    </div>
                                    
                                    <div class="col-xl-12 col-md-12 col-12">
                                        <table class="table table-sm">
                                                <thead class="thead-light">
                                                <tr>
                                                    <th width="50%">Pay Item</th>
                                                    <th>&nbsp;</th>
                                                    <th>&nbsp;</th>
                                                    <th>&nbsp;</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td colspan="5">
                                                        <div class="row">
                                                            <div class="col-12 mb-2">
                                                                <v-select :options="payItems" :reduce="name => name.id" label="name" :class="{ 'form-control is-invalid': paymentForm.errors.has('pay_item_id') }" v-model="paymentForm.pay_item_id"></v-select>
                                                                <show-error :form-name="paymentForm" :prop-name="'pay_item_id'"></show-error>
                                                            </div>

                                                            <div class="col-xl-3 col-md-12">
                                                                <input class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('amount') }" type="text" value="" v-model="paymentForm.amount" :placeholder="'Amount'">
                                                                <show-error :form-name="paymentForm" :prop-name="'amount'"></show-error>
                                                            </div>

                                                            <div class="col-xl-3 col-sm-12">
                                                                <input class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('description') }" type="text" value="" v-model="paymentForm.description" :placeholder="'Description'">
                                                                <show-error :form-name="paymentForm" :prop-name="'description'"></show-error>
                                                            </div>

                                                            <div class="col-xl-3 col-md-12">
                                                                <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('month_from') }" name="month_from" v-model="paymentForm.month_from">
                                                                    <option value="">Month From</option>
                                                                    <option value="January">January</option>
                                                                    <option value="February">February</option>
                                                                    <option value="March">March</option>
                                                                    <option value="April">April</option>
                                                                    <option value="May">May</option>
                                                                    <option value="June">June</option>
                                                                    <option value="July">July</option>
                                                                    <option value="August">August</option>
                                                                    <option value="September">September</option>
                                                                    <option value="October">October</option>
                                                                    <option value="November">November</option>
                                                                    <option value="December">December</option>
                                                                </select>
                                                                <show-error :form-name="paymentForm" :prop-name="'month_from'"></show-error>
                                                            </div>
                                                            <div class="col-xl-3 col-md-12">
                                                                <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('month_to') }" name="month_to" v-model="paymentForm.month_to">
                                                                    <option value="">Month To</option>
                                                                    <option value="January">January</option>
                                                                    <option value="February">February</option>
                                                                    <option value="March">March</option>
                                                                    <option value="April">April</option>
                                                                    <option value="May">May</option>
                                                                    <option value="June">June</option>
                                                                    <option value="July">July</option>
                                                                    <option value="August">August</option>
                                                                    <option value="September">September</option>
                                                                    <option value="October">October</option>
                                                                    <option value="November">November</option>
                                                                    <option value="December">December</option>
                                                                </select>
                                                                <show-error :form-name="paymentForm" :prop-name="'month_to'"></show-error>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('user.tax_office_id')}}</label>
                                            <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('tax_office_id') }" name="tax_office_id" v-model="paymentForm.tax_office_id">
                                                <option value="">Select Tax Office</option>
                                                <option v-for="taxOffice in taxOffices" :value="taxOffice.id">{{taxOffice.name}}</option>
                                            </select>
                                            <show-error :form-name="paymentForm" prop-name="tax_office_id"></show-error>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-6">
                                        <div class="form-group">
                                            <label for="">{{trans('payment.gateway_id')}}</label>
                                            <select class="form-control" :class="{ 'is-invalid': paymentForm.errors.has('gateway_id') }" name="gateway_id" v-model="paymentForm.gateway_id" placeholder="Select Payment 
                                            Gateway">
                                                <option value="">Select Payment Channel</option>
                                                <option v-for="gateway in gateways" :value="gateway.id">{{gateway.name}}</option>
                                            </select>
                                            <show-error :form-name="paymentForm" prop-name="gateway_id"></show-error>
                                        </div>
                                    </div>
                                </div>
                                  <router-link to="/login" class="btn btn-outline-primary float-left btn-inline mb-50">Back</router-link>
                                  <button-spinner
                                    :is-loading="paymentForm.isLoading" 
                                    :disabled="paymentForm.isLoading"
                                    
                                    class="btn btn-primary float-right btn-inline mb-50"
                                    style="height: 39px; color: #fff;"
                                    >
                                    Confirm
                                </button-spinner>
                                
                              </form>
                          </div>
                      </div>

                      <div class="modal fade" id="confirmPayment" tabindex="-1" role="dialog" aria-labelledby="paymentDetail" aria-hidden="true" style="display: none;">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="paymentDetail">Payment Confirmation</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div class="modal-body" v-if="showConfirm">
                                    <h4>Confirm Payment</h4>
                                    <p>Transaction Reference: <strong>{{ reference }}</strong></p>
                                    <p>TAX ID: <strong>{{ tax_id }}</strong></p>
                                    <p>Phone NO: <strong>{{ paymentForm.phone }}</strong></p>
                                    <p>Email: <strong>{{ paymentForm.email }}</strong></p>
                                    <p>Year: <strong>{{ paymentForm.payment_year }}</strong></p>
                                    <table class="table">
                                        <tr>
                                            <th>Item</th>
                                            <th>Amount</th>
                                            <th>Description</th>
                                            <th>Month From</th>
                                            <th>Month To</th>
                                        </tr>
                                        <tr>
                                            <td>{{ pay_item_name }}</td>
                                            <td>{{ paymentForm.amount }}</td>
                                            <td>{{ paymentForm.description }}</td>
                                            <td>{{ paymentForm.month_from }}</td>
                                            <td>{{ paymentForm.month_to }}</td>
                                        </tr>
                                    </table>
                                    <p>Total Amount: <strong>{{ paymentForm.amount }}</strong></p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">{{trans('general.close')}}</button>
                                    <button v-if="paymentForm.gateway_id == 1" type="button" class="btn btn-primary waves-effect" @click="generateRRR">Generate RRR</button>
                                    <button type="button" class="btn btn-primary waves-effect" @click="confirmPayment">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade" id="notification" tabindex="-1" role="dialog" aria-labelledby="notificationDetails" aria-hidden="true" style="display: none;">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="notificationDetails">Notice</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                </div>
                                <div class="modal-body">
                                    <p>Dear Taxpayers,</p>
                                    <p>We wish to notify you of our ETax portal currently being upgraded. You can log in to your existing profile using your State TaxID as default username and a default password as "<strong>password</strong>" which we advise you to update accordingly.</p>
                                    <p>You can pay your taxes, levies, fines, and charges online from your comfort zone, as well as file your annual returns and request for Tax Clearance Certificate.</p>
                                    <p>For any inquiry or support fell free to contact us via <a href="#">info@irs.sk.gov.ng</a> or call <a href="#">+234 708 534 9849</a>.</p>
                                    <p>
                                        Thank you.
                                    </p>
                                    
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-danger waves-effect text-left" data-dismiss="modal">{{trans('general.close')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
    
</template>

<script>
    export default {
        data() {
            return {
                paymentForm: new Form({
                    isLoading: false,
                    type: 'registered',
                    user_id: '',
                    tax_office_id: 2,
                    name: '',
                    phone: '',
                    email: '',
                    amount: '',
                    gateway_id: '',
                    payment_year: new Date().getFullYear(),
                    pay_item_id: '',
                    description: '',
                    month_from: '',
                    month_to: ''
                }),
                showConfirm: false,
                taxPayers: [],
                payItems: [],
                taxOffices: [],
                gateways: [],
                tax_id: '',
                rrr: '',
                pay_item_name: '',
                reference: ''
            }
        },
        mounted(){
            this.fetchPreRequisites();
            $('#notification').modal('show');
        },
        methods: {
            fetchPreRequisites(){
                axios.get('/api/guest/pre-requisite')
                    .then(response => response.data)
                    .then(response => {
                        this.taxPayers = response.taxPayers;
                        this.payItems = response.payItems;
                        this.taxOffices = response.taxOffices;
                        this.gateways = response.gateways;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            storePayment(){
                this.paymentForm.isLoading = true;
                this.paymentForm.post('/api/payment')
                    .then(response => {
                        this.reference = response.reference;
                        this.tax_id = response.user.tax_id;
                        this.paymentForm.phone = response.user.phone;
                        this.paymentForm.email = response.user.email;
                        this.paymentForm.amount = response.amount;
                        this.paymentForm.pay_item_id = response.pay_item_id;
                        this.paymentForm.description = response.description;
                        this.paymentForm.month_from = response.month_from;
                        this.paymentForm.month_to = response.month_to;
                        this.paymentForm.payment_year = response.payment_year;
                        this.paymentForm.gateway_id = response.gateway_id;
                        this.rrr = response.rrr;
                        this.pay_item_name = response.pay_item.name;
                        this.paymentForm.isLoading = false;
                        this.showConfirm = true;
                        $('#confirmPayment').modal('show');
                        
                    })
                    .catch(error => {
                        this.paymentForm.isLoading = false;
                        toastr.error(error.message);
                        helper.showErrorMsg(error);
                    });
            },
            generateRRR(){
                $('#confirmPayment').modal('hide');
                window.open('https://login.remita.net/remita/exapp/api/v1/send/api/print/billsvc/biller/' + this.rrr + '/printinvoiceRequest.pdf');
            },
            confirmPayment(){
                $('#confirmPayment').modal('hide');
                if (this.paymentForm.gateway_id == 1) {
                    this.makeRemitaPayment(this.rrr);
                } else if(this.paymentForm.gateway_id == 2) {
                    window.location.href = '/payment/' + this.reference + '/print';
                }
            },
            getConfig(config){
                return helper.getConfig(config);
            },
            populateTaxPayerInputs(event){
                axios.get('/api/guest/user/' + event)
                    .then(response => response.data)
                    .then(response => {
                        this.paymentForm.user_id = response.id;
                        this.paymentForm.email = response.email;
                        this.paymentForm.phone = response.phone;
                        if (response.type == 'individual') {
                            this.paymentForm.name = response.profile.first_name + ' ' + this.response.profile.last_name;
                        } else {
                            this.paymentForm.name = response.profile.business_name;
                        }
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error)
                    });
            },
            years(){
                let years = [];
                for (let index = 0; index < 5; index++) {
                    years.push(moment().subtract(index, 'year').format('YYYY'));
                    
                }
                return years;
            },
            clearData(){
                this.paymentForm.name = '';
                this.paymentForm.email = '';
                this.paymentForm.phone = '';
            },
            makeRemitaPayment(rrr) {
                var randomnumber = Math.floor(Math.random());
                var paymentEngine = RmPaymentEngine.init({
                    key: "QzAwMDAyNzEyNTl8MTEwNjE4NjF8OWZjOWYwNmMyZDk3MDRhYWM3YThiOThlNTNjZTE3ZjYxOTY5NDdmZWE1YzU3NDc0ZjE2ZDZjNTg1YWYxNWY3NWM4ZjMzNzZhNjNhZWZlOWQwNmJhNTFkMjIxYTRiMjYzZDkzNGQ3NTUxNDIxYWNlOGY4ZWEyODY3ZjlhNGUwYTY",
                    merchantName: "Sokoto Internal Revenue Services",
                    processRrr: true,
                    transactionId: randomnumber,
                    channel: "CARD,USSD,TRANSFER,BANK,BRANCH,IBANK",
                    extendedData: { 
                        customFields: [ 
                            { 
                                name: "rrr", 
                                value: rrr
                            } 
                        ]
                    },
                    
                    onSuccess: function (response) {
                        axios.get('/api/payment/verify/?rrr=' + rrr)
                            .then(response => response.data)
                            .then(response => {
                                if (response.data.message == "Successful") {
                                    toastr.success('Payment successful.');
                                    //this.$router.push('/payment/' + response.data.orderId);
                                    window.location.href = '/payment/' + response.data.orderId + '/print';
                                } else {
                                    toastr.error('There is an error.');
                                }
                            })
                            .catch(error => {
                                helper.showDataErrorMsg(error)
                            });
                    },
                    onError: function (response) {
                        toastr.error(response);
                        console.log('callback Error Response', response);
                    },
                    onClose: function () {
                        console.log("closed");
                    }
                });
                    paymentEngine.showPaymentWidget();
            }
        }
    }
</script>
