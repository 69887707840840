var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "profile-header mb-2" }, [
        _c("div", { staticClass: "relative" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "profile-img-container d-flex align-items-center justify-content-between"
            },
            [
              _c("img", {
                staticClass: "rounded-circle img-border box-shadow-1",
                attrs: { src: _vm.getAvatar(_vm.user), alt: "Card image" }
              }),
              _vm._v(" "),
              _vm._m(1)
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-end align-items-center profile-header-nav"
          },
          [
            _c("nav", { staticClass: "navbar navbar-expand-sm w-100 pr-0" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse navbar-collapse",
                  attrs: { id: "navbarSupportedContent" }
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "navbar-nav justify-content-around w-75 ml-sm-auto"
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "nav-item px-sm-0" },
                        [
                          _c(
                            "router-link",
                            {
                              class: [
                                _vm.menu === "basic" ? "active" : "",
                                "nav-link font-small-3"
                              ],
                              attrs: { to: "/tax-payer/" + _vm.id + "/basic" }
                            },
                            [
                              _c("i", { staticClass: "fas fa-user" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("user.basic")) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item px-sm-0" },
                        [
                          _c(
                            "router-link",
                            {
                              class: [
                                _vm.menu === "contact" ? "active" : "",
                                "nav-link font-small-3"
                              ],
                              attrs: { to: "/tax-payer/" + _vm.id + "/contact" }
                            },
                            [
                              _c("i", { staticClass: "fas fa-phone" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("user.contact")) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item px-sm-0" },
                        [
                          _c(
                            "router-link",
                            {
                              class: [
                                _vm.menu === "avatar" ? "active" : "",
                                "nav-link font-small-3"
                              ],
                              attrs: { to: "/tax-payer/" + _vm.id + "/avatar" }
                            },
                            [
                              _c("i", { staticClass: "far fa-address-book" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("user.avatar")) +
                                  "\n                          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item px-sm-0" },
                        [
                          _c(
                            "router-link",
                            {
                              class: [
                                _vm.menu === "social" ? "active" : "",
                                "nav-link font-small-3"
                              ],
                              attrs: { to: "/tax-payer/" + _vm.id + "/social" }
                            },
                            [
                              _c("i", { staticClass: "fas fa-share-alt" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.trans("user.social")) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item px-sm-0" },
                        [
                          _vm.id != _vm.getAuthUser("id")
                            ? _c(
                                "router-link",
                                {
                                  class: [
                                    _vm.menu === "password" ? "active" : "",
                                    "nav-link font-small-3"
                                  ],
                                  attrs: {
                                    to: "/tax-payer/" + _vm.id + "/password"
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-key" }),
                                  _vm._v(
                                    "\n                              " +
                                      _vm._s(_vm.trans("user.reset_password")) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item px-sm-0" },
                        [
                          _vm.getConfig("email_template")
                            ? _c(
                                "router-link",
                                {
                                  class: [
                                    _vm.menu === "email" ? "active" : "",
                                    "nav-link font-small-3"
                                  ],
                                  attrs: {
                                    to: "/tax-payer/" + _vm.id + "/email"
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-envelope" }),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.trans("user.email")) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cover-container" }, [
      _c("img", {
        staticClass: "img-fluid bg-cover rounded-0 w-100",
        attrs: {
          src: "/images/profile/user-uploads/cover.png",
          height: "150",
          alt: "User Profile Image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "float-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-icon btn-icon rounded-circle btn-primary mr-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "feather icon-edit-2" })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-icon btn-icon rounded-circle btn-primary",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "feather icon-settings" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler pr-0",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [
        _c("span", { staticClass: "navbar-toggler-icon" }, [
          _c("i", { staticClass: "feather icon-align-justify" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }