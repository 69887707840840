var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-7 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("efilling.efilling")) +
                  "\n                     "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/home" } }, [
                      _vm._v(
                        "\n                                     " +
                          _vm._s(_vm.trans("general.home")) +
                          "\n                                 "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 " +
                      _vm._s(_vm.trans("efilling.efilling")) +
                      "\n                             "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-5 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right ml-1",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/e-filling/create")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-plus" }),
                _vm._v(" " + _vm._s(_vm.trans("efilling.create_efilling")))
              ]
            ),
            _vm._v(" "),
            !_vm.showFilterPanel
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-sm pull-right",
                    on: {
                      click: function($event) {
                        _vm.showFilterPanel = !_vm.showFilterPanel
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "feather icon-filter" }),
                    _vm._v(" " + _vm._s(_vm.trans("general.filter")))
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "basic-datatable" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showFilterPanel
                  ? _c("div", { staticClass: "card border-bottom" }, [
                      _c(
                        "div",
                        { staticClass: "card-header" },
                        [
                          _c(
                            "h4",
                            { staticClass: "card-title float-left mb-0" },
                            [_vm._v(_vm._s(_vm.trans("general.filter")))]
                          ),
                          _vm._v(" "),
                          _c(
                            "export-excel",
                            {
                              staticClass:
                                "btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right",
                              attrs: {
                                data: _vm.efillings.data,
                                fields: _vm.json_fields,
                                worksheet:
                                  "Sokoto State Inland Revenue Service",
                                name: "efilling.xls"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "feather icon-file-text"
                              }),
                              _vm._v(
                                " Export to Excel\n                              \n                             "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("general.keyword")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filterEfillingForm.keyword,
                                      expression: "filterEfillingForm.keyword"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { name: "keyword" },
                                  domProps: {
                                    value: _vm.filterEfillingForm.keyword
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.filterEfillingForm,
                                        "keyword",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("date-range-picker", {
                                    attrs: {
                                      "start-date":
                                        _vm.filterEfillingForm.start_date,
                                      "end-date":
                                        _vm.filterEfillingForm.end_date,
                                      label: _vm.trans("general.date_between")
                                    },
                                    on: {
                                      "update:startDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterEfillingForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:start-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterEfillingForm,
                                          "start_date",
                                          $event
                                        )
                                      },
                                      "update:endDate": function($event) {
                                        return _vm.$set(
                                          _vm.filterEfillingForm,
                                          "end_date",
                                          $event
                                        )
                                      },
                                      "update:end-date": function($event) {
                                        return _vm.$set(
                                          _vm.filterEfillingForm,
                                          "end_date",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                                  on: {
                                    click: function($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm.efillings.total
                    ? _c(
                        "h4",
                        { staticClass: "card-title" },
                        [
                          _c("sort-by", {
                            attrs: {
                              "order-by-options": _vm.orderByOptions,
                              "sort-by": _vm.filterEfillingForm.sort_by,
                              order: _vm.filterEfillingForm.order
                            },
                            on: {
                              updateSortBy: function(value) {
                                _vm.filterEfillingForm.sort_by = value
                              },
                              updateOrder: function(value) {
                                _vm.filterEfillingForm.order = value
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _vm.efillings.total
                        ? _c("div", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("general.serial_number"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("efilling.tax_id")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("efilling.name")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("efilling.total_income"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("efilling.chargable_income")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans("efilling.payable_tax_amount")
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("efilling.status")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm.trans("general.action")))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.efillings.data, function(
                                  efilling,
                                  index
                                ) {
                                  return _c("tr", [
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(index + 1)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(
                                          efilling.user.tax_id
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(efilling.user.name)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatMoney")(
                                            efilling.total_income
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatMoney")(
                                            efilling.chargable_income
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatMoney")(
                                              efilling.payable_tax_amount
                                            )
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getStatus(efilling)
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "table-option" }, [
                                      _c(
                                        "div",
                                        { staticClass: "btn-group" },
                                        [
                                          _vm.hasPermission("approve-e-filling")
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: efilling.status
                                                        ? _vm.trans(
                                                            "efilling.reject"
                                                          )
                                                        : _vm.trans(
                                                            "efilling.approve"
                                                          ),
                                                      expression:
                                                        "efilling.status ? trans('efilling.reject') : trans('efilling.approve')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-primary btn-sm",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.toggleStatus(
                                                        efilling
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    class: [
                                                      "feather",
                                                      efilling.status
                                                        ? "icon-x"
                                                        : "icon-check"
                                                    ]
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip",
                                                  value: _vm.trans(
                                                    "efilling.view_efilling"
                                                  ),
                                                  expression:
                                                    "trans('efilling.view_efilling')"
                                                }
                                              ],
                                              staticClass:
                                                "btn btn-success btn-sm",
                                              attrs: {
                                                to: "/e-filling/" + efilling.id
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "feather icon-eye"
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.hasPermission("edit-e-filling")
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "efilling.edit_efilling"
                                                      ),
                                                      expression:
                                                        "trans('efilling.edit_efilling')"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "btn btn-info btn-sm",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.editEfilling(
                                                        efilling
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "feather icon-edit"
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.hasPermission("delete-e-filling")
                                            ? _c(
                                                "button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "confirm",
                                                      rawName: "v-confirm",
                                                      value: {
                                                        ok: _vm.confirmDelete(
                                                          efilling
                                                        )
                                                      },
                                                      expression:
                                                        "{ok: confirmDelete(efilling)}"
                                                    },
                                                    {
                                                      name: "tooltip",
                                                      rawName: "v-tooltip",
                                                      value: _vm.trans(
                                                        "efilling.delete_efilling"
                                                      ),
                                                      expression:
                                                        "trans('efilling.delete_efilling')"
                                                    }
                                                  ],
                                                  key: efilling.id,
                                                  staticClass:
                                                    "btn btn-danger btn-sm"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "feather icon-trash-2"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.efillings.total
                        ? _c(
                            "module-info",
                            {
                              attrs: {
                                module: "efilling",
                                title: "module_info_title",
                                description: "module_info_description",
                                icon: "check-circle"
                              }
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "btn" }, slot: "btn" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary btn-md",
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push(
                                            "/e-filling/create"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "feather icon-plus"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.trans("general.add_new"))
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterEfillingForm.page_length,
                          records: _vm.efillings
                        },
                        on: {
                          "update:pageLength": function($event) {
                            return _vm.$set(
                              _vm.filterEfillingForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function($event) {
                            return _vm.$set(
                              _vm.filterEfillingForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getEfillings
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }