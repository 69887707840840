var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v("Home")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb-item active" }, [
                  _vm._v(
                    "\n                                 Home (" +
                      _vm._s(_vm.start_date) +
                      " ~ " +
                      _vm._s(_vm.end_date) +
                      ")\n                             "
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right d-flex" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.gateway_id,
                    expression: "gateway_id"
                  }
                ],
                staticClass: "form-control mr-1",
                staticStyle: { width: "100%" },
                attrs: {
                  name: "gateway_id",
                  placeholder: "Payment \n             Gateway"
                },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.gateway_id = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function($event) {
                      return _vm.handleSelectItem($event, "gateway")
                    }
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "all" } }, [
                  _vm._v("All Payment Gateways")
                ]),
                _vm._v(" "),
                _vm._l(_vm.gateways, function(gateway) {
                  return _c("option", { domProps: { value: gateway.id } }, [
                    _vm._v(_vm._s(gateway.name))
                  ])
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown" }, [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown-menu dropdown-menu-right" },
                _vm._l(_vm.items, function(item) {
                  return _c(
                    "a",
                    {
                      key: item.id,
                      staticClass: "dropdown-item",
                      on: {
                        click: function($event) {
                          return _vm.handleSelectItem(item, "filter")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                         " +
                          _vm._s(item.name) +
                          "\n                     "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("section", { attrs: { id: "dashboard-ecommerce" } }, [
        _c("div", { staticClass: "row" }, [
          _vm.hasPermission("home-all-tax-payers")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "success", attrs: { to: "/tax-payer" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.users))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Total Tax Payers")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-individual-tax-payers")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "warning", attrs: { to: "/tax-payer" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.individual_tax_payers))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Individual Tax Payers")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-non-individual-tax-payers")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "info", attrs: { to: "/tax-payer" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.non_individual_tax_payers))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Non Individual Tax Payers")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-staff")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "danger", attrs: { to: "/staff" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.staffs))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("Staffs")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-successful-payment")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "success", attrs: { to: "/payment" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(_vm.successfull_payments)
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Successfull Payments (Online)")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-pending-payments")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "danger", attrs: { to: "/payment" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(_vm.pending_payments)
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Pending Payments (Online)")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-receipt")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "success", attrs: { to: "/receipt" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.successfull_receipt_payments
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Successfull Payments(Receipt)")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-receipt")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(9),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "warning", attrs: { to: "/receipt" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.pending_receipt_payments
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Pending Payments(Receipt)")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-successful-payment")
            ? _c("div", { staticClass: "col-lg-6 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(10),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "success", attrs: { to: "/payment" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.successfull_payments +
                                    _vm.successfull_receipt_payments
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Total Successfull Payments")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-pending-payments")
            ? _c("div", { staticClass: "col-lg-6 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(11),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "danger", attrs: { to: "/payment" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatMoney")(
                                  _vm.pending_payments +
                                    _vm.pending_receipt_payments
                                )
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Total Pending Payments")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-tcc")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(12),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "info", attrs: { to: "/tcc" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.tcc))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Total TCC")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-efilling")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(13),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { staticClass: "primary", attrs: { to: "/e-filling" } },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.efillings))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Total E-Filings Submitted")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-annual-return")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(14),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "success",
                          attrs: { to: "/annual-return" }
                        },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.annual_returns))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v("Total Annual Return Submitted")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("home-mda")
            ? _c("div", { staticClass: "col-lg-3 col-sm-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header d-flex flex-column align-items-start pb-0"
                    },
                    [
                      _vm._m(15),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "danger",
                          attrs: { to: "/setting/mda" }
                        },
                        [
                          _c("h2", { staticClass: "text-bold-700 mt-1" }, [
                            _vm._v(_vm._s(_vm.mdas))
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "mb-0" }, [_vm._v("MDA's")])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.hasPermission("view-payment-graph")
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-8 col-md-6 col-12" }, [
                _c("div", { staticClass: "card" }, [
                  _vm._m(16),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-content" }, [
                    _c(
                      "div",
                      { staticClass: "card-body pb-0" },
                      [
                        _c("vue-chart", {
                          attrs: { type: "bar", data: _vm.paymentChart }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.hasPermission("view-status-report")
                ? _c("div", { staticClass: "col-lg-4 col-md-6 col-12" }, [
                    _c("div", { staticClass: "card" }, [
                      _vm._m(17),
                      _vm._v(" "),
                      _c("div", { staticClass: "card-content" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "mb-3" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-info d-flex justify-content-between mb-1"
                            },
                            [
                              _vm._m(18),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-result" }, [
                                _c("span", [_vm._v(_vm._s(_vm.approved_tcc))])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-info d-flex justify-content-between mb-75"
                            },
                            [
                              _vm._m(19),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-result" }, [
                                _c("span", [_vm._v(_vm._s(_vm.pending_tcc))])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-info d-flex justify-content-between mb-1"
                            },
                            [
                              _vm._m(20),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-result" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.approved_annual_reports))
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-info d-flex justify-content-between mb-75"
                            },
                            [
                              _vm._m(21),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-result" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.pending_annual_reports))
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-info d-flex justify-content-between mb-1"
                            },
                            [
                              _vm._m(22),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-result" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.approved_efillings))
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "chart-info d-flex justify-content-between mb-75"
                            },
                            [
                              _vm._m(23),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-result" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.pending_efillings))
                                ])
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Dashboard")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass:
          "btn-icon btn btn-primary btn-round btn-sm dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "feather icon-filter" }), _vm._v(" Filter")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-users text-success font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-warning p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-users text-warning font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-info p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "feather icon-users text-info font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "feather icon-users text-danger font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-success font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-danger font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-printer text-success font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-warning p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-printer text-warning font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-success font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-dollar-sign text-danger font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-info p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "feather icon-printer text-info font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-primary p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", {
          staticClass: "feather icon-file-text text-primary font-medium-5"
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-success p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-desktop text-success font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatar bg-rgba-danger p-50 m-0" }, [
      _c("div", { staticClass: "avatar-content" }, [
        _c("i", { staticClass: "fa fa-building text-danger font-medium-5" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "card-header d-flex justify-content-between align-items-end"
      },
      [
        _c("h4", { staticClass: "card-title" }, [
          _vm._v("Payment Summary By MDA's")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "font-medium-5 mb-0" }, [
          _c("i", {
            staticClass: "feather icon-settings text-muted cursor-pointer"
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header d-flex justify-content-between pb-0" },
      [
        _c("h4", [_vm._v("Status Report")]),
        _vm._v(" "),
        _c("div", { staticClass: "dropdown chart-dropdown" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm border-0 dropdown-toggle p-0",
              attrs: {
                type: "button",
                id: "dropdownItem2",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [
              _vm._v(
                "\n                             Last 7 Days\n                           "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu dropdown-menu-right",
              attrs: { "aria-labelledby": "dropdownItem2" }
            },
            [
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _vm._v("Last 28 Days")
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _vm._v("Last Month")
              ]),
              _vm._v(" "),
              _c("a", { staticClass: "dropdown-item", attrs: { href: "#" } }, [
                _vm._v("Last Year")
              ])
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-success" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [
        _vm._v("Approved TCC")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-danger" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [
        _vm._v("Pending TCC")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-success" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [
        _vm._v("Approved Annual Returns")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-danger" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [
        _vm._v("Pending Annual Returns")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-success" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [
        _vm._v("Approved E-Fillings")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "series-info d-flex align-items-center" }, [
      _c("i", { staticClass: "fa fa-circle-o text-bold-700 text-danger" }),
      _vm._v(" "),
      _c("span", { staticClass: "text-bold-600 ml-50" }, [
        _vm._v("Pending E-Fillings")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }