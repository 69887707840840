var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.demandNoticeForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _vm.demandNoticeForm.type == "individual"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Tax Payer")]),
                  _vm._v(" "),
                  _c("v-select", {
                    class: {
                      "form-control is-invalid": _vm.demandNoticeForm.errors.has(
                        "user_id"
                      )
                    },
                    attrs: {
                      options: _vm.taxPayers,
                      reduce: function(name) {
                        return name.id
                      },
                      label: "name",
                      name: "user_id"
                    },
                    on: { input: _vm.populateTaxPayerInputs },
                    model: {
                      value: _vm.demandNoticeForm.user_id,
                      callback: function($$v) {
                        _vm.$set(_vm.demandNoticeForm, "user_id", $$v)
                      },
                      expression: "demandNoticeForm.user_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.demandNoticeForm,
                      "prop-name": "user_id"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-6 col-md-6 col-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Year")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.demandNoticeForm.year,
                          expression: "demandNoticeForm.year"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.demandNoticeForm.errors.has("year")
                      },
                      attrs: { name: "year" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.demandNoticeForm,
                            "year",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Select Year")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2024" } }, [
                        _vm._v("2024")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.demandNoticeForm,
                      "prop-name": "year"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.demandNoticeForm.type == "group"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-4 col-md-4 col-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Sector")]),
                  _vm._v(" "),
                  _c("v-select", {
                    class: {
                      "form-control is-invalid": _vm.demandNoticeForm.errors.has(
                        "sector_id"
                      )
                    },
                    attrs: {
                      options: _vm.sectors,
                      reduce: function(name) {
                        return name.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.demandNoticeForm.sector_id,
                      callback: function($$v) {
                        _vm.$set(_vm.demandNoticeForm, "sector_id", $$v)
                      },
                      expression: "demandNoticeForm.sector_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.demandNoticeForm,
                      "prop-name": "sector_id"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-4 col-md-4 col-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Size")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.demandNoticeForm.size,
                          expression: "demandNoticeForm.size"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.demandNoticeForm.errors.has("size")
                      },
                      attrs: { name: "size" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.demandNoticeForm,
                            "size",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Large" } }, [
                        _vm._v("Large")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Medium" } }, [
                        _vm._v("Medium")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Small" } }, [
                        _vm._v("Small")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.demandNoticeForm,
                      "prop-name": "size"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-4 col-md-4 col-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Year")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.demandNoticeForm.year,
                          expression: "demandNoticeForm.year"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.demandNoticeForm.errors.has("year")
                      },
                      attrs: { name: "year" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.demandNoticeForm,
                            "year",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Select Year")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2024" } }, [
                        _vm._v("2024")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.demandNoticeForm,
                      "prop-name": "year"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-8 offset-md-4" },
          [
            _c(
              "button-spinner",
              {
                staticClass: "btn btn-primary float-right btn-inline mb-50",
                staticStyle: { height: "39px", color: "#fff" },
                attrs: {
                  "is-loading": _vm.demandNoticeForm.isLoading,
                  disabled: _vm.demandNoticeForm.isLoading
                }
              },
              [
                _vm.id
                  ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                  : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
                attrs: { to: "/demand-notice" }
              },
              [_vm._v(_vm._s(_vm.trans("general.cancel")) + "\n            ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }