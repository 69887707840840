var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "navigation navigation-main",
      attrs: { id: "main-menu-navigation", "data-menu": "menu-navigation" }
    },
    [
      _c(
        "li",
        {
          class: [
            _vm.currentPage.includes("home") ? _vm.activeClass : "",
            "nav-item"
          ]
        },
        [
          _c("router-link", { attrs: { to: "/home", exact: "" } }, [
            _c("i", { staticClass: "feather icon-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "menu-title" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasPermission("list-tax-payer")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("tax-payer") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/tax-payer", exact: "" } }, [
                _c("i", { staticClass: "feather icon-users" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [_vm._v("Tax Payer")])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-payment")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("list-payment")
                ? _c(
                    "li",
                    {
                      class: [
                        this.$router.history.current.path == "/payment/create"
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/payment/create", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t    \t\t\t\t\tGenerate Bill\n\t    \t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    this.$router.history.current.path == "/payment"
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c("router-link", { attrs: { to: "/payment", exact: "" } }, [
                    _c("i", { staticClass: "feather icon-list" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-title" }, [
                      _vm._v(
                        "\n\t            \t\t\tPay or Confirm Payment\n\t            \t\t"
                      )
                    ])
                  ])
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-receipt") && _vm.getConfig("show_receipt_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("list-receipt")
                ? _c(
                    "li",
                    {
                      class: [
                        this.$router.history.current.path == "/receipt"
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/receipt", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t            \t\t\tView Receipt\n\t            \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("create-receipt")
                ? _c(
                    "li",
                    {
                      class: [
                        this.$router.history.current.path == "/receipt/create"
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/receipt/create", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t    \t\t\t\t\tGenerate Receipt\n\t    \t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("verify-receipt")
                ? _c(
                    "li",
                    {
                      class: [
                        this.$router.history.current.path == "/receipt/verify"
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/receipt/verify", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t            \t\t\tRePrint Receipt\n\t            \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-e-filling") && _vm.getConfig("show_efilling_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("list-e-filling")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("e-filling")
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/e-filling", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v("\n\t    \t\t\t\t\tE-Filing\n\t    \t\t\t\t")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("list-annual-return")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("annual-return")
                          ? _vm.activeClass
                          : ""
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/annual-return", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t            \t\t\tAnnual Report\n\t            \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-tcc") && _vm.getConfig("show_tcc_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("tcc") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/tcc", exact: "" } }, [
                _c("i", { staticClass: "feather icon-check" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t    \t\t\tTCC\n\t    \t\t")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-demand-notice") &&
      _vm.getConfig("show_demand_notice_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("list-demand-notice")
                ? _c(
                    "li",
                    {
                      class: [
                        this.$router.history.current.path == "/demand-notice"
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/demand-notice", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t            \t\t\tView Demand Notice\n\t            \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("create-demand-notice")
                ? _c(
                    "li",
                    {
                      class: [
                        this.$router.history.current.path ==
                        "/demand-notice/generate"
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/demand-notice/generate", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v("\n\t    \t\t\t\t\tGenerate\n\t    \t\t\t\t")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-e-filling") && _vm.getConfig("show_user_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("list-e-filling")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("individual-jtb")
                          ? _vm.activeClass
                          : "",
                        "nav-item"
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/individual-jtb", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t    \t\t\t\t\tIndividual\n\t    \t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("list-annual-return")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("non-individual-jtb")
                          ? _vm.activeClass
                          : ""
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/non-individual-jtb", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-list" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t            \t\t\tNon-Individual\n\t            \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-user") && _vm.getConfig("show_user_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("staff") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/staff", exact: "" } }, [
                _c("i", { staticClass: "feather icon-users" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v("\n\t\t\t\t\tStaff\n\t\t\t\t")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-settings")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(5),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/business-type")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/business-type", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tBusiness Type\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("category") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/category", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tCategory\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/sub-category")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/sub-category", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tSub Category\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/tax-rate")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/tax-rate", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tTax Rate\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("gateway") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/gateway", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tGateway\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("mda") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/mda", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tMDA\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/pay-item")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/pay-item", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tPay Item\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("setting/tax-office")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/tax-office", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\tTax Office\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("organization")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/organization", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tOrganization\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("occupation")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/occupation", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tOccupation\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("sector") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/sector", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tSector\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("sector-line")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/setting/sector-line", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v("\n\t    \t\t\t\t\tSector Line\n\t    \t\t\t\t")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("demand-notice-pay-item")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/setting/demand-notice-pay-item",
                        exact: ""
                      }
                    },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\tDemand Notice Pay Item\n\t    \t\t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-todo") && _vm.getConfig("show_todo_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("todo") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/todo", exact: "" } }, [
                _c("i", { staticClass: "feather icon-check-circle" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("todo.todo")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-message") && _vm.getConfig("show_message_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("message") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/message", exact: "" } }, [
                _c("i", { staticClass: "feather icon-mail" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("message.message")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-sms") && _vm.getConfig("show_sms_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("configuration")
                  ? _vm.activeClass
                  : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/sms", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-message-circle" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("general.sms")) +
                        "\n\t    \t\t"
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_email_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(6),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _vm.hasPermission("access-configuration") &&
              _vm.getConfig("show_email_template_menu")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("email-template")
                          ? _vm.activeClass
                          : ""
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/email-template", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-mail" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t\t\t    \t\t\t" +
                                _vm._s(_vm.trans("template.email_template")) +
                                "\n\t\t\t    \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("access-configuration") &&
              _vm.getConfig("show_email_log_menu")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("email-log")
                          ? _vm.activeClass
                          : ""
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/email-log", exact: "" } },
                        [
                          _c("i", { staticClass: "feather icon-mail" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t\t\t    \t\t\t" +
                                _vm._s(_vm.trans("mail.email_log")) +
                                "\n\t\t\t    \t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_settings_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(7),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("basic") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/basic", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-settings" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(
                              _vm.trans("configuration.basic_configuration")
                            ) +
                            "\n\t        \t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("logo") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/logo", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-image" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t            \t\t\t" +
                            _vm._s(_vm.trans("general.logo")) +
                            "\n\t            \t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("system") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/system", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-settings" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(
                              _vm.trans("configuration.system_configuration")
                            ) +
                            "\n\t    \t\t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("mail") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/mail", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-mail" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("mail.mail_configuration")) +
                            "\n\t    \t\t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.getConfig("multilingual")
                ? _c(
                    "li",
                    {
                      class: [
                        _vm.currentPage.includes("locale")
                          ? _vm.activeClass
                          : ""
                      ]
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/configuration/locale", exact: "" } },
                        [
                          _c("i", { staticClass: "fas fa-globe" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "menu-title" }, [
                            _vm._v(
                              "\n\t    \t\t\t\t\t" +
                                _vm._s(_vm.trans("locale.locale")) +
                                "\n\t    \t\t\t\t"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("menu") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/menu", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-list" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("general.menu")) +
                            "\n\t    \t\t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("authentication")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { to: "/configuration/authentication", exact: "" }
                    },
                    [
                      _c("i", { staticClass: "feather icon-log-in" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(_vm.trans("auth.authentication")) +
                            "\n\t        \t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_permission_menu")
        ? _c("li", { staticClass: "nav-item has-sub" }, [
            _vm._m(8),
            _vm._v(" "),
            _c("ul", { staticClass: "menu-content" }, [
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("role") ? _vm.activeClass : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/role", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-log-in" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t        \t\t\t\t" +
                            _vm._s(_vm.trans("role.role")) +
                            "\n\t        \t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: [
                    _vm.currentPage.includes("permission")
                      ? _vm.activeClass
                      : ""
                  ]
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/configuration/permission", exact: "" } },
                    [
                      _c("i", { staticClass: "feather icon-lock" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-title" }, [
                        _vm._v(
                          "\n\t    \t\t\t\t\t" +
                            _vm._s(_vm.trans("permission.permission")) +
                            "\n\t    \t\t\t\t"
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_activity_log_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("activity-log") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/activity-log", exact: "" } }, [
                _c("i", { staticClass: "feather icon-activity" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("activity.activity_log")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") && _vm.getConfig("ip_filter")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("ip-filter") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/ip-filter", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-filter" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("ip_filter.ip_filter")) +
                        "\n\t    \t\t"
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_schedule_job_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("scheduled-job")
                  ? _vm.activeClass
                  : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/configuration/scheduled-job", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-clock" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v(
                      "\n\t    \t\t\t" +
                        _vm._s(_vm.trans("general.scheduled_job")) +
                        "\n\t    \t\t"
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("access-configuration") &&
      _vm.getConfig("show_backup_menu")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("backup") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c("router-link", { attrs: { to: "/backup", exact: "" } }, [
                _c("i", { staticClass: "feather icon-box" }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-title" }, [
                  _vm._v(
                    "\n\t    \t\t\t" +
                      _vm._s(_vm.trans("backup.backup")) +
                      "\n\t    \t\t"
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasPermission("list-report")
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("report") ? _vm.activeClass : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/report/by-mda", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-navigation" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v("\n\t    \t\t\tCollection Report\n\t    \t\t")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.userRole == "admin"
        ? _c(
            "li",
            {
              class: [
                _vm.currentPage.includes("virtual-account")
                  ? _vm.activeClass
                  : "",
                "nav-item"
              ]
            },
            [
              _c(
                "router-link",
                { attrs: { to: "/virtual-account", exact: "" } },
                [
                  _c("i", { staticClass: "feather icon-server" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "menu-title" }, [
                    _vm._v("\n\t    \t\t\tVirtual Account\n\t    \t\t")
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-dollar-sign" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Bills & Payment")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-printer" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Tax Receipt")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-file-text" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Returns Filling")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-bell" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Demand Notice")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-navigation" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Joint Tax Board")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-settings" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Settings")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-inbox" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("\n\t   \t\t\t\tEmail\n   \t\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-settings" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("System Settings")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "" } }, [
      _c("i", { staticClass: "feather icon-lock" }),
      _vm._v(" "),
      _c("span", { staticClass: "menu-title", attrs: { "data-i18n": "" } }, [
        _vm._v("Permissions")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }