<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-7 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('receipt.verify_receipt')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/home">
                                        {{ trans('general.home') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/receipt">
                                        {{ trans('receipt.receipt') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">
                                    {{ trans('receipt.verify_receipt') }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-5 col-12 d-md-block d-none">
        
            </div>

        </div>

    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">

                    <transition name="fade">
                        <div class="card border-bottom">
                            <div class="card-header">
                                <h4 class="card-title float-left mb-0">{{trans('general.filter')}}</h4>
                                <export-excel
                                    class   = "btn btn-success btn-sm mr-1 mb-1 waves-effect waves-light pull-right"
                                    :data   = "receipts.data"
                                    :fields = "json_fields"
                                    worksheet = "Sokoto State Inland Revenue Service"
                                    name    = "receipt.xls">
                                 
                                    <i class="feather icon-file-text"></i> Export to Excel
                                 
                                </export-excel>
                            </div>
                            <div class="card-content">
                                <div class="card-body">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label for="">{{trans('receipt.transaction_reference')}}</label>
                                            <input class="form-control" name="transaction_reference" placeholder="Enter Payment Reference" v-model="searchReceiptForm.paymentId">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <br>
                                        <button class="btn btn-primary waves-effect waves-light pull-left" @click="search">{{trans('receipt.find')}}</button>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    </transition>
                    
                    <div class="card" v-if="showSearchPanel">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="table-responsive" v-if="receipts.total">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{trans('receipt.date')}}</th>
                                            <th>{{trans('receipt.tax_id')}}</th>
                                            <th>{{trans('receipt.payer')}}</th>
                                            <th>{{trans('receipt.phone')}}</th>
                                            <th>{{trans('receipt.amount_paid')}}</th>
                                            <th>{{trans('receipt.rev_code')}}</th>
                                            <th>{{trans('receipt.transaction_reference')}}</th>
                                            <th>{{trans('receipt.status')}}</th>
                                            <th class="table-option">{{trans('general.action')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(receipt, index) in receipts.data">
                                            <td>{{receipt.created_at | momentDateTime}}</td>
                                            <td v-text="receipt.user.tax_id"></td>
                                            <td v-text="receipt.payer"></td>
                                            <td v-text="receipt.phone"></td>
                                            <td>{{ receipt.amount_paid | formatMoney }}</td>
                                            <td v-text="receipt.rev_code"></td>
                                            <td v-text="receipt.transaction_reference"></td>
                                            <td v-html="getStatus(receipt)"></td>
                                            <td class="table-option">
                                                <div class="btn-group">
                                                    
                                                    <a :href="`/receipt/${receipt.reference_no}/print`" class="btn btn-primary btn-sm" v-tooltip="trans('receipt.print_receipt')" v-if="hasPermission('print-receipt')">
                                                        <i class="feather icon-printer"></i>
                                                    </a>
                                                    <router-link :to="`/receipt/${receipt.id}`" class="btn btn-success btn-sm" v-tooltip="trans('receipt.view_receipt')"><i class="feather icon-eye"></i></router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="!receipts.total" class="alert alert-danger mt-1 alert-validation-msg" role="alert">
                                <div class="alert-body d-flex align-items-center">
                                    <i data-feather="info" class="me-50"></i>
                                    <span>No receipt found for this <strong>TIN</strong> Number.</span>
                                </div>
                            </div>
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
   
   </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userRole: '',
                receipts: {
                    total: 0,
                    data: []
                },
                searchReceiptForm: {
                    paymentId: ''
                },
                
                showSearchPanel: false,
                applicationStatus: false,
                json_fields: {
                    'Tax ID': 'tax_id',
                    'Payer': 'payer',
                    'Address': 'address',
                    'Amount': 'amount',
                    'Amount Paid': 'amount_paid',
                    'Rev. Code': 'rev_code',
                    'Payment Reference': 'transaction_reference'
                    
                },
                json_meta: [
                    [
                        {
                            'key': 'charset',
                            'value': 'utf-8'
                        }
                    ]
                ],
            };
        },
        mounted(){
            if(!helper.hasPermission('list-receipt')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('receipt')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            this.userRole = this.getRole()[0];
            this.receiptStatus = this.receipts.status;
        },
        methods: {
            search(){
                axios.get('/api/receipt/'+ this.searchReceiptForm.paymentId + '/search')
                    .then(response => response.data)
                    .then(response => {
                        this.receipts = response;
                        this.showSearchPanel = true;
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getRole(role){
              return helper.getRole(role);
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            getStatus(receipt){
                return receipt.status ? ('<span class="badge badge-success">'+i18n.receipt.printed+'</span>') : ('<span class="badge badge-danger">'+i18n.receipt.pending+'</span>') ;
            }
        },
        filters: {
          momentDateTime(date) {
            return helper.formatDateTime(date);
          },
          formatMoney(value){
            return helper.formatMoney(value);
          }
        }
    }
</script>
