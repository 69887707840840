var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("tax_payer.tax_payer")) +
                  "\n                        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/tax-payer" } }, [
                      _vm._v(_vm._s(_vm.trans("tax_payer.tax_payers")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.user.profile
                  ? _c("li", { staticClass: "breadcrumb-item active" }, [
                      _vm._v(
                        _vm._s(
                          _vm.user.profile.first_name +
                            " " +
                            _vm.user.profile.last_name
                        ) +
                          " (" +
                          _vm._s(_vm.user.email) +
                          ")"
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/tax-payer")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("tax_payer.tax_payer_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c(
        "div",
        { attrs: { id: "user-profile" } },
        [
          _c("user-sidebar", { attrs: { menu: "email", id: _vm.id } }),
          _vm._v(" "),
          _c("section", { attrs: { id: "profile-info" } }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("user-summary", { attrs: { user: _vm.user } }),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-8 col-12" }, [
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.email")))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-content" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-start align-items-center mb-1"
                          },
                          [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.submit.apply(null, arguments)
                                  },
                                  keydown: function($event) {
                                    return _vm.emailForm.errors.clear(
                                      $event.target.name
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("template.template"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("v-select", {
                                      attrs: {
                                        label: "name",
                                        name: "template_id",
                                        id: "template_id",
                                        options: _vm.templates,
                                        placeholder: _vm.trans(
                                          "template.select_template"
                                        )
                                      },
                                      on: {
                                        select: _vm.getTemplateContent,
                                        close: function($event) {
                                          _vm.emailForm.template_id =
                                            _vm.selected_template.id
                                        },
                                        remove: function($event) {
                                          _vm.emailForm.template_id = ""
                                        }
                                      },
                                      model: {
                                        value: _vm.selected_template,
                                        callback: function($$v) {
                                          _vm.selected_template = $$v
                                        },
                                        expression: "selected_template"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.emailForm,
                                        "prop-name": "template_id"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(_vm.trans("template.subject"))
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.emailForm.subject,
                                          expression: "emailForm.subject"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        value: "",
                                        name: "subject",
                                        placeholder: _vm.trans("user.subject")
                                      },
                                      domProps: {
                                        value: _vm.emailForm.subject
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.emailForm,
                                            "subject",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.emailForm,
                                        "prop-name": "subject"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("html-editor", {
                                      attrs: {
                                        name: "body",
                                        model: _vm.emailForm.body,
                                        isUpdate: "true",
                                        "reload-content": _vm.reload_content
                                      },
                                      on: {
                                        "update:model": function($event) {
                                          return _vm.$set(
                                            _vm.emailForm,
                                            "body",
                                            $event
                                          )
                                        },
                                        clearErrors: function($event) {
                                          return _vm.emailForm.errors.clear(
                                            "body"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.emailForm,
                                        "prop-name": "body"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-info pull-right",
                                    attrs: { type: "submit" }
                                  },
                                  [_vm._v(_vm._s(_vm.trans("message.send")))]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }