var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content-header row" }, [
      _c("div", { staticClass: "content-header-left col-md-9 col-12 mb-2" }, [
        _c("div", { staticClass: "row breadcrumbs-top" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h2", { staticClass: "content-header-title float-left mb-0" }, [
              _vm._v(
                _vm._s(_vm.trans("tax_payer.tax_payer")) +
                  "\n                        "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "breadcrumb-wrapper col-12" }, [
              _c("ol", { staticClass: "breadcrumb" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: "/tax-payer" } }, [
                      _vm._v(_vm._s(_vm.trans("tax_payer.tax_payers")))
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.user.profile
                  ? _c("li", { staticClass: "breadcrumb-item active" }, [
                      _vm._v(
                        _vm._s(
                          _vm.user.profile.first_name +
                            " " +
                            _vm.user.profile.last_name
                        ) +
                          " (" +
                          _vm._s(_vm.user.email) +
                          ")"
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "content-header-right text-md-right col-md-3 col-12 d-md-block d-none"
        },
        [
          _c("div", { staticClass: "form-group breadcrum-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm pull-right",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/tax-payer")
                  }
                }
              },
              [
                _c("i", { staticClass: "feather icon-list" }),
                _vm._v(" " + _vm._s(_vm.trans("tax_payer.tax_payer_list")))
              ]
            )
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-body" }, [
      _c("div", { attrs: { id: "user-profile" } }, [
        _c("section", { attrs: { id: "profile-info" } }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4 col-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.imageTitle))
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "feather icon-more-horizontal cursor-pointer"
                  })
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("upload-image", {
                      attrs: {
                        id: "avatar",
                        "upload-path":
                          "/user/profile/avatar/" + _vm.getAuthUser("id"),
                        "remove-path":
                          "/user/profile/avatar/remove/" +
                          _vm.getAuthUser("id"),
                        "image-source": _vm.avatar.user
                      },
                      on: {
                        uploaded: _vm.updateAvatar,
                        removed: _vm.updateAvatar
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-8 col-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v(
                      _vm._s(_vm.trans("user.update_profile")) +
                        " " +
                        _vm._s(_vm.title(_vm.profileForm.type))
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-content" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-start align-items-center mb-1"
                      },
                      [
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.updateProfile.apply(null, arguments)
                              },
                              keydown: function($event) {
                                return _vm.profileForm.errors.clear(
                                  $event.target.name
                                )
                              }
                            }
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(_vm._s(_vm.trans("user.username")))
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.profileForm.username,
                                          expression: "profileForm.username"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "username",
                                        disabled: ""
                                      },
                                      domProps: {
                                        value: _vm.profileForm.username
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.profileForm,
                                            "username",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.profileForm,
                                        "prop-name": "username"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-12 col-sm-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(_vm._s(_vm.trans("user.email")))
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.profileForm.email,
                                          expression: "profileForm.email"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.profileForm.errors.has(
                                          "email"
                                        )
                                      },
                                      attrs: { type: "text", name: "email" },
                                      domProps: {
                                        value: _vm.profileForm.email
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.profileForm,
                                            "email",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("show-error", {
                                      attrs: {
                                        "form-name": _vm.profileForm,
                                        "prop-name": "email"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.profileForm.type == "individual"
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.title"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.profileForm.title,
                                                  expression:
                                                    "profileForm.title"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "title"
                                                )
                                              },
                                              attrs: { name: "title" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "title",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.trans("user.title")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.titles, function(
                                                title
                                              ) {
                                                return _c(
                                                  "option",
                                                  {
                                                    domProps: {
                                                      value: title.id
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(title.id))]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "title"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.first_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.first_name,
                                                expression:
                                                  "profileForm.first_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "first_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "first_name"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.first_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "first_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "first_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.last_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.last_name,
                                                expression:
                                                  "profileForm.last_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "last_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "last_name"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.last_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "last_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "last_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sector_id")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "sector_id"
                                              )
                                            },
                                            attrs: {
                                              options: _vm.sectors,
                                              reduce: function(name) {
                                                return name.id
                                              },
                                              label: "name",
                                              name: "sector_id"
                                            },
                                            on: { input: _vm.getSectorLines },
                                            model: {
                                              value: _vm.profileForm.sector_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "sector_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.sector_id"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "sector_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sector_line_id")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "sector_line_id"
                                              )
                                            },
                                            attrs: {
                                              options: _vm.sectorLines,
                                              reduce: function(name) {
                                                return name.id
                                              },
                                              label: "name",
                                              name: "sector_line_id"
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm.sector_line_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "sector_line_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.sector_line_id"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "sector_line_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("Size")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.profileForm.size,
                                                  expression: "profileForm.size"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "size"
                                                )
                                              },
                                              attrs: { name: "size" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "size",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "Large" } },
                                                [_vm._v("Large")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "Medium" } },
                                                [_vm._v("Medium")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "Small" } },
                                                [_vm._v("Small")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "size"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.bvn"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profileForm.bvn,
                                                expression: "profileForm.bvn"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "bvn"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "bvn"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.bvn
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "bvn",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "bvn"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.date_of_birth")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("datepicker", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "date_of_birth"
                                              )
                                            },
                                            attrs: {
                                              bootstrapStyling: true,
                                              name: "date_of_birth"
                                            },
                                            on: {
                                              selected: function($event) {
                                                return _vm.profileForm.errors.clear(
                                                  "date_of_birth"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm.date_of_birth,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "date_of_birth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.date_of_birth"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "date_of_birth"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.gender"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "radio radio-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(_vm.genders, function(
                                                  gender
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-sm-4"
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.profileForm
                                                                .gender,
                                                            expression:
                                                              "profileForm.gender"
                                                          }
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          id: gender.id,
                                                          name: "gender"
                                                        },
                                                        domProps: {
                                                          value: gender.id,
                                                          checked:
                                                            _vm.profileForm
                                                              .gender ==
                                                            gender.id,
                                                          checked: _vm._q(
                                                            _vm.profileForm
                                                              .gender,
                                                            gender.id
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.profileForm.errors.clear(
                                                              "gender"
                                                            )
                                                          },
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.profileForm,
                                                              "gender",
                                                              gender.id
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: gender.id
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.trans(
                                                                  "list." +
                                                                    gender.id
                                                                )
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "gender"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.state"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm.state_id,
                                                  expression:
                                                    "profileForm.state_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "state_id"
                                                )
                                              },
                                              attrs: {
                                                name: "state_id",
                                                disabled: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "state_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.states, function(state) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: { value: state.id }
                                                },
                                                [_vm._v(_vm._s(state.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "state_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.lga_id"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.profileForm.lga_id,
                                                  expression:
                                                    "profileForm.lga_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "lga_id"
                                                )
                                              },
                                              attrs: { name: "lga_id" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "lga_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.lgas, function(lga) {
                                              return _c(
                                                "option",
                                                { domProps: { value: lga.id } },
                                                [_vm._v(_vm._s(lga.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "lga_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.tax_office_id")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .tax_office_id,
                                                  expression:
                                                    "profileForm.tax_office_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "tax_office_id"
                                                )
                                              },
                                              attrs: { name: "tax_office_id" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "tax_office_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.taxOffices, function(
                                              taxOffice
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: taxOffice.id
                                                  }
                                                },
                                                [_vm._v(_vm._s(taxOffice.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "tax_office_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.birth_place")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.birth_place,
                                                expression:
                                                  "profileForm.birth_place"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "birth_place"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "birth_place"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.birth_place
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "birth_place",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "birth_place"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.occupation")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .occupation_id,
                                                  expression:
                                                    "profileForm.occupation_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "occupation_id"
                                                )
                                              },
                                              attrs: { name: "occupation_id" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "occupation_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.occupations, function(
                                              occupation
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: occupation.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(occupation.name)
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "occupation_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.source_of_income_id"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .source_of_income_id,
                                                  expression:
                                                    "profileForm.source_of_income_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "source_of_income_id"
                                                )
                                              },
                                              attrs: {
                                                name: "source_of_income_id"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "source_of_income_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.categories, function(
                                              category
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: category.id
                                                  }
                                                },
                                                [_vm._v(_vm._s(category.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "source_of_income_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.nationality")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.nationality,
                                                expression:
                                                  "profileForm.nationality"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "nationality"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "nationality"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.nationality
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "nationality",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "nationality"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.address"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profileForm.address,
                                                expression:
                                                  "profileForm.address"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "address"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "address"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.address
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "address",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "address"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.city"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profileForm.city,
                                                expression: "profileForm.city"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "city"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "city"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.city
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "city",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "city"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.state_of_origin_id"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .state_of_origin_id,
                                                  expression:
                                                    "profileForm.state_of_origin_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "state_of_origin_id"
                                                )
                                              },
                                              attrs: {
                                                name: "state_of_origin_id"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "state_of_origin_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.states, function(state) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: { value: state.id }
                                                },
                                                [_vm._v(_vm._s(state.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "state_of_origin_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.tax_authority")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.tax_authority,
                                                expression:
                                                  "profileForm.tax_authority"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "tax_authority"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "tax_authority",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.tax_authority
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "tax_authority",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "tax_authority"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.employer_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.employer_name,
                                                expression:
                                                  "profileForm.employer_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "employer_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "employer_name"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.employer_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "employer_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "employer_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.employer_tin")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.employer_tin,
                                                expression:
                                                  "profileForm.employer_tin"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "employer_tin"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "employer_tin"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.employer_tin
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "employer_tin",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "employer_tin"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.profileForm.type == "business"
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.business_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.business_name,
                                                expression:
                                                  "profileForm.business_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "business_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "business_name"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.business_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "business_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "business_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.registered_name"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .registered_name,
                                                expression:
                                                  "profileForm.registered_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "registered_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "registered_name"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.registered_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "registered_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "registered_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.organization_id"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .organization_id,
                                                  expression:
                                                    "profileForm.organization_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "organization_id"
                                                )
                                              },
                                              attrs: {
                                                name: "organization_id"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "organization_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.organizations, function(
                                              organization
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: organization.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(organization.name)
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "organization_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.business_type")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .business_type_id,
                                                  expression:
                                                    "profileForm.business_type_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "business_type_id"
                                                )
                                              },
                                              attrs: {
                                                name: "business_type_id"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "business_type_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.businessTypes, function(
                                              businessType
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: businessType.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(businessType.name)
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "business_type_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sector_id")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "sector_id"
                                              )
                                            },
                                            attrs: {
                                              options: _vm.sectors,
                                              reduce: function(name) {
                                                return name.id
                                              },
                                              label: "name",
                                              name: "sector_id"
                                            },
                                            on: { input: _vm.getSectorLines },
                                            model: {
                                              value: _vm.profileForm.sector_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "sector_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.sector_id"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "sector_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sector_line_id")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("v-select", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "sector_line_id"
                                              )
                                            },
                                            attrs: {
                                              options: _vm.sectorLines,
                                              reduce: function(name) {
                                                return name.id
                                              },
                                              label: "name",
                                              name: "sector_line_id"
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm.sector_line_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "sector_line_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.sector_line_id"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "sector_line_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("Size")
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.profileForm.size,
                                                  expression: "profileForm.size"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "size"
                                                )
                                              },
                                              attrs: { name: "size" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "size",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "Large" } },
                                                [_vm._v("Large")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "Medium" } },
                                                [_vm._v("Medium")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "Small" } },
                                                [_vm._v("Small")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "size"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.rc_no"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profileForm.rc_no,
                                                expression: "profileForm.rc_no"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "rc_no"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "rc_no"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.rc_no
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "rc_no",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "rc_no"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.enterprise_registration_no"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .enterprise_registration_no,
                                                expression:
                                                  "profileForm.enterprise_registration_no"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "enterprise_registration_no"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "enterprise_registration_no"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm
                                                  .enterprise_registration_no
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "enterprise_registration_no",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name":
                                                "enterprise_registration_no"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.date_of_commencement"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("datepicker", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "date_of_commencement"
                                              )
                                            },
                                            attrs: {
                                              bootstrapStyling: true,
                                              name: "date_of_commencement"
                                            },
                                            on: {
                                              selected: function($event) {
                                                return _vm.profileForm.errors.clear(
                                                  "date_of_commencement"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm
                                                  .date_of_commencement,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "date_of_commencement",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.date_of_commencement"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name":
                                                "date_of_commencement"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.state"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm.state_id,
                                                  expression:
                                                    "profileForm.state_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "state_id"
                                                )
                                              },
                                              attrs: {
                                                name: "state_id",
                                                disabled: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "state_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.states, function(state) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: { value: state.id }
                                                },
                                                [_vm._v(_vm._s(state.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "state_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.lga_id"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.profileForm.lga_id,
                                                  expression:
                                                    "profileForm.lga_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "lga_id"
                                                )
                                              },
                                              attrs: { name: "lga_id" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "lga_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.lgas, function(lga) {
                                              return _c(
                                                "option",
                                                { domProps: { value: lga.id } },
                                                [_vm._v(_vm._s(lga.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "lga_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.address"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profileForm.address,
                                                expression:
                                                  "profileForm.address"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "address"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "address"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.address
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "address",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "address"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.city"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.profileForm.city,
                                                expression: "profileForm.city"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "city"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "city"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.city
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "city",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "city"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.tax_office_id")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.profileForm
                                                      .tax_office_id,
                                                  expression:
                                                    "profileForm.tax_office_id"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              class: {
                                                "is-invalid": _vm.profileForm.errors.has(
                                                  "tax_office_id"
                                                )
                                              },
                                              attrs: { name: "tax_office_id" },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.profileForm,
                                                    "tax_office_id",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            _vm._l(_vm.taxOffices, function(
                                              taxOffice
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  domProps: {
                                                    value: taxOffice.id
                                                  }
                                                },
                                                [_vm._v(_vm._s(taxOffice.name))]
                                              )
                                            }),
                                            0
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "tax_office_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.tax_authority")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.tax_authority,
                                                expression:
                                                  "profileForm.tax_authority"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "tax_authority"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "tax_authority",
                                              readonly: ""
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.tax_authority
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "tax_authority",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "tax_authority"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.company_tin")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.company_tin,
                                                expression:
                                                  "profileForm.company_tin"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "company_tin"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.company_tin
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "company_tin",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "company_tin"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.date_of_incorporation"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("datepicker", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "date_of_incorporation"
                                              )
                                            },
                                            attrs: {
                                              bootstrapStyling: true,
                                              name: "date_of_incorporation"
                                            },
                                            on: {
                                              selected: function($event) {
                                                return _vm.profileForm.errors.clear(
                                                  "date_of_incorporation"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm
                                                  .date_of_incorporation,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "date_of_incorporation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.date_of_incorporation"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name":
                                                "date_of_incorporation"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.issuance_place")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .issuance_place,
                                                expression:
                                                  "profileForm.issuance_place"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "issuance_place"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "issuance_place"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.issuance_place
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "issuance_place",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "issuance_place"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.updated_by")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.updated_by,
                                                expression:
                                                  "profileForm.updated_by"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "updated_by"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "updated_by"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.updated_by
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "updated_by",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "updated_by"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-4" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.update_time")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.update_time,
                                                expression:
                                                  "profileForm.update_time"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "update_time"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "update_time"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.update_time
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "update_time",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "update_time"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.profileForm.type == "staff"
                              ? _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.first_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.first_name,
                                                expression:
                                                  "profileForm.first_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "first_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "first_name"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.first_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "first_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "first_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.last_name")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm.last_name,
                                                expression:
                                                  "profileForm.last_name"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "last_name"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "last_name"
                                            },
                                            domProps: {
                                              value: _vm.profileForm.last_name
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "last_name",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "last_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.date_of_birth")
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("datepicker", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "date_of_birth"
                                              )
                                            },
                                            attrs: {
                                              bootstrapStyling: true,
                                              name: "date_of_birth"
                                            },
                                            on: {
                                              selected: function($event) {
                                                return _vm.profileForm.errors.clear(
                                                  "date_of_birth"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm.date_of_birth,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "date_of_birth",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.date_of_birth"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "date_of_birth"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans(
                                                  "user.date_of_anniversary"
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("datepicker", {
                                            class: {
                                              "form-control is-invalid": _vm.profileForm.errors.has(
                                                "date_of_anniversary"
                                              )
                                            },
                                            attrs: {
                                              bootstrapStyling: true,
                                              name: "date_of_anniversary"
                                            },
                                            on: {
                                              selected: function($event) {
                                                return _vm.profileForm.errors.clear(
                                                  "date_of_anniversary"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.profileForm
                                                  .date_of_anniversary,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "date_of_anniversary",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "profileForm.date_of_anniversary"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "date_of_anniversary"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(_vm.trans("user.gender"))
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "radio radio-info" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                _vm._l(_vm.genders, function(
                                                  gender
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-sm-4"
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.profileForm
                                                                .gender,
                                                            expression:
                                                              "profileForm.gender"
                                                          }
                                                        ],
                                                        attrs: {
                                                          type: "radio",
                                                          id: gender.id,
                                                          name: "gender"
                                                        },
                                                        domProps: {
                                                          value: gender.id,
                                                          checked:
                                                            _vm.profileForm
                                                              .gender ==
                                                            gender.id,
                                                          checked: _vm._q(
                                                            _vm.profileForm
                                                              .gender,
                                                            gender.id
                                                          )
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.profileForm.errors.clear(
                                                              "gender"
                                                            )
                                                          },
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.profileForm,
                                                              "gender",
                                                              gender.id
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: gender.id
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.trans(
                                                                  "list." +
                                                                    gender.id
                                                                )
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }),
                                                0
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "gender"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-12 col-sm-6" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sm_profile", {
                                                  name: "Facebook"
                                                })
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .facebook_profile,
                                                expression:
                                                  "profileForm.facebook_profile"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "facebook_profile"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "facebook_profile"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.facebook_profile
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "facebook_profile",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "facebook_profile"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sm_profile", {
                                                  name: "Twitter"
                                                })
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .twitter_profile,
                                                expression:
                                                  "profileForm.twitter_profile"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "twitter_profile"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "twitter_profile"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.twitter_profile
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "twitter_profile",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "twitter_profile"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sm_profile", {
                                                  name: "Google Plus"
                                                })
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .google_plus_profile,
                                                expression:
                                                  "profileForm.google_plus_profile"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "google_plus_profile"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "google_plus_profile"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm
                                                  .google_plus_profile
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "google_plus_profile",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "google_plus_profile"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-6" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.trans("user.sm_profile", {
                                                  name: "Linkedin"
                                                })
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.profileForm
                                                    .linkedin_profile,
                                                expression:
                                                  "profileForm.linkedin_profile"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              "is-invalid": _vm.profileForm.errors.has(
                                                "linkedin_profile"
                                              )
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "linkedin_profile"
                                            },
                                            domProps: {
                                              value:
                                                _vm.profileForm.linkedin_profile
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.profileForm,
                                                  "linkedin_profile",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("show-error", {
                                            attrs: {
                                              "form-name": _vm.profileForm,
                                              "prop-name": "linkedin_profile"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button-spinner",
                              {
                                staticClass:
                                  "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
                                staticStyle: { height: "39px", color: "#fff" },
                                attrs: {
                                  "is-loading": _vm.profileForm.isLoading,
                                  disabled: _vm.profileForm.isLoading
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.trans("general.save")) +
                                    "\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "breadcrumb-item" }, [
      _c("a", { attrs: { href: "/" } }, [_vm._v("Home")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }