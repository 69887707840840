<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h2 class="content-header-title float-left mb-0">{{trans('tax_payer.tax_payer')}}
                        </h2>
                        <div class="breadcrumb-wrapper col-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/tax-payer">{{trans('tax_payer.tax_payers')}}</router-link>
                                </li>
                                <li class="breadcrumb-item active" v-if="user.profile">{{user.profile.first_name+' '+user.profile.last_name}} ({{user.email}})</li>
                                </ol> 
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <div class="form-group breadcrum-right">
                    <button class="btn btn-primary btn-sm pull-right" @click="$router.push('/tax-payer')"><i class="feather icon-list"></i> {{trans('tax_payer.tax_payer_list')}}</button>
                </div>
            </div>
        </div>

    <div class="content-body">
        <div id="user-profile">
            <user-sidebar menu="email" :id="id"></user-sidebar>
  
  <section id="profile-info">
    <div class="row">
        <user-summary :user="user"></user-summary>
      
      <div class="col-lg-8 col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{trans('user.email')}}</h4>
            </div>
          <div class="card-content">
              <div class="card-body">
            <div class="d-flex justify-content-start align-items-center mb-1">
                <form @submit.prevent="submit" @keydown="emailForm.errors.clear($event.target.name)">
                                        <div class="form-group">
                                            <label for="">{{trans('template.template')}}</label>
                                            <v-select label="name" v-model="selected_template" name="template_id" id="template_id" :options="templates" :placeholder="trans('template.select_template')" @select="getTemplateContent" @close="emailForm.template_id = selected_template.id" @remove="emailForm.template_id = ''"></v-select>
                                            <show-error :form-name="emailForm" prop-name="template_id"></show-error>
                                        </div>
                                        <div class="form-group">
                                            <label for="">{{trans('template.subject')}}</label>
                                            <input class="form-control" type="text" value="" v-model="emailForm.subject" name="subject" :placeholder="trans('user.subject')">
                                            <show-error :form-name="emailForm" prop-name="subject"></show-error>
                                        </div>
                                        <div class="form-group">
                                            <html-editor name="body" :model.sync="emailForm.body" isUpdate="true" :reload-content="reload_content" @clearErrors="emailForm.errors.clear('body')"></html-editor>
                                            <show-error :form-name="emailForm" prop-name="body"></show-error>
                                        </div>
                                        <button type="submit" class="btn btn-sm btn-info pull-right">{{trans('message.send')}}</button>
                                    </form>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
   
   </div>
    </div>
</template>

<script>
    import userSidebar from './user-sidebar'
    import userSummary from './summary'
    import vSelect from 'vue-multiselect'
    import htmlEditor from '../../components/html-editor'

    export default {
        components : { userSidebar,userSummary,vSelect,htmlEditor },
        data() {
            return {
                id:this.$route.params.id,
                user: '',
                templates: [],
                emailForm: new Form({
                    template_id: '',
                    subject: '',
                    body: ''
                }),
                selected_template: {},
                reload_content: false,
            };
        },
        mounted(){
            if(!helper.hasPermission('email-tax-payer')){
                helper.notAccessibleMsg();
                this.$router.push('/home');
            }

            if(!helper.featureAvailable('email_template')){
                helper.featureNotAvailableMsg();
                this.$router.push('/home');
            }

            axios.get('/api/user/'+this.id)
                .then(response => response.data)
                .then(response => {
                    this.user = response.user;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                });

            if(helper.getConfig('email_template'))
            axios.get('/api/email-template/user/lists')
                .then(response => response.data)
                .then(response => {
                    this.templates = response;
                })
                .catch(error => {
                    helper.showDataErrorMsg(error);
                })
        },
        methods: {
            getTemplateContent(selectedOption){
                this.emailForm.errors.clear('template_id');
                axios.get('/api/email-template/'+selectedOption.id+'/content?user_id='+this.id)
                    .then(response => response.data)
                    .then(response => {
                        this.emailForm.subject = response.mail_data.subject;
                        this.reload_content = true;
                        this.emailForm.body = response.mail_data.body;
                    })
                    .catch(error => {
                        helper.showDataErrorMsg(error);
                    });
                this.reload_content = false;
            },
            submit(){
                this.emailForm.patch('/api/user/'+this.id+'/email')
                    .then(response => {
                        toastr.success(response.message);
                        this.selected_template= '';
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            }
        }
    }
</script>
