var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed.apply(null, arguments)
        },
        keydown: function($event) {
          return _vm.demandNoticePayItemForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-9 col-md-12 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Sector")]),
              _vm._v(" "),
              _c("v-select", {
                class: {
                  "form-control is-invalid": _vm.demandNoticePayItemForm.errors.has(
                    "sector_id"
                  )
                },
                attrs: {
                  options: _vm.sectors,
                  reduce: function(name) {
                    return name.id
                  },
                  label: "name"
                },
                model: {
                  value: _vm.demandNoticePayItemForm.sector_id,
                  callback: function($$v) {
                    _vm.$set(_vm.demandNoticePayItemForm, "sector_id", $$v)
                  },
                  expression: "demandNoticePayItemForm.sector_id"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.demandNoticePayItemForm,
                  "prop-name": "sector_id"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-3 col-md-12 col-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Size")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.demandNoticePayItemForm.size,
                      expression: "demandNoticePayItemForm.size"
                    }
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.demandNoticePayItemForm.errors.has("size")
                  },
                  attrs: { name: "size" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.demandNoticePayItemForm,
                        "size",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "Large" } }, [
                    _vm._v("Large")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Medium" } }, [
                    _vm._v("Medium")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "Small" } }, [_vm._v("Small")])
                ]
              ),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.demandNoticePayItemForm,
                  "prop-name": "size"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xl-12 col-md-12 col-12" }, [
          _c("table", { staticClass: "table table-sm" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.demandNoticePayItemForm.pay_items, function(
                pay_item,
                k
              ) {
                return _c("tr", { key: k }, [
                  _c(
                    "td",
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid": _vm.demandNoticePayItemForm.errors.has(
                            "pay_items." + [k] + ".pay_item_id"
                          )
                        },
                        attrs: {
                          options: _vm.payItems,
                          reduce: function(name) {
                            return name.id
                          },
                          label: "name"
                        },
                        model: {
                          value: pay_item.pay_item_id,
                          callback: function($$v) {
                            _vm.$set(pay_item, "pay_item_id", $$v)
                          },
                          expression: "pay_item.pay_item_id"
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.demandNoticePayItemForm,
                          "prop-name": "pay_items." + [k] + ".pay_item_id"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: pay_item.amount,
                            expression: "pay_item.amount"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.demandNoticePayItemForm.errors.has(
                            "pay_items." + [k] + ".amount"
                          )
                        },
                        attrs: {
                          type: "text",
                          value: "",
                          placeholder: "Amount"
                        },
                        domProps: { value: pay_item.amount },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(pay_item, "amount", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.demandNoticePayItemForm,
                          "prop-name": "pay_items." + [k] + ".amount"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.trans("general.add"),
                            expression: "trans('general.add')"
                          }
                        ],
                        staticClass:
                          "btn btn-primary mr-1 mb-1 waves-effect waves-light btn-sm pull-right",
                        attrs: { type: "button" },
                        on: { click: _vm.addNewRow }
                      },
                      [_c("i", { staticClass: "feather icon-plus" })]
                    ),
                    _vm._v(" "),
                    k > 0
                      ? _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.trans("general.remove"),
                                expression: "trans('general.remove')"
                              }
                            ],
                            staticClass:
                              "btn btn-danger mr-1 mb-1 waves-effect waves-light btn-sm pull-right",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.deleteRow(k, pay_item)
                              }
                            }
                          },
                          [_c("i", { staticClass: "feather icon-trash-2" })]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "btn btn-primary mr-1 mb-1 waves-effect waves-light pull-right",
          attrs: { type: "submit" }
        },
        [
          _vm.id
            ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
            : _c("span", [_vm._v(_vm._s(_vm.trans("general.save")))])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.id, expression: "id" }
          ],
          staticClass:
            "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
          attrs: { to: "/setting/demand-notice-pay-item" }
        },
        [_vm._v(_vm._s(_vm.trans("general.cancel")))]
      ),
      _vm._v(" "),
      !_vm.id
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-danger waves-effect waves-light pull-right mr-1 mb-1",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v(_vm._s(_vm.trans("general.cancel")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { attrs: { width: "50%" } }, [_vm._v("Pay Item")]),
        _vm._v(" "),
        _c("th", [_vm._v("Amount")]),
        _vm._v(" "),
        _c("th", { staticClass: "table-option" }, [_vm._v(" ")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }